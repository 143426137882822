import React from 'react';
import { Container } from '@mui/material';
import './index.css';

const Header = () => {
    return (
        <>
       <div className="parentDiv">

      <div className="backgroundImagee"></div>
      
 
      <div className="overlayy"></div>


      <div className="centerText">
        <p>We're UniApp, We Empower Education Seekers Regardless Of Where They Come From Or Their Educational Background</p>
      </div>
    </div>
        </>
    );
};

export default Header;
