import React from 'react';
import './index.css'
const HowToApply = () => {
  return (
 
    <>
        <div content-tab="howtoapply">
                <h2 >How to apply - Latvia</h2>
                <p>
                  There are some steps needed to follow in order to apply to
                  study medicine in Latvia: Firstly, you want to Find a Latvian
                  university to apply to. After settling on a particular medical
                  school, you learn where to submit your university application.
                  Keep in mind that Latvia doesn’t employ a universal
                  application system. Hence, you need to check out the
                  application procedure at each university. Here on
                  <a href="https://uni-app.com/">UniApp</a>, we can offer full
                  help in applying to whichever school you aspire to join. Then
                  you want to make sure that you meet the university and the
                  language entry requirements ( in case you came from an English
                  non-speaking country). Most importantly, you have to apply
                  before the application deadlines. That’s why it is crucial to
                  frequently check out the admission section on the university’s
                  website and subscribe to the university’s newsletter when
                  possible.
                </p>
                <h3><strong>Admission Prerequisites </strong></h3>
                <ol>
                  <li>General secondary education diploma.</li>
                  <li>
                    One of the internationally recognized English proficiency
                    language tests or minimum a grade 6 (almost good) in a
                    10-point grading system in English in the general secondary
                    education diploma.
                  </li>
                  <li>
                    Grades in Biology, Chemistry, and Physics or Natural
                    Sciences, should be no less than 6(almost good) in a
                    10-point grading system.
                  </li>
                </ol>
                <h3><strong>Admission requirements</strong></h3>
                <ol>
                  <li>
                    Certified copy of secondary education diploma and grade
                    transcript in original language (for undergraduate studies)
                    or certified copy of bachelor diploma and diploma supplement
                    in original language (for postgraduate studies).
                  </li>
                  <li>
                    Documented translation of educational documents in English
                    or Latvian.
                  </li>
                  <li>
                    Document confirming proficiency in the language of
                    instruction (if necessary).
                  </li>
                  <li>Copy of passport.</li>
                  <li>Application form.</li>
                  <li>One passport-size photo.</li>
                  <li>
                    Copy of application fee payment receipt (non-refundable and
                    must be transferred to the UL bank account).
                  </li>
                  <li>Motivation letter.</li>
                  <li>Curriculum Vitae.</li>
                  <li>
                    Medical report and copy of entire vaccination document about
                    Hepatitis B and TD Tetanus/ Diphtheria vaccinations (For
                    Higher Professional Study program “Medicine”).
                  </li>
                </ol>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  Studying a medical program and obtaining a degree in medicine
                  in Latvia is a worthwhile experience. For possessing many
                  things to offer to visitors despite being a small country in
                  the Baltics. You will enjoy its mixed culture, history,
                  relaxation, and fun for sure. Apart from being picturesque,
                  the country has an excellent education system. Thus, studying
                  in Latvia is an optimum option. With attractive tuition fees
                  and various medical English programs, Latvia has become one of
                  the main destinations for international medical students. Come
                  on! It is an invitation to discover its history and offered
                  education, by which you will have the best medical career
                  worldwide.
                </p>
                <p>&nbsp;</p>
              </div>
    </>



  );
}
export default HowToApply;