import React from 'react';
import './Search.css';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

const General = ({ data }) => {
    return (
        <>

            <div className='general-box'>
                <AccountBalanceOutlinedIcon className='tabsInGeneral'/>
                <div className='general-data'>
                    <h5>Institution Name</h5>
                    <h3>{data?.instituteName}</h3>
                </div>
            </div>


            <div className='general-box'>
               <DriveFileRenameOutlineOutlinedIcon className='tabsInGeneral'/>
                <div className='general-data'>
                    <h5>Course Name</h5>
                    <h3>{data?.title}</h3>
                </div>
            </div>


            <div className='general-box'>
               <CheckCircleSharpIcon className='tabsInGeneral'/>
                <div className='general-data'>
                    <h5>Degree</h5>
                    <h3>{data?.degree}</h3>
                </div>
            </div>



            <div className='general-box'>
               <LanguageOutlinedIcon className='tabsInGeneral'/>
                <div className='general-data'>
                    <h5>Language</h5>
                    <h3>{data?.languages}</h3>
                </div>
            </div>




            <div className='general-box'>
            <WatchLaterOutlinedIcon  className='tabsInGeneral'/>
                <div className='general-data'>
                    <h5>Duration</h5>
                    <h3>
                        {data?.durationInMonth} months /
                        ({data?.durationInSemester} semesters)
                    </h3>
                </div>
            </div>




            <div className='general-box'>
                <PaymentsOutlinedIcon className='tabsInGeneral'/>
                <div className='general-data'>
                    <h5>Tuition Fee</h5>
                    <h3>
                        {data?.tuitionFee}
                        {data?.tuitionFeeCurrency} per
                        {data?.tuitionFeeUnit}
                    </h3>
                </div>
            </div>


            {/* <div className='general-box'>
                <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    className='tabsInGeneral'
                    viewBox="0 0 511.99 512"
                >
                    <path
                        d="M497.9,98,414,14.1A48,48,0,0,0,380.13,0H176a48.15,48.15,0,0,0-48,48.09V165.7c-6,0-11.94-1.68-24.13-5a18.92,18.92,0,0,0-5-.66,19.34,19.34,0,0,0-13.66,5.79c-18,18.26-17.07,17.77-41.7,24.5a19.71,19.71,0,0,0-13.78,14.07c-6.47,24.67-6.09,24.16-24,42.32A20.43,20.43,0,0,0,.68,266.12c6.56,24.38,6.52,24.39,0,48.76a20.45,20.45,0,0,0,5.08,19.45c18,18.15,17.58,17.79,24,42.29a19.74,19.74,0,0,0,13.78,14.1L48,391.93V496a16,16,0,0,0,23.15,14.31L128,481.89l56.84,28.42A16.12,16.12,0,0,0,192,512H464a48,48,0,0,0,48-48V132A48.23,48.23,0,0,0,497.9,98ZM384,32.59a15.73,15.73,0,0,1,7.4,4.2l83.88,83.88a15.73,15.73,0,0,1,4.2,7.4H384ZM33.28,316.68c5.7-22.3,5.7-30.23,0-52.39,15.65-16.2,19.56-23,25.63-45.06,21.57-6.13,28.06-9.92,43.88-25.69,9.8,2.62,16.82,4.15,25.21,4.15s15.25-1.49,25.19-4.16c15.56,15.51,22.49,19.58,43.86,25.68,6,21.95,9.71,28.63,25.65,45.07-5.77,22.45-5.76,30,0,52.4-15.62,16.17-19.55,23-25.61,45-14.63,3.92-24,7.36-37.25,19.36a77,77,0,0,0-63.7,0C83,369.13,73.59,365.67,58.92,361.68c-6-21.89-9.72-28.57-25.64-45Zm101.89,133a16,16,0,0,0-14.31,0L80,470.11V409.9l.31.29a18.92,18.92,0,0,0,23.8,2.82,44.9,44.9,0,0,1,47.74,0A18.84,18.84,0,0,0,162,416a19.17,19.17,0,0,0,13.63-5.79c.13-.13.21-.18.34-.32v60.22l-40.84-20.42ZM480,464a16,16,0,0,1-16,16H208V391.9c1.54-.4,2.65-.71,4.44-1.19a19.72,19.72,0,0,0,13.77-14.08c6.47-24.68,6.09-24.16,24-42.32a20.42,20.42,0,0,0,5.07-19.44c-6.53-24.33-6.55-24.34,0-48.76a20.45,20.45,0,0,0-5.07-19.45c-18-18.15-17.58-17.79-24-42.29a19.72,19.72,0,0,0-13.75-14.09c-24.23-6.57-23.89-6.23-41.72-24.52A19.09,19.09,0,0,0,160,160.6V48.09A16.12,16.12,0,0,1,176,32H352V136.07A23.88,23.88,0,0,0,376,160H480V464Z"
                        transform="translate(-0.01)"
                    />
                </svg>
                <div className='general-data'>
                    <h5>Study Model</h5>
                    <h3> {data?.partTime}</h3>
                    
                    <h3> {data?.fullTime}</h3>
                    
                    <h3> {data?.online}</h3>
                </div>
            </div> */}





        </>
    );
};

export default General;
