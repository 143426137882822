import React, { useEffect } from 'react';
import './index.css';
import WOW from 'wowjs';
import { Container } from '@mui/material';
import Pages from './Pages';
const PrivacyAndTerms = () => {

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0)
  }, [])
  return (
    <div style={{ padding: '50px 0px' }}>
      <Container maxWidth="lg">
        <div className='mainBox'>
          <div className='left-side'>
            <Pages />
          </div>
          <div className='right-side wow fadeInDown' data-wow-duration='1s'  >
            <div style={{ color: '#696969' }}>
              <header style={{ textAlign: 'center', marginBottom: '50px' }}>
                <h3>Privacy policy</h3>
                <p style={{ fontWeight: '600' }}>
                  In this privacy policy we have summarised
                  all essential information about UniApp’s
                  handling of your personal data and your
                  accompanying rights.
                </p>
                <p style={{ fontWeight: '600' }}>
                  This privacy policy comes into force on
                  July, 27, 2022.
                </p>
              </header>
              <p>
                At UniApp, security and transparency are our
                top priorities when it comes to handling
                personal data. In this privacy policy, we have
                summarised in detail what information we
                collect about you, how we use it and what
                rights you have.
              </p>
              <div className="list-content">
                <ol>
                  <li>
                    <h4>Scope of this privacy policy</h4>
                    <p>
                      This privacy policy applies to your use
                      of the UniApp online platform
                      (“Website”), including all UniApp
                      services, applications, products and
                      tools (collectively, “UniApp Services”),
                      regardless of how you access, view or
                      use those services, including access via
                      mobile devices or via an application.
                    </p>
                  </li>
                  <li>
                    <h4>Updating our privacy policy</h4>
                    <p>
                      UniApp reserves the right to update and
                      amend this privacy policy from time to
                      time to reflect changes in the way
                      UniApp processes your personal data or
                      to reflect changes in legal
                      requirements. Any changes we make to our
                      privacy policy in the future will be
                      posted on our website (www.uni-app.com)
                      and, where appropriate, we will also
                      notify you by email.
                    </p>
                  </li>
                  <li>
                    <h4>
                      Who is responsible for your personal
                      data ?
                    </h4>
                    <p>
                      The responsible party within the meaning
                      of the German Data Protection Regulation
                      (DSGVO) is Neom GmbH, Neue Mainzer Str.
                      46-50, D-60311, Frankfurt am Main, as
                      operator of the Services.
                    </p>
                    <p>
                      Whenever this privacy policy refers to
                      “UniApp”, “we” or “us”, this always
                      means Neom GmbH as the responsible
                      entity within the meaning of the DSGVO.
                    </p>
                  </li>
                  <li>
                    <h4>
                      What personal data do we collect and
                      process ?
                    </h4>
                    <p>
                      UniApp may collect personal information
                      from you when you use the UniApp
                      Services, create a UniApp user account,
                      upload or update information or
                      documents in your UniApp user account,
                      submit information to us via a web form
                      or contact us otherwise. UniApp also
                      collects personal information from other
                      sources. These sources may include but
                      are not limited to UniApp partners,
                      educational institutions, credit
                      institutions, data providers or payment
                      processors.
                    </p>
                    <p>
                      UniApp collects the following personal
                      data in particular:
                    </p>
                    <ol>
                      <li>
                        <p>
                          Personal data that you provide
                          independently when creating a UniApp
                          user account, your application
                          profile or when using our services
                        </p>
                        <ul>
                          <li>
                            Data that you must provide when
                            creating a UniApp user account
                            such as name, title, address,
                            email address, nationality, user
                            name, mobile phone number or date
                            of birth;
                          </li>
                          <li>
                            Data you provide when creating
                            your application profile, such as
                            your name, nationality, email
                            address, mobile phone number,
                            gender, date of birth, country of
                            residence, educational background,
                            certificates, achievements or
                            results in educational, language
                            tests or work experience, travel
                            history, passport number or
                            languages you speak;
                          </li>
                          <li>
                            Data that we need to authenticate,
                            identify or verify your identity
                            or that UniApp is required or
                            authorised by law to collect and
                            process;
                          </li>
                          <li>
                            Financial information required in
                            connection with the use of our
                            payment service providers (e.g.
                            credit card and account numbers,
                            payment data);
                          </li>
                          <li>
                            Content that you share with us,
                            agents or third parties via our
                            chat feature.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          Personal data from social networks
                        </p>
                        <ul>
                          <li>
                            UniApp allows you to use social
                            networks or similar service
                            providers with which you already
                            have an account to create an
                            UniApp user account or to link
                            your UniApp user account to the
                            relevant social network. The
                            providers of the social networks
                            or similar service providers may
                            provide us with certain personal
                            data depending on your privacy
                            settings.
                          </li>
                          <li>
                            For more information about the
                            personal data we receive from the
                            social network provider, please
                            refer to the privacy policy of the
                            respective provider.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          Personal data that is automatically
                          collected by us when you use our
                          services
                        </p>
                        <ul>
                          <li>
                            Data that arises in the course of
                            using our services and is linked
                            to your UniApp user account, such
                            as searches for educational
                            institutions or study programs;
                          </li>
                          <li>
                            Data about your interaction with
                            our services and your
                            communication with us;
                          </li>
                          <li>
                            Location data, including the
                            approximate location (e.g. region
                            derived from IP address) and the
                            exact location of your mobile
                            device;
                          </li>
                          <li>
                            Information about your visit and
                            usage when accessing our services,
                            such as information about the
                            device and browser you are using,
                            your IP address and location, your
                            advertiser identifier, the
                            duration and time and the time of
                            your visit, traffic information to
                            and from the sites, referral URL,
                            browsing history and your language
                            preferences.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          Personal data we collect through the
                          use of cookies and similar
                          technologies
                        </p>
                        <p>
                          Cookies are small packets of data
                          that your browser stores on your
                          device when instructed by a website
                          you visit to “remember” information
                          about you, such as your language
                          preferences or login information. We
                          use cookies and similar technologies
                          to collect data when you use our
                          services, in particular the UniApp
                          platform. UniApp collects the data
                          from the device (including mobile
                          devices) you use to access the
                          service.
                        </p>
                        <p>
                          The data collected includes the
                          following usage and device-related
                          information:
                        </p>
                        <ul>
                          <li>
                            The IP address of the terminal
                            device with which you access our
                            services;
                          </li>
                          <li>
                            Information about your terminal
                            device, operating system and
                            version, browser settings, ID for
                            advertisements and device
                            identifier;
                          </li>
                          <li>
                            Location data of the terminal
                            device from which you use our
                            services;
                          </li>
                          <li>
                            For further information please
                            refer to chapter 11 “Use of
                            cookies and similar technologies”.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          Personal data we obtain from third
                          parties or public sources:
                        </p>
                        <p>
                          UniApp also collects personal data
                          from public sources or third parties
                          to the extent permitted by
                          applicable law. UniApp may merge and
                          combine this data with the data you
                          provide to us.
                        </p>
                        <p>
                          Among other things, the following
                          data are collected:
                        </p>
                        <ul>
                          <li>
                            Data from third parties, such as
                            banks, credit reporting parties,
                            affiliates or support service
                            providers;
                          </li>
                          <li>
                            Data from information parties;
                          </li>
                          <li>
                            Data from public sources such as
                            public registers.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>
                      On what legal basis do we process your
                      data ?
                    </h4>
                    <p>
                      UniApp processes your personal data on
                      different legal bases and for different
                      purposes which allow us to do so.
                    </p>
                    <p>
                      Among other things, UniApp processes
                      your personal data to enable you to use
                      and continuously improve our platform,
                      to provide a personalised user
                      experience on our platform, to display
                      customised advertising to you, to
                      provide customer service or to protect
                      you from fraud and similar unlawful
                      activities. To enable these purposes,
                      UniApp also needs to share the data with
                      third parties.
                    </p>
                    <ol>
                      <li>
                        <p>
                          We process your personal data in
                          order to be able to offer you our
                          services and to fulfil the contract
                          concluded with you (Art. 6 para. 1
                          lit. b) DSGVO). In particular, this
                          includes the following purposes:
                        </p>
                        <ul>
                          <li>
                            The provision of our services and
                            the use of our platform, in
                            particular to apply to educational
                            institutions and to measure and
                            continuously improve the quality
                            of our services and to keep our
                            platform operational;
                          </li>
                          <li>
                            To enforce UniApp’s Terms of Use,
                            this privacy policy and other
                            policies;
                          </li>
                          <li>
                            UniApp processes data relating to
                            you or your company for the
                            purposes of entering into and
                            performing the contract between
                            you and UniApp;
                          </li>
                          <li>
                            The execution of transactions
                            between UniApp and the agents, the
                            provision and continuous
                            improvement of our services and
                            the creation of the permanent
                            functionality of our platform;
                          </li>
                          <li>
                            The provision of our support
                            regarding your user account. For
                            this purpose, we may contact you
                            via your UniApp user account,
                            email or mobile number;
                          </li>
                          <li>
                            The processing of general location
                            data such as the IP address in
                            order to provide you with
                            location-based search results for
                            example when searching for
                            educational institutions.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          UniApp processes your personal data
                          in order to comply with our legal
                          obligations (Art. 6 para. 1 lit. c)
                          DSGVO). This includes in particular
                          the following purposes:
                        </p>
                        <ul>
                          <li>
                            UniApp always tries to detect,
                            contain and prevent illegal
                            activities (e.g. fraud, money
                            laundering, phishing or terrorist
                            financing);
                          </li>
                          <li>
                            Participation in proceedings
                            (including court proceedings)
                            conducted by state authorities;
                          </li>
                          <li>
                            Ensuring the safe use of our
                            services and platform;
                          </li>
                          <li>
                            To comply with the legal
                            obligations to retain personal
                            data.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          To the extent necessary, UniApp will
                          transfer your personal data for the
                          above purposes to the following
                          selected recipients, among others:
                        </p>
                        <ul>
                          <li>
                            To third parties who have a legal
                            right to information against
                            UniApp;
                          </li>
                          <li>
                            To law enforcement agencies,
                            courts, other government agencies,
                            intergovernmental or supranational
                            bodies;
                          </li>
                          <li>External service providers;</li>
                          <li>
                            Third parties involved in legal
                            proceedings, provided that they
                            submit a legal order, court order
                            or equivalent legal disposition to
                            us;
                          </li>
                          <li>
                            Credit reference agencies, data
                            verification services, debt
                            collection agencies (e.g.
                            information on late payments,
                            defaults or other irregularities
                            that may be relevant to your
                            credit report);
                          </li>
                          <li>
                            Other companies within the scope
                            of a company acquisition;
                          </li>
                          <li>
                            UniApp partners (e.g. educational
                            institutions, recruitment
                            partners);
                          </li>
                          <li>
                            Members of our group of companies,
                            in particular udrus.com;
                          </li>
                          <li>
                            Other third parties we work with
                            to provide you with certain
                            services.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          We process your personal data in
                          order to protect your vital
                          interests or the vital interests of
                          another natural person (Art. 6 para.
                          1 lit. d) DSGVO). The following
                          purposes are covered by this:
                        </p>
                        <ul>
                          <li>
                            UniApp processes your personal
                            data to detect, prevent,
                            investigate and mitigate unlawful
                            activities that may affect your
                            vital interests or those of other
                            individuals.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          We process your personal data in
                          order to pursue our legitimate
                          interests (Art. 6 para. 1 lit. f)
                          DSGVO), but only to the extent that
                          your interests, fundamental rights
                          and freedoms are not overridden. On
                          this basis, we process your data for
                          the following purposes:
                        </p>
                        <ul>
                          <li>
                            UniApp processes your personal
                            data for the purpose of
                            participating in proceedings
                            (including legal proceedings)
                            conducted by state courts, law
                            enforcement agencies or other
                            governmental, intergovernmental or
                            supranational bodies, in
                            particular for the purpose of
                            prosecuting, clarifying and
                            investigating unlawful acts, to
                            the extent that we may assume that
                            the disclosure of the personal
                            data is necessary to prevent
                            imminent danger or unlawful acts;
                          </li>
                          <li>
                            UniApp processes your personal
                            data to prevent, mitigate,
                            investigate, prosecute and detect
                            fraud or other unlawful
                            activities;
                          </li>
                          <li>
                            We process your personal data to
                            carry out identity checks and for
                            verification purposes;
                          </li>
                          <li>
                            We process personal data to
                            protect the legitimate interests
                            of third parties in the context of
                            civil disputes in order to avert
                            imminent disadvantages, insofar as
                            UniApp may assume that it is
                            necessary to disclose the personal
                            data to such third parties;
                          </li>
                          <li>
                            UniApp processes personal data for
                            the automatic filtering and, where
                            necessary, subsequent manual
                            review of messages sent via our
                            messaging tools to protect against
                            unlawful activity or breaches of
                            UniApp’s terms of use;
                          </li>
                          <li>
                            UniApp processes your personal
                            data to assess the effectiveness
                            and success of our marketing
                            campaigns;
                          </li>
                          <li>
                            UniApp processes your personal
                            data in the event of the
                            initiation, preparation and
                            implementation of a company
                            acquisition, merger or takeover.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>
                      Storage period, storage location and
                      deletion
                    </h4>
                    <p>
                      We will retain your personal information
                      for as long as is necessary to fulfil
                      the purposes set out in this privacy
                      policy, for as long as we have not
                      otherwise communicated this to you, or
                      for as long as permitted by applicable
                      law. We will treat the personal
                      information we collect in accordance
                      with our data retention and deletion
                      policy. In addition to deletion, our
                      policy also provides for the
                      anonymization of certain data. Retention
                      periods for personal data vary from
                      country to country.
                    </p>
                    <p>
                      The following is an exemplary list of
                      common retention periods:
                    </p>
                    <ul>
                      <li>
                        Data and information obtained by
                        UniApp from technical means such as
                        cookies or other analysis tools will
                        be retained by us for a period of up
                        to one year from the expiry of the
                        cookies.
                      </li>
                      <li>
                        <p>
                          Your personal data will generally
                          only be stored for as long as it is
                          required for the purposes set out in
                          this privacy policy. However, we may
                          be required by law to retain your
                          personal data for a longer period of
                          time. These legal obligations may
                          arise for accounting, auditing, or
                          tax purposes, among others. The
                          retention periods in Europe are
                          generally between six and ten years.
                        </p>
                        <p>
                          We may also restrict the processing
                          of your data instead of deleting it
                          if this is permitted or required by
                          law. This applies in particular in
                          cases where we still need the data
                          for possible legal prosecution,
                          legal defence or otherwise. The
                          statutory limitation and retention
                          periods are then decisive for the
                          duration of the restriction and
                          processing. The individual retention
                          periods depend on the respective
                          personal data, the applicable
                          national law and the purposes for
                          which we need them. After expiry of
                          the relevant periods, the data will
                          then be permanently deleted.
                        </p>
                      </li>
                      <li>
                        The data we collect from you may be
                        stored and retained at a designated
                        location outside the European Economic
                        Area (EEA). Only selected third
                        parties will transfer the data. Some
                        countries outside the EEA may have
                        lower levels of data protection or no
                        data protection laws at all. Where
                        this is the case, UniApp will ensure
                        that personal data is adequately
                        protected and only transferred through
                        procedures approved by the EU
                        Commission.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h4>Your rights</h4>
                    <p>
                      Subject to possible restrictions under
                      national law, as a data subject you have
                      the right of access, accuracy,
                      objection, porting, deletion and
                      complaint.
                    </p>
                    <p>Your rights in detail:</p>
                    <ul>
                      <li>
                        You have the right to request a copy
                        of the personal data UniApp holds
                        about you. There are exceptions to
                        this right, so that access to this
                        data may be denied if, for example,
                        personal data would be disclosed to a
                        third party in this context or UniApp
                        is legally prevented from disclosing
                        this information.
                      </li>
                      <li>
                        UniApp strives to keep your personal
                        data as accurate, up-to- date and
                        complete as possible. If anything has
                        changed in your personal data, you can
                        adjust it yourself in your UniApp user
                        account.
                      </li>
                      <li>
                        Under certain circumstances, you have
                        the right to object to the processing
                        of your personal data and to ask us to
                        delete, restrict or block your
                        personal data.
                      </li>
                      <li>
                        You have the right to request UniApp
                        to provide you or another data
                        processor with your personal data in a
                        commonly used, machine-readable
                        format.
                      </li>
                      <li>
                        You have the right to have your
                        personal data deleted if the personal
                        data is no longer needed for the
                        purposes for which it was collected by
                        UniApp or has been processed
                        unlawfully, for example.
                      </li>
                      <li>
                        If you believe that your personal data
                        may have been breached by UniApp, you
                        have the right to seek redress from
                        the competent courts or to lodge a
                        complaint with the competent
                        supervisory authority.
                      </li>
                      <li>
                        In principle, the processing of your
                        application to enforce one of these
                        rights is free of charge. However, if
                        there are reasonable reasons to
                        suspect that your application is
                        manifestly unfounded or contrary to
                        the principles of good faith, we
                        reserve the right in individual cases
                        to charge an appropriate fee in
                        accordance with the statutory
                        provisions, up to the amount of our
                        actual costs or to refuse to process
                        the application.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h4>Revocation of consent</h4>
                    <p>
                      You have the right, if you have
                      consented to the collection, processing
                      and disclosure of personal data, to
                      revoke your consent in whole or in part.
                      To revoke your consent, please contact
                      us at
                      <a href="mailto:laurenz@uni-app.com"
                      >laurenz@uni-app.com</a
                      >.
                    </p>
                    <p>
                      UniApp will no longer use your personal
                      data for the purposes for which you
                      originally consented once we have
                      received your revocation. The lawfulness
                      of the processing carried out on the
                      basis of the consent until the
                      revocation is not affected by the
                      revocation.
                    </p>
                  </li>
                  <li>
                    <h4>Our news tools</h4>
                    <p>
                      UniApp offers you the possibility to
                      send direct messages via our platform.
                      All messages sent are first received by
                      us and then forwarded to the addressee.
                      In the process, we automatically filter
                      the messages according to certain
                      criteria and, if necessary, carry out a
                      manual check afterwards.
                    </p>
                    <p>
                      This process serves to protect
                      legitimate interests such as protection
                      against fraud or similar suspicious
                      activities such as phishing or spam. In
                      the event of violations of our terms of
                      use, we reserve the right to temporarily
                      block or permanently delete your user
                      account.
                    </p>
                    <p>
                      As a part of our free service, we will
                      create an account for you in our
                      platform, UniApp, where you can
                      communicate with the schools and manage
                      your preferences.
                    </p>
                  </li>
                  <li>
                    <h4>Data security</h4>
                    <p>
                      UniApp takes the protection of your
                      personal data very seriously and
                      therefore takes appropriate technical,
                      digital, physical and organizational
                      measures to protect your data from
                      unlawful use, access, misuse by third
                      parties or loss. These may include
                      firewalls, access restrictions and
                      authorization controls for data access
                      only by certain employees.
                    </p>
                  </li>
                  <li>
                    <h4>
                      Use of cookies and similar technologies
                    </h4>
                    <p>
                      When you use our services, we or the
                      companies we work with may place cookies
                      and similar technologies on your
                      computer or mobile device. These
                      technologies help us to improve user
                      behavior and tell us which parts of our
                      website have been visited. Also the use
                      of cookies enables personalised
                      advertising and a personalised user
                      experience. UniApp uses first-,
                      third-party and session cookies.
                    </p>
                    <p>
                      Our cookies and similar technologies
                      have different functions:
                    </p>
                    <ol>
                      <li>
                        <p>Essential cookies</p>
                        <p>
                          These types of cookies are necessary
                          for our website to function and
                          cannot be turned off by our system.
                          They are usually only set in
                          response to actions you take to
                          request services (e.g. setting
                          privacy preferences). In addition,
                          the cookies that are strictly
                          necessary include those that we use
                          to prevent fraud.
                        </p>
                      </li>
                      <li>
                        <p>Performance / Analytics Cookies</p>
                        <p>
                          These types of cookies allow us to
                          count visits and traffic sources in
                          order to measure and improve the
                          performance of our website. These
                          cookies allow us to find out which
                          pages get the most attention and
                          which are the least popular with our
                          users. All information collected by
                          these cookies is anonymised.
                        </p>
                      </li>
                      <li>
                        <p>Functional cookies</p>
                        <p>
                          These types of cookies allow us to
                          remember the choices you make on our
                          website and to tailor our services
                          to present content that is relevant
                          to you.
                        </p>
                      </li>
                      <li>
                        <p>Advertising cookies</p>
                        <p>
                          We use these cookies to display
                          advertising that is tailored to your
                          surfing behaviour, among other
                          things. These cookies are also used
                          to measure the effectiveness of
                          advertising campaigns. The cookies
                          remember that you have visited a
                          website and this information can
                          then be passed on to other
                          companies, such as advertisers. This
                          means that after visiting our
                          websites, you may be shown
                          advertisements for our services on
                          other websites.
                        </p>
                      </li>
                      <li>
                        <p>Elective options:</p>
                        <p>
                          You have the option to deactivate
                          the use of cookies and similar
                          technologies if your device supports
                          this. You can adjust the cookie
                          settings in your browser or in the
                          device settings.
                        </p>
                        <p>
                          You are free to disable the use of
                          cookies and similar technologies if
                          your device supports this. You can
                          manage your cookie settings in your
                          browser or device settings. In
                          addition, you can decide whether we
                          may use cookies and similar
                          technologies to display personalised
                          advertisements to you.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h4>Contact us</h4>
                    <p>
                      If you have any questions regarding this
                      privacy policy, please contact us at any
                      time at
                      <a href="mailto:laurenz@uni-app.com"
                      >laurenz@uni-app.com</a
                      >
                    </p>
                    <p>
                      Neom GmbH (UniApp) <br />
                      Neue Mainzer Str. 46-5060311<br />
                      Frankfurt am Main<br />
                      Germany
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default PrivacyAndTerms;