import React from 'react';
import './index.css'

const Universities = ({ data }) => {
    return (

        <body>
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </body>


    );
}
export default Universities;