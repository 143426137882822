import React from 'react';
import './index.css'

const HowToApply = () => {


    return (
        <>
           <div content-tab="howtoapply">
                <h2>
                  How to apply - Czech Republic
                </h2>
                <p>
                  The application of medical schools is based on your high
                  school scores. Having a bachelor of science degree is a must
                  to be eligible for the programme. You will have to take an
                  entrance exam. Upon clearing the exam, you’ll have to appear
                  for an interview. Most of these exams and interviews are
                  conducted at the university or at the representative offices
                  in different parts of the world. You’ll have to prove your
                  English proficiency either by Skype interview or by clearing
                  exams conducted by the universities.
                </p>
                <p>
                  It is best to apply to more than one university through UniApp
                  as it increases the possibility of getting admission.
                </p>
                <h2>
                  <span style={{fontWeight:'400'}}
                    >Required paper to Study Medicine in Czech Republic</span
                  >
                </h2>
                <p>
                  <span style={{fontWeight:'400'}}
                    >-The original high school transcripts in English, as
                    certified by the Ministry of Foreign Affairs.</span
                  >
                </p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >-The original high school diploma in English attested by a
                    foreign official.</span
                  >
                </p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >-A passport photocopy (the first three pages).</span
                  >
                </p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >-A current disease-free certificate written in
                    English.</span
                  >
                </p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >-A certificate of non-judgment in English that has been
                    translated into Czech and certified by the Ministry of
                    Foreign Affairs.</span
                  >
                </p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >-An original English birth certificate.</span
                  >
                </p>
                <p>
                  <span style={{fontWeight:'400'}}>–</span
                  ><span style={{fontWeight:'400'}}
                    >A one-year international health insurance policy covering
                    treatment costs of up to €60,000 in accordance with Czech
                    Embassy requirements.</span
                  >
                </p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >-Bank account statement for the student or his guardian (if
                    the account statement is for one of the student’s parents
                    requesting an English-translated letter of commitment to
                    spend)</span
                  >
                </p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >– Motivation letter outlining the reasons for the student’s
                    interest in studying in the Czech Republic, the major he
                    plans to pursue after the end of the preparatory year, any
                    pertinent information regarding his interest in studying in
                    the Czech Republic, and the languages in which the student
                    is proficient.</span
                  >
                </p>
                <p>
                  If you are searching for a destination to study medicine in
                  Czech Republic, we hope this can help you secure your seat and
                  find your dream university. We hope the information will
                  assist you and support your application process, entrance
                  exam, visa process and settling into your life.
                </p>
              </div>
        </>


    );
}
export default HowToApply;