import React from 'react';
import './index.css'

const Living = ({data}) => {


    return (
       
        <body>
        <div dangerouslySetInnerHTML={{ __html: data }} />
    </body>


    );
}
export default Living;