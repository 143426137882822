import React from 'react';
import './index.css'

const About = ({ data }) => {


    return (
        <body className='bodyCountry'>
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </body>



    );
}
export default About;