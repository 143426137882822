import React from 'react';
import './index.css'

const Study = ({data}) => {


    return (
        <body>
        <div dangerouslySetInnerHTML={{ __html: data }} />
    </body>


    );
}
export default Study;