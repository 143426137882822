import React from 'react';
import './Search.css';
const AdmissionRequirements = ({ data }) => {

    return (
        <div className='AdmissionRequirementsBox'>
            <div style={{ marginBottom: '1rem' }}>
                {data?.requiredLanguageTests?.[0]?.testName && (
                    <div>
                        <h5 style={{ marginBottom: '1rem' }}>Language requirements</h5>
                        <div>
                            <p style={{ marginBottom: '1rem' }}>
                                {data?.requiredLanguageTests.length > 0 && 'Any of the followings:'}
                            </p>
                        </div>

                        <div>
                            <table style={{ borderCollapse: 'collapse', border: '1px solid #808080' }}>
                                <thead></thead>
                                <tbody>
                                    <tr style={{ border: '1px solid #808080' }}>
                                        <td style={{ border: '1px solid #808080', padding: '5px', color: "#000", fontWeight: '400' }}>
                                            Test Name
                                        </td>
                                        <td style={{ border: '1px solid #808080', padding: '5px', color: "#000", fontWeight: '400' }}>
                                            Minimum Score
                                        </td>
                                        <td style={{ border: '1px solid #808080', padding: '5px', color: "#000", fontWeight: '400' }}>
                                            Minimum Score Level
                                        </td>
                                    </tr>
                                    {data?.requiredLanguageTests.map((ln, index) => (
                                        <tr key={index} style={{ border: '1px solid #808080' }}>
                                            <td style={{ border: '1px solid #808080', padding: '2px' }}>
                                                {ln?.testName}
                                            </td>
                                            <td style={{ border: '1px solid #808080', padding: '2px' }}>
                                                {ln?.minScore}
                                            </td>
                                            <td style={{ border: '1px solid #808080', padding: '2px' }}>
                                                {ln?.minScoreLabel}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                <div style={{ marginBottom: '1rem' }}>
                    <h5 style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                        Details on admission requirements
                    </h5>
                    <table style={{}}>
                        <tbody>
                            {data?.admissionRequirement?.minimumLevelText && (
                                <tr>
                                    <td>{data?.admissionRequirement.minimumLevelText}</td>
                                </tr>
                            )}
                            <tr>
                                <td>
                                    <b>Other requirements:</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p
                                        style={{ whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{ __html: data?.admissionRequirement?.other }}
                                    ></p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AdmissionRequirements;
