import React from 'react';
import './index.css';
const ImageSection = () => {
    return (
        <div className="container">
            <div className="background-image"></div>
            <div className="overlay"></div>
            <div className="centered-text">
                Your Guide To College, University Planning
            </div>
        </div>
    );
}
export default ImageSection;