import React, { useState, useEffect } from 'react';
import './Search.css';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WOW from 'wowjs';
import CancelIcon from '@mui/icons-material/Cancel';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import {
    Container,
    TextField,
    Slider,
    Radio,
    Pagination,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Autocomplete
} from '@mui/material';
import { useNavigate } from 'react-router';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const inputStyle = {
    backgroundColor: '#fff',
    borderRadius: '18px',
    margin: '15px 0px',
    width: '300px',
};

const DegreeFilter = ({ label, options, value, onChange, removeTab }) => (
    <>
        <p style={{ margin: '10px 0', fontWeight: '600', fontSize: '15px' }}>{label}</p>
        <Autocomplete
            multiple
            options={options}
            value={value}
            onChange={onChange}
            size='small'
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={`Select ${label.toLowerCase()}`}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                />
            )}

            renderOption={(props, option, { selected }) => (
                <div {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ flexGrow: 1 }}>{option}</div>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginLeft: 8 }}
                        checked={selected}
                    />
                </div>
            )}
        />

    </>
);

const CountryFilter = ({ label, options, value, onChange }) => (
    <>
        <p style={{ margin: '10px 0', fontWeight: '600', fontSize: '15px' }}>{label}</p>
        <Autocomplete
            multiple
            options={options}
            value={value.map(option => option.name)}
            onChange={onChange}
            size='small'
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
                <TextField  {...params} variant="outlined" placeholder={`Select ${label.toLowerCase()}`} />
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {option}
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginLeft: 8 }}
                        checked={selected}
                    />
                </li>
            )}
        />
    </>
);

const RangeSlider = ({ label, minValue, maxValue, onChange }) => (
    <>
        <p style={{ margin: '10px 0', fontWeight: '600', fontSize: '15px' }}> {label}: {Number(minValue)} - {Number(maxValue)}</p>
        <div style={{ padding: '0 10px' }}>
            <Slider
                size='small'
                value={[minValue, maxValue]}
                onChange={onChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                max={12}
            />
        </div>
    </>
);

const RadioGroupFilter = ({ label, value, onChange, options }) => (
    <>
        <p style={{ margin: '10px 0', fontWeight: '600', fontSize: '15px' }}> {label}</p>
        <RadioGroup
            aria-label="vertical-radio-group"
            name="vertical-radio-group"
            value={value}
            onChange={onChange}
            orientation="vertical"
        >
            {options.map((option) => (

                <FormControlLabel
                    key={option}
                    style={{ margin: '0', padding: '0' }}
                    value={option}
                    control={<Radio size='small' style={{ margin: '5px 2px', padding: '0' }} />}  // Apply styles to the Radio component
                    label={option}
                />

            ))}
        </RadioGroup>
    </>
);

const CheckboxFilter = ({ values, onChange }) => (
    <>
        <p style={{ margin: '10px 0', fontWeight: '600', fontSize: '15px' }}> Attendance</p>
        <div>
            <FormGroup>
                {Object.entries(values).map(([name, checked]) => (
                    <FormControlLabel
                        key={name}
                        style={{ margin: '0', padding: '0' }}
                        control={<Checkbox size='small' style={{ margin: '5px 2px', padding: '0' }} checked={checked} onChange={onChange} name={name} />}
                        label={name}
                    />
                ))}
            </FormGroup>
        </div>
    </>
);

const Search = () => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const qValue = queryParams.get('q');

    const institute = queryParams.get('institute');
    const navigate = useNavigate();
    // const [scrollPosition, setScrollPosition] = useState(0);
    // const dynamicLimit = 25000;
    const [searchInput, setSearchInput] = useState(`${qValue && qValue?.length > 0 ? qValue : ''}`);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(12);
    const [selectedValue, setSelectedValue] = useState('Any');
    const [mainDataFilter, setMainDataFilter] = useState([]);
    const [isFilterVisible, setIsFilterVisible] = useState(true);
    const [FeesValue, setFeesValue] = useState('0');
    const [checkboxValues, setCheckboxValues] = useState({ Online: false, FullTime: false, PartTime: false, });
    const [data, setData] = useState([]);
    const [courseArray, setCourseArray] = useState([]);
    const [countryArray, setCountryArray] = useState([]);
    const [disciplineArray, setDisciplineArray] = useState([]);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [dataLevels, setDataLevels] = useState([])
    const [selectedLanguage, setSelectedLanguage] = useState([]);
    const [dataLanguages, setDataLanguages] = useState([]);
    const [selectDiscipline, setSelectDiscipline] = useState([]);
    const [dataCategories, setDataCategories] = useState([]);
    const [selectCountry, setSelectCountry] = useState([]);
    const [dataCountry, setDataCountry] = useState([]);
    const [selectUniversity, setSelectUniversity] = useState([]);
    const [dataInstitutions, setDataInstitutions] = useState([]);
    const [totalPages, setTotalPages] = useState(null);

    const handleChange = (event, value) => setSelectedOptions(value);
    const handleInputChange = (event) => setSearchInput(event.target.value);
    const handleLanguageChange = (event, value) => setSelectedLanguage(value);
    const handelDiscipline = (event, value) => setSelectDiscipline(value);


    const handelCountry = (event, newValue) => {
        const selectedCountries = newValue.map(country => ({ type: "Country", name: country }));

        setSelectCountry(selectedCountries);
    };
    const handelUniversity = (event, value) => setSelectUniversity(value);
    const handleChangeRange = (event, newValue) => { setMinValue(newValue[0]); setMaxValue(newValue[1]); };
    const handleChangeRadio = (event) => setSelectedValue(event.target.value);
    const handleChangeCheckboxValues = (event) => setCheckboxValues({ ...checkboxValues, [event.target.name]: event.target.checked });
    const [page, setPage] = useState(1);

    const scrollView = () => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        function parseQueryParamArray(queryParams, paramName) {
            let paramArray = [];

            if (queryParams && queryParams.get(paramName)) {
                let paramValue = queryParams.get(paramName);
                paramArray = paramValue.split(',').map(value => value.trim());
            }
            return paramArray;
        }
        setCourseArray(parseQueryParamArray(queryParams, 'course'));
        setCountryArray(parseQueryParamArray(queryParams, 'country'));
        setDisciplineArray(parseQueryParamArray(queryParams, 'discipline'));

    }, []);

    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
    };

    const viewDetails = (item) => {
        navigate(`/Program/${item.slug}`);
    }

    const removeFromArray = (dataArray, valueToRemove, setFunction) => {
        const updatedOptions = dataArray.filter(option => option !== valueToRemove);
        setFunction(updatedOptions);
    };

    const removeFromArrayByName = (dataArray, valueToRemove, setFunction) => {
        const updatedOptions = dataArray.filter(option => option.name !== valueToRemove);
        setFunction(updatedOptions);
    };

    const removeTab = (valueToRemove) => {
        if (dataLevels.includes(valueToRemove)) {
            removeFromArray(selectedOptions, valueToRemove, setSelectedOptions);
        } else if (dataLanguages.includes(valueToRemove)) {
            removeFromArray(selectedLanguage, valueToRemove, setSelectedLanguage);
        } else if (dataCategories.includes(valueToRemove)) {
            removeFromArray(selectDiscipline, valueToRemove, setSelectDiscipline);
        } else if (dataCountry.includes(valueToRemove)) {
            removeFromArrayByName(selectCountry, valueToRemove, setSelectCountry);
        } else if (dataInstitutions.includes(valueToRemove)) {
            removeFromArray(selectUniversity, valueToRemove, setSelectUniversity);
        } else {
            setSearchInput('');
        }

        if (valueToRemove === searchInput) {
            const updatedMainDataFilter = mainDataFilter.filter(ele => ele !== valueToRemove);
            setMainDataFilter(updatedMainDataFilter);
        }
    };

    const mapCountryArray = () => countryArray.map(country => ({ type: "Country", name: country }));
    const mergeArrays = (arr1, arr2) => [...arr1, ...arr2];

    const buildDTO = () => {
        const city = mergeArrays(mapCountryArray(), selectCountry);
        const courses = mergeArrays(courseArray, selectedOptions);
        const category = mergeArrays(disciplineArray, selectDiscipline);

        return {
            categories: category,
            institutions: institute ? [institute] : selectUniversity,
            isFullTime: checkboxValues.FullTime,
            isOnline: checkboxValues.Online,
            isPartTime: checkboxValues.PartTime,
            languages: selectedLanguage,
            levels: courses,
            locations: city,
            searchText: searchInput,
            semesterMax: maxValue,
            semesterMin: minValue,
            tuitionFee: FeesValue,
        };
    };

    const handleChangePagination = async (event, value) => {
        scrollView();
        setPage(value);

        const url = `https://api.uni-app.com/courses/search-partner-courses?page=${value}&size=50`;
        const DTO = buildDTO();

        try {
            const response = await axios.post(url, DTO);
            const newData = response.data.content;
            setTotalPages(response?.data?.totalPages);
            setData(newData);
        } catch (err) {
            console.log('ERROR WHEN FILTER THE DATA ', err.message);
        }
    };

    const getData = async () => {
        try {
            switch (selectedValue) {
                case 'Any':
                    setFeesValue('0');
                    break;
                case 'Up to 2.500':
                    setFeesValue(2500);
                    break;
                case 'Up to 5.000':
                    setFeesValue(5000);
                    break;
                case 'Up to 7.500':
                    setFeesValue(7500);
                    break;
                case 'Up to 10.000':
                    setFeesValue(10000);
                    break;
                case '+10.000':
                    setFeesValue('-10000');
                    break;
                default:
                    break;
            }

            const DTO = buildDTO();

            const url = `https://api.uni-app.com/courses/search-partner-courses?page=${page}&size=50`;
            const response = await axios.post(url, DTO);
            const newData = response.data.content;
            setTotalPages(response?.data?.totalPages);
            setData(newData);
        } catch (err) {
            console.log('ERROR WHEN FILTER THE DATA ', err.message);
        }
    };



    useEffect(() => {
        getData();

    }, [
        countryArray,
        courseArray,
        institute,
        disciplineArray,
        selectedOptions,
        searchInput,
        selectedLanguage,
        selectDiscipline,
        selectCountry,
        minValue,
        selectUniversity,
        maxValue,
        FeesValue,
        selectedValue,
        checkboxValues])


    const dataFilter = async () => {
        try {
            const res = await axios.get('https://api.uni-app.com/courses/filters?officialOnly=true');
            setDataInstitutions(Object.keys(res.data.institutions))
            setDataCountry(Object.keys(res.data.country))
            setDataCategories(Object.keys(res.data.categories))
            setDataLanguages(Object.keys(res.data.languages))
            setDataLevels(Object.keys(res.data.levels));
        } catch (err) {
            console.log('error when filter data ', err.message);
        }
    }
    useEffect(() => {
        scrollView();
        dataFilter();
    }, [])

    useEffect(() => {
        const filtersSet = new Set([
            ...selectedOptions,
            ...selectedLanguage,
            ...selectDiscipline,
            ...selectUniversity,
            ...(institute && institute?.length > 0 ? [institute] : []),
            (qValue && qValue?.length > 0 ? qValue : searchInput),
            ...(selectCountry.map(country => country.name) || []),
            ...countryArray || [],
            ...courseArray || [],
            ...disciplineArray || [],
        ]);
        const filters = Array.from(filtersSet);

        setMainDataFilter(filters);
    }, [selectedOptions, selectedLanguage, selectDiscipline, selectUniversity, selectCountry, searchInput, qValue && qValue?.length, countryArray
        , courseArray,
        disciplineArray]);



    return (
        <div className="SearchContainer wow fadeInDown" data-wow-duration='1s'>
            <Container maxWidth="lg">
                <div className='mainSearch'>
                    <h2>Find degrees all over the world</h2>

                    <hr />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                        <TextField
                            size='small'
                            placeholder="What are you searching for?"
                            value={searchInput}
                            onChange={handleInputChange}
                            InputProps={{ style: { ...inputStyle } }}
                        />

                        <div className='DataPassed'>
                            <div className='tabsSearch'>
                                {mainDataFilter && mainDataFilter.map((ele) => {
                                    const isSearchInputNotEmpty = searchInput.trim() !== '';
                                    const shouldShowElement = isSearchInputNotEmpty || ele !== searchInput;
                                    return shouldShowElement && (
                                        <div style={{ backgroundColor: '#DDD', fontSize: '13px', margin: '5px', borderRadius: '50px', padding: '5px 10px', display: 'flex', alignItems: 'center' }}>
                                            <SearchIcon style={{ fontSize: '15px' }} />
                                            {ele}
                                            <CancelIcon onClick={() => removeTab(ele)} style={{ fontSize: '15px', marginLeft: '5px', cursor: 'pointer' }} />
                                        </div>
                                    );
                                })}

                            </div>

                        </div>
                        <FilterAltIcon className='filterIcon' onClick={toggleFilterVisibility} />
                    </div>
                </div>
            </Container>

            <div className='searchContent'>
                <Container maxWidth="lg">
                    <div class="flex-container">
                        <aside>
                            {isFilterVisible && (
                                <div className={`filter`}>
                                    <DegreeFilter label="Degree Type" options={dataLevels} value={selectedOptions} onChange={handleChange} />
                                    <DegreeFilter label="Language" options={dataLanguages} value={selectedLanguage} onChange={handleLanguageChange} />
                                    <DegreeFilter label="Discipline" options={dataCategories} value={selectDiscipline} onChange={handelDiscipline} />
                                    <CountryFilter label="Country" options={dataCountry} value={selectCountry} onChange={handelCountry} />
                                    <DegreeFilter label="University" options={dataInstitutions} value={selectUniversity} onChange={handelUniversity} />
                                    <RangeSlider label="Semester" minValue={minValue} maxValue={maxValue} onChange={handleChangeRange} />
                                    <RadioGroupFilter label="Tuition Fees (Per Semester)" value={selectedValue} onChange={handleChangeRadio} options={['Any', 'Up to 2.500', 'Up to 5.000', 'Up to 7.500', 'Up to 10.000', '+10.000']} />
                                    <CheckboxFilter values={checkboxValues} onChange={handleChangeCheckboxValues} />
                                </div>
                            )}
                        </aside>

                        <div className='mainViewData'>
                            {data.map((course, key) => (
                                <div key={key} className='viewData'>
                                    <div className='viewDataContainer'>
                                        <div className='ContainerMainImg'>
                                            <img src={course.logo} alt='image not found' className='mainImg' />
                                        </div>
                                        <div className='degreeCard'>
                                            <h4 onClick={() => { viewDetails(course) }}>{course.title}</h4>
                                            <h5 onClick={() => { viewDetails(course) }}>{course.degree} <CheckCircleIcon style={{ fontSize: '15px', color: '#76d4f9' }} /></h5>
                                            <div className='data'>
                                                <div>
                                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                                                        <CalendarMonthOutlinedIcon style={{ color: '#696969' }} />
                                                        <p style={{ marginTop: '15px', marginLeft: '5px' }}>
                                                            Apply Anytime
                                                        </p>
                                                    </div>

                                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                                                        <LanguageOutlinedIcon style={{ color: '#696969' }} />
                                                        <p style={{ marginTop: '15px', marginLeft: '5px' }}>
                                                            {course.languages[0]}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                                                        <WatchLaterOutlinedIcon style={{ color: '#696969' }} />
                                                        <p style={{ marginTop: '15px', marginLeft: '5px' }}>
                                                            {course.durationInSemester} Semesters
                                                        </p>
                                                    </div>

                                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                                                        <PaymentsOutlinedIcon style={{ color: '#696969' }} />
                                                        <p style={{ marginTop: '15px', marginLeft: '5px' }}>
                                                            {course.tuitionFee} -  {course.tuitionFeeCurrency}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>

                                                    {course.locations && course.locations.length > 1 && (
                                                        <div style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                                                            <LocationOnOutlinedIcon style={{ color: '#696969' }} />
                                                            <p style={{ marginTop: '15px', marginLeft: '5px' }}>
                                                                {course.locations[0].city} +{course.locations.length - 1} more
                                                            </p>
                                                        </div>
                                                    )}
                                                    {course.locations && course.locations.length === 1 && (
                                                        <div style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                                                            <LocationOnOutlinedIcon style={{ color: '#696969' }} />
                                                            <p style={{ marginTop: '15px', marginLeft: '5px' }}>{course.locations[0].city}</p>
                                                        </div>
                                                    )}

                                                    <div style={{ display: 'flex', alignItems: 'center', margin: '0' }}>
                                                        <WorkspacePremiumOutlinedIcon style={{ color: '#696969' }} />
                                                        <p style={{ marginTop: '15px', marginLeft: '5px' }}>
                                                            {course?.level}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='btnCard'>
                                                    <p onClick={() => {
                                                        const url = 'https://my.uni-app.com/sign-in?redirectURL=%2Fhome';
                                                        window.location.href = url;
                                                    }}>
                                                        Apply Now
                                                    </p>
                                                    <p onClick={() => { viewDetails(course) }}>
                                                        Read More
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {data && data.length && data.length > 0 ? <div style={{ display: 'flex', justifyContent: 'center', padding: '15px 0' }}>

                                <Pagination
                                    sx={{
                                        '& .MuiPaginationItem-root': {
                                            color: '#333',
                                            fontSize: '15px',
                                            '&:hover': {
                                                backgroundColor: '#e0e0e0',
                                                color: '#333',
                                            },
                                        },
                                        '& .Mui-selected': {
                                            backgroundColor: '#e0e0e0',
                                            color: '#333',
                                        },
                                    }} count={totalPages - 1} page={page} onChange={handleChangePagination} />
                            </div> : null}
                        </div>

                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Search;




// const fetchData = async () => {
//     try {
//         const data = {
//             "levels": courseArray ? courseArray : [],
//             "languages": [],
//             "institutions": institute ? [institute] : [],
//             "categories": disciplineArray ? disciplineArray : [],
//             'locations': countryArray ? countryArray.map(country => ({ type: "Country", name: country })) : [],
//             "isOnline": false,
//             "isFullTime": false,
//             "searchText": qValue && qValue?.length > 0 ? qValue : searchInput,
//             "isPartTime": false,
//             "semesterMin": 0,
//             "semesterMax": 12,
//             "tuitionFee": 0
//         }
//         const getDTO = () => (data);

//         const DTO = getDTO();
//         const url = `https://api.uni-app.com/courses/search-partner-courses?page=${page}&size=10`;

//         const response = await axios.post(url, DTO);
//         const newData = response.data.content;

//         setData(prevData => [...prevData, ...newData]);
//     } catch (error) {
//         console.error('Error fetching data:', error);
//     }
// };

// useEffect(() => {
//     fetchData();
// }, [page])

// useEffect(() => {
//     const handleScroll = () => {
//         const isScrolledToBottom = window.innerHeight + document.documentElement.scrollTop >= (document.documentElement.offsetHeight - 700);
//         if (isScrolledToBottom) {
//             setPage((prevPage) => prevPage + 1);
//         }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//         window.removeEventListener('scroll', handleScroll);
//     };
// }, [setPage]);