import React, { useState, useEffect } from 'react';
import './index.css';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';


const Header = () => {
    const navigate = useNavigate();

    const [TeamCount, setTeamCount] = useState(0);
    const [RateCount, setRateCount] = useState(0);
    const [StudentCount, setStudentCount] = useState(0);
    const [AgentsCount, setAgentsCount] = useState(0);

    const increaseCount = () => {
        if (TeamCount < 5000) {
            setTimeout(() => {
                setTeamCount(TeamCount + 5);
            }, 0.1);
        }
    };

    const Rate = () => {
        if (RateCount < 25) {
            setTimeout(() => {
                setRateCount(RateCount + 1);
            }, 15);

        }
    }

    const Student = () => {
        if (StudentCount < 99) {
            setTimeout(() => {
                setStudentCount(StudentCount + 1);
            }, 5);

        }
    }

    const Agents = () => {
        if (AgentsCount < 1000) {
            setTimeout(() => {
                setAgentsCount(AgentsCount + 10);
            }, 5);

        }
    }

    useEffect(() => {
        increaseCount();
        Rate();
        Agents();
        Student();
    })

    return (
        <div style={{ backgroundColor: '#f8f8f8', paddingTop: '100px' }}>
            <div style={{ padding: '0px 0px 50px 0px' }}>
                <div className='Container-studentsPage'>
                    <Container maxWidth="lg">
                        <div className='studentsPage-content'>
                            <div>

                                <h1> University <span className='title'>Applications made easy</span> for your student's recruitment activities</h1>
                                <p>When you grow we grow, Uni-App works with the power of artificial
                                    intelligence to simplify the process of recruiting international  Students,  lets make international education a priority</p>
                                <div className='btnStyle' onClick={() => { navigate('/contact-us/') }}>
                                    <span className='btnContact' >
                                        Partner With Us
                                    </span>
                                </div>
                            </div>
                            <div className='count'>


                                <div>
                                    <h1 style={{ fontWeight: '800' }}>{TeamCount}+</h1>
                                    <p style={{ color: '#3f3a64' }}> programs</p>
                                </div>

                                <div>
                                    <h1 style={{ fontWeight: 'bold' }}>{StudentCount}%</h1>
                                    <p style={{ color: '#3f3a64' }}> SATISFACTION RATE</p>
                                </div>

                                <div>
                                    <h1 style={{ fontWeight: '800' }}>{RateCount}+</h1>
                                    <p style={{ color: '#3f3a64' }}> COUNTRIES</p>
                                </div>




                          
                                <div >
                                    <h1 style={{ fontWeight: '800' }}>{AgentsCount}+</h1>
                                    <p style={{ color: '#3f3a64' }}>STUDY AGENTS </p>
                                </div>


                            </div>
                        </div>
                    </Container>
                </div>
            </div>

        </div>
    );
}
export default Header;