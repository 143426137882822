import React, { useEffect } from 'react';
import './index.css';
import { Container } from '@mui/material';
import WOW from 'wowjs';
import Pages from './Pages';
const Terms = () => {

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0)

  }, [])
  return (
    <div style={{ padding: '50px 0px' }}>
      <Container maxWidth="lg">
        <div className='mainBox'>
          <div className='left-side'>
            <Pages />
          </div>
          <div className='right-side wow fadeInDown' data-wow-duration='1s' >

            <div
              className="ContainerTerms"
            >
              <div
                data-id="f3390cb"
                data-element_type="column"
              >
                <div
                >
                  <div
                    data-id="3dad8ab"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div >

                      <h2

                      >
                        Terms of Use
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div
            >
              <div
                data-id="15f8c31"
                data-element_type="column"
              >
                <div
                >
                  <div
                    data-id="a5afd65"
                    data-element_type="widget"
                    data-widget_type="text-editor.default"
                  >
                    <div>

                      <p>
                        <span
                          //   style={{letter-spacing: 0em"}}
                          data-contrast="none"
                        >These terms of use (“Terms of Use”),
                          including the privacy policy available on our
                          website (“Privacy Policy”) and any other
                          terms, conditions, notices and disclaimers
                          published, displayed or otherwise provided to
                          you from time to time govern your use of, or
                          access to, </span
                        ><span
                          //   style="letter-spacing: 0em"
                          data-contrast="none"
                        >uni-app.com</span
                        ><span
                          //   style="letter-spacing: 0em"
                          data-contrast="none"
                        >.</span
                        >
                      </p>
                      <p>
                        <span data-contrast="none"
                        >When we use the term “</span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none"
                        >”, we mean the “</span
                        ><span data-contrast="none">uni-app.com</span
                        ><span data-contrast="none"
                        >” domain (including any mobile sites), </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">
                          computing platform and any applications, and
                          all other websites, mobile applications,
                          software, components, products and
                          services.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >These Terms of Use are a legal agreement
                          between you and </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none"
                        >. You acknowledge and agree that in using or
                          accessing </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">
                          you are bound by these Terms of Use.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >Should you not agree or accept these Terms of
                          Use, including any modifications or variations
                          to these Terms of Use from time to time, your
                          sole remedy is to immediately cease your use
                          of and access to </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >The purpose of this website is to provide you
                          with information about </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">
                          and services provided by </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none"
                        >. If there is any conflict in the between
                          these website Terms of Use and the terms and
                          conditions of any subscription and access to </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">
                          (“Terms and Conditions”), the Terms and
                          Conditions shall prevail.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <ol>
                        <li>
                          <span data-contrast="none"> Language</span>
                        </li>
                      </ol>
                      <p>
                        <span data-contrast="none"
                        >These Terms of Use shall only be produced in
                          the English language. If English is not your
                          first language, it is your responsibility to
                          have these Terms of Use translated by a
                          professional translator. </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">
                          shall not be held responsible for any errors,
                          omissions or misunderstandings arising from or
                          as a result of any translation of these Terms
                          of Use.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <ol start="2">
                        <li>
                          <span data-contrast="none">
                            Modification to the Terms of Use</span
                          >
                        </li>
                      </ol>
                      <p>
                        <span data-contrast="none"
                        >To the fullest extent permitted by law, you
                          acknowledge and agree that </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">
                          reserves the right to modify or vary any
                          terms, conditions, notices or disclaimers in
                          relation to these Terms of Use, at its sole
                          and absolute discretion, without notice and
                          without liability to you or any third
                          party.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >In the event the Terms of Use are modified or
                          varied in any way whatsoever, your use and/or
                          continued use of </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">
                          constitutes your acceptance of any modified or
                          varied Terms of Use.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <ol start="3">
                        <li>
                          <span data-contrast="none">
                            Privacy Policy</span
                          >
                        </li>
                      </ol>
                      <p>
                        <span data-contrast="none"
                        >Your privacy is important to us. Our Privacy
                          Policy explains how we may collect, store and
                          use your personal information from and about
                          you when you visit our website. This Privacy
                          Policy applies to any personal information
                          that is collected when you use our services or
                          visit our website.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >You are responsible for reviewing our Privacy
                          Policy.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >If you do not wish to provide personal
                          information to us, then you are not obliged to
                          do so. However, please be aware that this will
                          affect your use of </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <ol start="4">
                        <li>
                          <span data-contrast="none">
                            Intellectual Property</span
                          >
                        </li>
                      </ol>
                      <p>
                        <span data-contrast="none">OWNERSHIP</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="1"
                          aria-setsize="-1"
                          data-aria-posinset="1"
                          data-aria-level="1"
                        >
                          <span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            is owned by </span
                          ><span data-contrast="none">Neom Gmbh</span
                          ><span data-contrast="none"
                          >, its related bodies corporate and/or its
                            third party licensors. You acknowledge </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            title to and interest in </span
                          ><span data-contrast="none">uni-app.com</span
                          ><span data-contrast="none">.</span
                          ><span data-ccp-props='{"134233118":true}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="1"
                          aria-setsize="-1"
                          data-aria-posinset="2"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >All Intellectual Property Rights arising
                            out of or in connection with the </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            website are owned by </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none"
                          >, its related bodies corporate and/or its
                            third party licensors. You acknowledge </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            title to and interest in the Intellectual
                            Property Rights.</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="1"
                          aria-setsize="-1"
                          data-aria-posinset="3"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >For the purpose of this section 4,
                            “Intellectual Property Rights” means all
                            intellectual and industrial property
                            (whether existing now or in the future, and
                            whether or not registered or registrable),
                            including all copyright (including moral
                            rights), patents, trademarks (including
                            service marks), designs, Confidential
                            Information, circuit layout, inventions,
                            discoveries, geographical indications of
                            origin, circuit layouts, programming tools,
                            object code, source code, methods,
                            techniques, recipes, formulae, algorithms,
                            modules, libraries, databases, and
                            includes:</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="1"
                          aria-setsize="-1"
                          data-aria-posinset="0"
                          data-aria-level="2"
                        >
                          <span data-contrast="none"
                          >any application or right to apply for
                            registration of any of these rights;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="1"
                          aria-setsize="-1"
                          data-aria-posinset="0"
                          data-aria-level="2"
                        >
                          <span data-contrast="none"
                          >all licences and rights to use the
                            intellectual property;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="1"
                          aria-setsize="-1"
                          data-aria-posinset="0"
                          data-aria-level="2"
                        >
                          <span data-contrast="none"
                          >any registration of any of those rights or
                            any registration of any application referred
                            to above; and</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="1"
                          aria-setsize="-1"
                          data-aria-posinset="0"
                          data-aria-level="2"
                        >
                          <span data-contrast="none"
                          >all renewals and extensions of these
                            rights.</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <div>&nbsp;</div>
                      <div>&nbsp;</div>
                      <p>
                        <span data-contrast="none">YOUR COVENANTS</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >You may download or print certain
                          information, material or content appearing on </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">
                          for informational purposes only, provided that
                          you ensure that </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">
                          Intellectual Property Rights are
                          maintained.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none">You must:</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="2"
                          aria-setsize="-1"
                          data-aria-posinset="1"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >not represent yourself as the owner of or
                            having any interest in </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            Intellectual Property Rights or any part of
                            it;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="2"
                          aria-setsize="-1"
                          data-aria-posinset="2"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >not use or allow the use of </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            Intellectual Property Rights or any part of
                            it in a manner that is contrary to, or
                            conflicts with, or in any way damages the
                            title or interest of </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            in the Intellectual Property Rights;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="2"
                          aria-setsize="-1"
                          data-aria-posinset="3"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >not use any brand name, trade name,
                            trademark, logo or design of </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            or its affiliates;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="2"
                          aria-setsize="-1"
                          data-aria-posinset="4"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >not challenge or call into question in any
                            way the right, title, interest and goodwill
                            of </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            in respect of the </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            Intellectual Property Rights;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="2"
                          aria-setsize="-1"
                          data-aria-posinset="1"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >not register or attempt to register under
                            the provisions of any statute or otherwise </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            Intellectual Property Rights;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="2"
                          aria-setsize="-1"
                          data-aria-posinset="2"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >not produce, modify, transmit, post, use, </span
                          ><span data-contrast="none">license</span
                          ><span data-contrast="none"
                          >, distribute, reverse engineer or make a
                            copy of </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            Intellectual Property Rights for any
                            purpose;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="2"
                          aria-setsize="-1"
                          data-aria-posinset="3"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >not deface, remove or obscure any of </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            Intellectual Property Rights; and</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="2"
                          aria-setsize="-1"
                          data-aria-posinset="4"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >do all other acts and things that may be
                            reasonably required by </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            to ensure the protection of </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            Intellectual Property Rights.</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <ol start="5">
                        <li>
                          <span data-contrast="none">
                            Third Party Content</span
                          >
                        </li>
                      </ol>
                      <p>
                        <span data-contrast="none"
                        >You acknowledge and agree that:</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="3"
                          aria-setsize="-1"
                          data-aria-posinset="1"
                          data-aria-level="1"
                        >
                          <span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            may contain other third party content or
                            links to other websites (“Third Party
                            Content”) that is provided solely as a
                            convenience to you during your use of </span
                          ><span data-contrast="none">UniApp</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="3"
                          aria-setsize="-1"
                          data-aria-posinset="2"
                          data-aria-level="1"
                        >
                          <span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            does not endorse the Third Party Content and
                            is not responsible for the creation,
                            maintenance or accuracy of the Third Party
                            Content;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="3"
                          aria-setsize="-1"
                          data-aria-posinset="3"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >you rely on the Third Party Content at your
                            own risk; and</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="3"
                          aria-setsize="-1"
                          data-aria-posinset="4"
                          data-aria-level="1"
                        >
                          <span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            makes no representation, statement or
                            warranty, either expressly or implicitly, in
                            relation to the accuracy, timeliness,
                            performance, completeness or suitableness of
                            the Third Party Content on </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">.</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <ol start="6">
                        <li>
                          <span data-contrast="none">
                            Your equipment</span
                          >
                        </li>
                      </ol>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="4"
                          aria-setsize="-1"
                          data-aria-posinset="1"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >You are responsible for all equipment,
                            operating systems, networks, software and
                            internet service providers that you use or
                            may use to access </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none"
                          >, including the maintenance, upkeep, repair
                            and performance of that equipment and
                            systems.</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559685":1440,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="4"
                          aria-setsize="-1"
                          data-aria-posinset="2"
                          data-aria-level="1"
                        >
                          <span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            accepts no responsibility for any lack of
                            access or inability to use </span
                          ><span data-contrast="none">Uni-App</span
                          ><span data-contrast="none">.</span
                          ><span data-contrast="none">c</span
                          ><span data-contrast="none">o</span
                          ><span data-contrast="none">m</span
                          ><span data-contrast="none">.</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559685":1440,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <ol start="7">
                        <li>
                          <span data-contrast="none"> Disclaimers</span>
                        </li>
                      </ol>
                      <p>
                        <span data-contrast="none"
                        >To the fullest extent permitted by law:</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="5"
                          aria-setsize="-1"
                          data-aria-posinset="1"
                          data-aria-level="1"
                        >
                          <span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            does not make any representations,
                            statements or warranties whatsoever, either
                            expressly or implicitly, in relation to the
                            accuracy, timeliness, performance,
                            completeness or suitability of the content
                            on </span
                          ><span data-contrast="none">UniApp</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="5"
                          aria-setsize="-1"
                          data-aria-posinset="2"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >You acknowledge that your use of </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            at your own risk;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="5"
                          aria-setsize="-1"
                          data-aria-posinset="3"
                          data-aria-level="1"
                        >
                          <span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            does not make any representations,
                            statements</span
                          ><span data-contrast="none">,</span
                          ><span data-contrast="none">
                            or warranties whatsoever, either expressly
                            or implicitly, that your use or access to </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            shall be timely, secure, free from
                            interruption (whether due to any temporary
                            and/or partial breakdown, repair,
                            upgrade</span
                          ><span data-contrast="none">,</span
                          ><span data-contrast="none">
                            or maintenance of </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none"
                          >) or free from error;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="5"
                          aria-setsize="-1"
                          data-aria-posinset="4"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >You are responsible for ensuring that you
                            have read and understood the Terms of Use
                            and Privacy Policy and keep up to date with
                            any modifications or amendments of the Terms
                            of Use and Privacy Policy;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="5"
                          aria-setsize="-1"
                          data-aria-posinset="5"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >You acknowledge that downloading or viewing
                            of any content, including Third Party
                            Content, is done so at your own risk and </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            does not make any representations,
                            statements</span
                          ><span data-contrast="none">,</span
                          ><span data-contrast="none">
                            or warranties whatsoever, either expressly
                            or implicitly, that the content, including
                            Third Party Content, shall be fit for
                            purpose or virus free; and</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="5"
                          aria-setsize="-1"
                          data-aria-posinset="1"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >You are responsible for all equipment,
                            operating systems, networks, software</span
                          ><span data-contrast="none">,</span
                          ><span data-contrast="none">
                            and internet service providers that you use
                            or may use to access to </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none"
                          >, including the maintenance, upkeep, repair
                            and performance of that equipment and
                            systems and </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">
                            does not make any representations,
                            statements or warranties whatsoever, either
                            expressly or implicitly, that your
                            equipment, operating systems, networks,
                            software and internet service providers are
                            fit for purpose to use or access to </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">.</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <ol start="8">
                        <li>
                          <span data-contrast="none"> Indemnity</span>
                        </li>
                      </ol>
                      <p>
                        <span data-contrast="none"
                        >To the fullest extent permitted by law, you
                          agree to release, hold harmless and indemnify </span
                        ><span data-contrast="none">UniApp</span
                        ><span data-contrast="none">
                          from and against any claims, suits,
                          liabilities, damages, injuries, costs,
                          expenses (including indirect and consequential
                          loss) whatsoever and however arising out of or
                          in connection with:</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="6"
                          aria-setsize="-1"
                          data-aria-posinset="2"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >your use or access to, or misuse of, </span
                          ><span data-contrast="none">UniApp</span
                          ><span data-contrast="none">;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="6"
                          aria-setsize="-1"
                          data-aria-posinset="3"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >your breach of these Terms of Use;</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="6"
                          aria-setsize="-1"
                          data-aria-posinset="1"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >your violation, infringement or
                            contravention of any third party rights;
                            and</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="6"
                          aria-setsize="-1"
                          data-aria-posinset="2"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >your infringement or noncompliance with any
                            applicable law.</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <ol start="9">
                        <li>
                          <span data-contrast="none">
                            Miscellaneous</span
                          >
                        </li>
                      </ol>
                      <p>
                        <span data-contrast="none">WAIVER</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >A waiver of a provision of or right under
                          these Terms of Use is effective only if it is
                          in writing signed by the party granting the
                          waiver.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none">INVALIDITY</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >Any provision of these Terms of Use which is
                          invalid or unenforceable in any jurisdiction
                          will, as to that jurisdiction only, be read
                          down or severed to the extent of that
                          invalidity or unenforceability. The remaining
                          provisions of these Terms of Use which are
                          self-sustaining and capable of separate
                          enforcement without regard to the read down or
                          severed provision in that jurisdiction are and
                          will continue to be valid and enforceable in
                          accordance with their terms.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >ENTIRE AGREEMENT</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >These Terms of Use, together with any
                          documents referred to in these Terms of Use or
                          provided in conjunction with these Terms of
                          Use from time to time, comprises the entire
                          agreement between the parties with respect to
                          the subject matter of these Terms of
                          Use.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none">GOVERNING LAW</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >This Agreement shall be governed and
                          construed in all respects in accordance with
                          the laws of </span
                        ><span data-contrast="none">Germany</span
                        ><span data-contrast="none">
                          and, except where restricted by the applicable
                          Law, the parties hereby irrevocably submit to
                          the exclusive jurisdiction of the courts of </span
                        ><span data-contrast="none">Germany</span
                        ><span data-contrast="none">.</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none">CONTACT US</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <p>
                        <span data-contrast="none"
                        >If you have any questions or concerns about
                          these Terms of Use or its implementation, you
                          may contact us by</span
                        ><span data-ccp-props="{}">&nbsp;</span>
                      </p>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="7"
                          aria-setsize="-1"
                          data-aria-posinset="1"
                          data-aria-level="1"
                        >
                          <span data-contrast="none">telephone: +</span
                          ><span data-contrast="none"
                          >49 176 30 188540</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="7"
                          aria-setsize="-1"
                          data-aria-posinset="2"
                          data-aria-level="1"
                        >
                          <span data-contrast="none">email: </span
                          ><span data-contrast="none">info</span
                          ><span data-contrast="none">@</span
                          ><span data-contrast="none">uni-app.com</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                      <ul>
                        <li
                          data-leveltext=""
                          data-font="Symbol"
                          data-listid="7"
                          aria-setsize="-1"
                          data-aria-posinset="1"
                          data-aria-level="1"
                        >
                          <span data-contrast="none"
                          >written correspondence: </span
                          ><span data-contrast="none"
                          >Neom GmbH, Neue Mainzer Str, 46-50, 60311
                            Frankfurt am Main, GardenTower</span
                          ><span
                            data-ccp-props='{"134233118":true,"335559738":150}'
                          >&nbsp;</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Container>
    </div>
  );
}
export default Terms;