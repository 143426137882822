import React from 'react';
import './index.css';
import { Container } from '@mui/material';
import Image1 from '../../../src/until/Image/PNG/Secrets1.jpg'

import Image2 from '../../../src/until/Image/PNG/Secrets2.jpg'

import Image3 from '../../../src/until/Image/PNG/Secrets3.jpg'

import Image4 from '../../../src/until/Image/PNG/Secrets4.jpg'

import Image5 from '../../../src/until/Image/PNG/Secrets5.jpg'

import { useNavigate } from 'react-router';


const Secrets = () => {
  const navigate = useNavigate();

  return (
    <div className='Secrets-of-Studying'>
      <Container maxWidth="xl">
        <h1 className='headerName'>Secrets of <span className='title'> Studying Abroad </span>  Revealed <br /> in Articles</h1>

        <div className='articlesSectionCard'>

          <div className='ContainerCard' onClick={() => {
            navigate('/study-medicine-in-germany-in-english/')
          }}>
            <img src={Image1} alt='image not found' />
            <div className='card-text'>
              <p>MEDICINE</p>
              <p>How to Study Medicine in Germany in English? Breaking the Language Barrier</p>
            </div>
          </div>

          <div className='ContainerCard' onClick={() => {
            navigate('/the-essential-steps-to-study-medicine-in-germany/')
          }}>
            <img src={Image3} alt='image not found' />
            <div className='card-text'>
              <p>MEDICINE</p>
              <p>The Essential Steps to Study Medicine in Germany: The Path to Medical Excellence</p>
            </div>
          </div>

          <div className='ContainerCard' onClick={() => {
            navigate('/online-masters-degree-reasons-why/');
          }}>
            <div>
              <img src={Image2} alt='the image not found' className='middleImage' />
              <div className='textBottom'>
                <p>MASTERS DEGREE</p>
                <h1>
                  Why should I study an Online master’s degree?</h1>
              </div>

            </div>
          </div>

          <div className='ContainerCard' onClick={() => {
            navigate('/your-masters-degree-useful-tips/')
          }}>
            <img src={Image4} alt='image not found' />
            <div className='card-text'>
              <p>MASTERS DEGREE</p>
              <p>Your Master’s degree: Useful tips for choosing a program</p>
            </div>
          </div>

          <div className='ContainerCard' onClick={() => {
            navigate('/interesting-facts-about-online-masters-degrees/')
          }}>
            <img src={Image5} alt='image not found' />
            <div className='card-text'>
              <p>MASTERS DEGREE</p>
              <p>Interesting Facts about Online Master’s degrees</p>
            </div>
          </div>
          
          
        

        </div>

        {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className='ContainerCard' onClick={() => {
            navigate('/online-masters-degree-reasons-why/');
          }}>
            <div>
              <img src={Image2} alt='the image not found' className='middleImage' />
              <div className='textBottom'>
                <p>MASTERS DEGREE</p>
                <h1>
                  Why should I study an Online master’s degree?</h1>
              </div>

            </div>
          </div>
        </div> */}


        <div className='more-article' onClick={() => {
          navigate('/articles/')
        }} >
          <span>
            <a> More Article</a>
          </span>

        </div>

      </Container>
    </div>
  );
}
export default Secrets;


{/* <div className='flex-Card'>
          <div className='ContainerCard' onClick={() => {
            navigate('/study-medicine-in-germany-in-english/')
          }}>
            <img src={Image1} alt='image not found' />
            <div className='card-text'>
              <p>MEDICINE</p>
              <p>How to Study Medicine in Germany in English? Breaking the Language Barrier</p>
            </div>
          </div>
          <div className='ContainerCard' onClick={() => {
            navigate('/online-masters-degree-reasons-why/');
          }}>
            <img src={Image2} alt='the image not found' className='middleImage' />
            <div className='textBottom'>
              <p>MASTERS DEGREE</p>
              <h1>
                Why should I study an Online master’s degree?</h1>
            </div>
          </div>

          <div className='ContainerCard' onClick={() => {
            navigate('/the-essential-steps-to-study-medicine-in-germany/')
          }}>
            <img src={Image3} alt='image not found' />
            <div className='card-text'>
              <p>MEDICINE</p>
              <p>The Essential Steps to Study Medicine in Germany: The Path to Medical Excellence</p>
            </div>
          </div>
        </div>

        <div className='flex-Card'>
          <div className='ContainerCard' onClick={() => {
            navigate('/your-masters-degree-useful-tips/')
          }}>
            <img src={Image4} alt='image not found' />
            <div className='card-text'>
              <p>MASTERS DEGREE</p>
              <p>Your Master’s degree: Useful tips for choosing a program</p>
            </div>
          </div>
          <div className='ContainerCard'>
          </div>

          <div className='ContainerCard' onClick={() => {
            navigate('/interesting-facts-about-online-masters-degrees/')
          }}>
            <img src={Image5} alt='image not found' />
            <div className='card-text'>
              <p>MASTERS DEGREE</p>
              <p>Interesting Facts about Online Master’s degrees</p>
            </div>
          </div>
        </div>
        <div className='more-article' onClick={() => {
          navigate('/articles/')
        }} >
          <span>
            <a> More Article</a>
          </span>

        </div> */}