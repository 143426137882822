import React from 'react';
import './index.css'

const Scholarship = ({ data }) => {
  return (
    <body>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </body>
  );
}
export default Scholarship;