import React, { useEffect } from 'react';
import Container from "@mui/material/Container";
import './index.css'
import Header from './Header';
import Services from './Services';
import WOW from 'wowjs';
import LicensedDoctor from './LicensedDoctor';
import Country from './Country';
import SecondSection from './SecondSection';
import Expect from './Expect';
const StudyMedicine = () => {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className='wow fadeInDown' data-wow-duration='1s'>
            <Header />
            <Services />
            <LicensedDoctor />
            <Country />
            <SecondSection />
            <Expect />
            <div style={{ backgroundColor: '#0077A7ad', padding: '75px 0px' }}>
                <Container maxWidth="lg">

                    <div className='sideMain'>

                        <h2 style={{ color: '#fff', margin: '0' }}> Study Medicine Not Sure?</h2>

                        <h1 style={{ textAlign: 'center', color: "#fff", fontSize: "37px", margin: '0' }}>

                            Our Study Advisors Are There To Answer All Sorts Of Questions

                        </h1>

                        <div className='UniversityApplicationBtn' onClick={() => { window.location.href = 'https://my.uni-app.com/sign-in?redirectURL=%2Fhome' }}>
                            <span className='link'>
                                Ask Your Question
                            </span>
                        </div>
                    </div>

                </Container>
            </div>

        </div>

    );
};

export default StudyMedicine;
