import React, { useState, useEffect } from 'react';
import './Search.css';
import { Container } from '@mui/material';
import cover from '../../until/Image/PNG/program-banner-overlay (1).png'
import Gallery from './Gallery';
import { useParams } from 'react-router-dom';
import WOW from 'wowjs';
import axios from 'axios';
// import RelatedPrograms from './RelatedPrograms';
import General from './General';
import { useNavigate } from 'react-router';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AdmissionRequirements from './AdmissionRequirements';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';


const Program = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(null)
    const [relatedData, setRelatedData] = useState([]);
    const tabs = ['General', 'Overview', 'Admission Requirements', 'Tuition Fees', 'Gallery']
    const [activeTab, setActiveTab] = useState('General');
    const { slug } = useParams();


    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


    const fetchData = async (slug) => {
        try {
            const slugResponse = await axios.get(`https://api.uni-app.com/courses/by-slug/${slug}`);
            const data = slugResponse.data;
            setData(data);

            if (data) {
                const DTO = {
                    title: data?.title,
                    instituteName: data?.instituteName,
                    degree: data?.degree,
                    id: data?.id
                };

                const relatedResponse = await axios.post('https://api.uni-app.com/courses/similar?size=4', DTO);
                setRelatedData(relatedResponse.data.content);
            } else {
                console.log('ERROR: Unable to fetch related data');
            }
        } catch (err) {
            console.error('Error fetching data:', err.message);
        }
    };

    const slugDetails = (data) => {
        navigate(`/Program/${data}`);
        fetchData(data);
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchData(slug);
    }, [slug]);



    return (
        <div className='ProgramContainer wow fadeInDown' data-wow-duration='1s'>
            <Container maxWidth='lg'>
                <img src={cover} alt="cover not found" />
                <div className='CenterContainer'>
                    <div className='ProgramContainerBox'>
                        <div class="proram-title-degree-institute">
                            <h1>{data?.title}</h1>
                            <div>
                                <h5>{data?.degree}</h5>
                                <h5>{data?.instituteName}</h5>
                            </div>
                            <div className='dataOption'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CalendarMonthOutlinedIcon style={{color:'#696969'}}/>
                                    <p>Apply Anytime</p>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <WatchLaterOutlinedIcon style={{color:'#696969'}} />
                                    </div>
                                    <p>
                                        {data?.durationInSemester} Semesters
                                    </p>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <div>
                                      <LocationOnOutlinedIcon style={{color:'#696969'}}/>
                                    </div>

                                    {data && data.locations && data.locations.map((ele, index) => {
                                        const isLastElement = index === data.locations.length - 1;
                                        const comma = (!isLastElement && data.locations.length > 1) ? ',' : ''
                                        const marginStyle = (data.locations.length > 1) ? { margin: '0' } : {};

                                        return (
                                            <p key={index} style={marginStyle}>
                                                {ele.city}{comma}
                                            </p>
                                        );
                                    })}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                    <LanguageOutlinedIcon style={{color:'#696969'}} />
                                    </div>
                                    <p>{data?.languages}</p>


                                </div>
                                <div className='lastICON' style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                      <PaymentsOutlinedIcon  style={{color:'#696969'}}/>
                                    </div>
                                    <p>
                                        {data?.tuitionFee} - 
                                        {data?.tuitionFeeCurrency}

                                    </p>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <WorkspacePremiumOutlinedIcon style={{color:'#696969'}}/>
                                    </div>
                                    <p>{data?.level}</p>
                                </div>

                            </div>
                        </div>

                        <div className='btnWithImage'>
                            <img

                                src={data?.logo}
                                alt=""
                            />

                            <div className='applyNow'>
                                <p onClick={() => {
                                    window.location.href = `https://my.uni-app.com/sign-in?redirectURL=%2Fhome`;
                                }}>Apply Now</p>
                            </div>
                        </div>
                    </div>


                </div>
                <div className='overViewContainer'>
                    <div className='overViewContainerContact'>
                        <div className='option-tabs'>

                            <div className='main-tabs'>
                                {tabs.map((tab) => (
                                    <span
                                        key={tab}
                                        className={`tabs-name ${activeTab === tab ? 'active-tab' : ''}`}
                                        onClick={() => handleTabClick(tab)}
                                    >
                                        {tab}
                                    </span>
                                ))}
                            </div>
                            {activeTab === 'General' && <General data={data} />}

                            {activeTab === 'Gallery' && <Gallery data={data} />}

                            {activeTab === 'Admission Requirements' && <AdmissionRequirements data={data} />}

                            {activeTab === 'Tuition Fees' && <>
                                <div className='Tuition'>
                                    <p>Tuition Fees:</p>

                                    <p style={{ backgroundColor: '#f2f2f2', padding: '10px' }}>    {data?.tuitionFee}
                                        {data?.tuitionFeeCurrency} per semester</p>
                                </div>
                            </>}

                            {activeTab === 'Overview' && <>
                                <div className='Overview'>
                                    <h2 className='headerOverView'>Featured Outline</h2>

                                    <h4 className='subHeaderOverView'>Program Details</h4>

                                    <div className='scrollView'>
                                        <div dangerouslySetInnerHTML={{ __html: data?.overview }} />
                                    </div>
                                </div>

                            </>}

                        </div>
                        <div className='option-data'>
                            <div className='details-box'>
                                <div>

                                    <h4>Application Deadline</h4>
                                    <p>Winter semester starts in September</p>
                                    <p>Summer semester starts in January</p>
                                    <p className='last'>Application Open All Year</p>
                                    <span onClick={() => {
                                        window.location.href = `https://my.uni-app.com/sign-in?redirectURL=%2Fhome`;
                                    }}>Apply Now</span>
                                </div>
                            </div>
                            <div className='overViewImage'>
                                <img src='https://static.udrus.com/assets/image/talk-to-expert.webp' alt='' className='overViewImg' />
                                <div className='details'>
                                    <h4>Need to talk to an expert ?</h4>
                                    <p>Are you still confused about to start your journey ? Don’t hesitate to book a meeting with an expert
                                        . Our dedicated team is ready to help you.</p>
                                    <span onClick={() => {
                                        navigate(`/contact-us/`)
                                    }}>Contact us</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {<div className="Related-Programs">
                    <h2>Related Programs</h2>
                    <div className="Related-Programs-box">
                        {relatedData.map((program, index) => (
                            <div className="degree-box" key={index}>
                                <div>
                                    <img src={program?.logo} style={{ width: '100%', maxHeight: '120px' , objectFit:'fill' }} alt="" />
                                    <h1>{program?.title}</h1>
                                    <h5>{program?.degree}</h5>
                                    <div style={{ display: 'flex' }}>
                                         <CalendarMonthOutlinedIcon style={{color:'#696969' ,fontSize:'20px'}}/>
                                        <div>
                                            <p>Apply Anytime</p>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', }}>
                                        <div>
                                        <WatchLaterOutlinedIcon style={{color:'#696969' ,fontSize:'20px'}} />
                                        </div>
                                        <p>
                                            {data?.durationInSemester} Semesters
                                        </p>
                                    </div>

                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div>
                                        <LocationOnOutlinedIcon style={{color:'#696969' , fontSize:'20px'}}/>
                                        </div>
                                        {data && data.locations && data.locations.map((ele, index) => {
                                            const isLastElement = index === data.locations.length - 1;
                                            const comma = (!isLastElement && data.locations.length > 1) ? ',' : ''
                                            return (

                                                <div key={index} style={{ backgroundColor: '' }}>
                                                    <p>{ele.city}{comma}{}</p>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div style={{ display: 'flex' }}>
                                        <div>
                                        <LanguageOutlinedIcon style={{color:'#696969' , fontSize:'20px'}} />
                                        </div>
                                        <p>{data?.languages}</p>
                                    </div>

                                    <div className='lastICON' style={{ display: 'flex' }}>
                                        <div>
                                        <PaymentsOutlinedIcon  style={{color:'#696969' , fontSize:'20px'}}/>
                                        </div>
                                        <p>
                                            {data?.tuitionFee}
                                            {data?.tuitionFeeCurrency}

                                        </p>
                                    </div>

                                    <div style={{ display: 'flex', }}>
                                        <div>
                                        <WorkspacePremiumOutlinedIcon style={{color:'#696969' , fontSize:'20px'}}/>
                                        </div>
                                        <p>{data?.level}</p>
                                    </div>
                                </div>
                                <div className="readMore">
                                    <p onClick={() => { slugDetails(program.slug) }}>Read More</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                }
            </Container>
        </div>
    );
};

export default Program;
