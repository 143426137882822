import React, { useState, useEffect } from 'react';
import { Container, TextField } from '@mui/material';
import './index.css';
import DegreesFilter from '../BachelorsDegrees/DegreesFilter';
import OnlineContent from './OnlineContent';
import WOW from 'wowjs';
import FilterOnline from './FilterOnline';
import { useNavigate } from 'react-router';
const OnlineDegrees = () => {
    const navigate = useNavigate();

    const inputStyle = {
        backgroundColor: '#fff',
        borderRadius: '18px',

    };
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, [])

    return (
        <body className='degreeSection wow fadeInDown' data-wow-duration='1s'>
            <div className="parentTheCountry">
                <div className="backgroundImageMainCountry"></div>
                <div className="CountryOverlay"></div>
                <Container maxWidth="lg">
                    <div className="BachelorsDegreesText">
                        <h1>Discover Best Online Degrees Worldwide!</h1>
                        <div className='sssss'>
                            <TextField
                                fullWidth
                                // value={studyTopic}
                                // onChange={(e) => setStudyTopic(e.target.value)}
                                size='small'
                                className='input1'
                                placeholder='What do you want to study?'
                                InputProps={{ style: { ...inputStyle } }}
                            />
                        </div>
                            <button className='button' onClick={() => { navigate('/Search') }}>Search</button>
                    </div>
                </Container>
            </div>


            <FilterOnline />
            {/* <DegreesFilter /> */}
            <OnlineContent />

        </body>
    );
}

export default OnlineDegrees;
