import React, { useEffect } from 'react';
import './index.css';
import { Container } from '@mui/material';
import ContactUs from './component/ContactUs'
import Map from './component/Map'
import WOW from 'wowjs';


const ContactUsScreen = () => {
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='wow fadeInDown' data-wow-duration='1.5s' style={{ padding: '50px 0px' }}>
            <Container maxWidth="lg">
                <ContactUs />
                {/* <Map/> */}
            </Container>

        </div>
    );
}

export default ContactUsScreen;