import React, {useEffect } from 'react';
import { Container, TextField } from '@mui/material';
import './index.css';
import DegreesContent from './DegreesContent';
import { useNavigate } from 'react-router';

import DegreesFilter from './DegreesFilter';
import WOW from 'wowjs';

const BachelorsDegrees = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, [])
    const inputStyle = {
        backgroundColor: '#fff',
        borderRadius: '18px',
    };

    return (
        <body className='degreeSection wow fadeInDown' data-wow-duration='1s'>
            <div className="parentTheCountry">
                <div className="backgroundImageMainCountry"></div>
                <div className="CountryOverlay"></div>
                <Container maxWidth="lg">
                    <div className="BachelorsDegreesText">
                        <h1>Discover Best Bachelor's Degrees Worldwide!</h1>
                        <div className='sssss'>
                            <TextField
                                fullWidth
                                // value={studyTopic}
                                // onChange={(e) => setStudyTopic(e.target.value)}
                                size='small'
                                className='input1'
                                placeholder='What do you want to study?'
                                InputProps={{ style: { ...inputStyle } }}
                            />
                        </div>
                            <button className='button' onClick={() => { navigate('/Search') }}>Search</button>
                       
                    </div>
                </Container>
            </div>

            <DegreesFilter />
            <DegreesContent />

        </body>
    );
}

export default BachelorsDegrees;
