import React from 'react';
import './index.css'

const HowToApply = ({data}) => {
    return (
        <body>
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </body>
    );
}


export default HowToApply;