import React from 'react';
import './index.css'

const Study = () => {


    return (
  
      <>
      <div content-tab="study">
                <h2 >
                  Study Medicine in Latvia
                </h2>
                <h3>Reasons to Study Medicine in Latvia</h3>
                <p>&nbsp;</p>
                <p>
                  Promoting the Medical field in the country, the Republic of
                  Latvia has a high-end living and educational standards, for
                  being a stable country where your academic years will be
                  unforgettable. Here are the main reasons to study medicine in
                  Latvia:
                </p>
                <p>&nbsp;</p>
                <ol>
                  <li>
                    Latvia is a fast-growing, state-of-the-art country, and a
                    promising member of the European Union.
                  </li>
                  <li>
                    The Baltic country is ranked 39th in the world’s most
                    growing economies.
                  </li>
                  <li>
                    &nbsp;Education is not lectures and classes only, as years
                    of experience from the Latvian universities have developed a
                    high-quality educational system.
                  </li>
                  <li>The received medical diploma is recognized worldwide.</li>
                  <li>
                    The followed credit system of ECTS in Latvia makes
                    international credit transfer easier.
                  </li>
                  <li>
                    You will have access to clinics, hospitals, laboratories,
                    and libraries to guarantee the best chances to learn.
                  </li>
                  <li>
                    You are allowed to apply for residency and postgraduate
                    training programs at university teaching hospitals worldwide
                    after graduation.
                  </li>
                </ol>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  If you are still not sure whether Latvia is a good destination
                  to study medicine and get a life experience,<strong>
                    there are additional entertaining and lifestyle merits to
                    think about:</strong
                  >
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ol>
                  <li>
                    Cultural Diversity of Christian Latvians who still keep some
                    of their old pagan customs and practices in folk songs,
                    legends, and festivals.
                  </li>
                  <li>
                    Latvia is a nation of singers, as most Latvians were members
                    of the singing choir at some point in their life.
                  </li>
                  <li>
                    You will enjoy the hybrid heritage of the buildings while
                    keeping the Latvian identity; this appears in the log houses
                    characteristic of Russia just a few miles away from a Gothic
                    castle left from Medieval times.
                  </li>
                  <li>
                    More than 50% of Latvia is thick forests, and over 12,500
                    rivers are carving the countryside until reaching the Baltic
                    Sea, making it an amazing hotspot for avid nature lovers.
                    And naturally, a great number of parks and reserves are
                    located throughout the country.
                  </li>
                  <li>
                    &nbsp;With these thousands of rivers and the Baltic shore,
                    there is a lot of fishing to do there. You can also enjoy
                    float angling, spin-fishing, fly-fishing, ice fishing, boat
                    fishing, and shore fishing. And of course, you will enjoy
                    eating plenty of caught fish kinds like salmon, sea trout,
                    pike, and vimba.
                  </li>
                  <li>
                    &nbsp;As geography translates to forest hikes, river
                    kayaking, and beach walks, adventurous international
                    students can take part in husky dog sledding in the Winter.
                    They are making Latvia a place of endless recreational
                    activities.
                  </li>
                </ol>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <h3>
                  <strong
                    >Tuition Fees and Living Costs to Study Medicine in Latvia
                  </strong>
                </h3>
                <p>&nbsp;</p>
                <p>
                  Although the Baltic country is a member of the EU and the
                  Schengen area, it is placed in the middle regarding living
                  costs and tuition fees and has significantly lower living
                  costs than western European countries. On the contrary, Latvia
                  is signifying a higher living standard.
                </p>
                <p>&nbsp;</p>
                <p>
                  The tuition fees to study medicine in Latvia vary between
                  €9,000 and €12,000, depending on the university. Dentistry
                  courses are €14,000 and pharmacy courses are only offered at
                  RSU at the price of €8,000 for the first two years and €9,000
                  for the last three years.
                </p>
                <p>&nbsp;</p>
                <p>
                  The average living expenses in the capital are estimated at
                  €630 per month without rent. Depending on the location. The
                  single-bedroom apartment rental is between €300 to €450. RSU
                  rooms (university housing) cost between €80 to €400, depending
                  on the number of shared rooms in the same house. Note to
                  mention that hostels are great options for students who want
                  to save money. Finally, a meal at an inexpensive restaurant
                  costs around €9, and a monthly pass for public transportation
                  is €50.
                </p>
              </div>
      </>



    );
}
export default Study;