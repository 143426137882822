import React from 'react';
import './index.css'

const About = () => {


  return (
    <>
      <div
        content-tab="university">
        <h2>Universities in Latvia</h2>
        <p>
          On Considering medicine as a university major in Latvia, you
          will be exposed to a various range of subjects required to
          qualify them for the profession;
        </p>
        <p>&nbsp;</p>
        <ol>
          <li>
            The science and methods of medicine are based on human
            morphology (Structure).
          </li>
          <li>
            &nbsp;Functions of the body parts and organs (Physiology),
          </li>
          <li>&nbsp;Psychology</li>
          <li>
            Basic clinical practice (Anamnesis, Overall Objective
            Examination, and Interpretation of Results, Manipulation
            competence).
          </li>
          <li>
            &nbsp;Health Promotion and Disease Prevention in the context
            of family and society.
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          After gaining the needed knowledge for successful completion
          of the program, you may start your residency and Ph.D. studies
          as a graduate. The medical program in Latvia corresponds to EU
          standards and professional requirements set for MDs.
        </p>
        <p>&nbsp;</p>
        <p>
          This obtained degree aligns with European labor market
          requirements and is part of the European comparative degree
          system that allows free movement within Europe. It is also on
          a full-time basis and covers 6 academic years.
        </p>
        <p>&nbsp;</p>
        <p>
          You as a graduate with a medical degree may work in the field
          of health care, medical firms, and governmental institutions,
          like hospitals or health centers under the supervision of
          certified physicians.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h3>
          Here are 3 main medical schools at the following Latvian
          Universities:
        </h3>
        <p>&nbsp;</p>
        <ol>
          <li>University of Latvia</li>
          <li>Riga Stradins University</li>
          <li>Latvia University of Life Sciences and Technologies</li>
        </ol>
        <p>&nbsp;</p>
        <h3>University of Latvia</h3>
        <p>&nbsp;</p>
        <p>
          University of Latvia is the most popular educational
          institution for international students in Riga. It was founded
          in 1862 and has become one of the best and most popular
          universities in Europe. There are 13 faculties of which there
          are medicine and dentistry. Other majors are natural sciences,
          humanities, law, business sciences, and art. This variety of
          disciplines makes the university environment exciting and
          rich.
        </p>
        <p>
          <strong
          >Medical degrees offered by University of Latvia:</strong
          >
        </p>
        <ul>
          <li>Medicine: in English</li>
          <li>Dentistry: in English</li>
        </ul>
        <p>&nbsp;</p>
        <h3>Riga Stradins University</h3>
        <p>&nbsp;</p>
        <p>
          Riga Stradins University Medical School was established in
          1950. It is considered one of the oldest and most respected
          faculties in the country that offer programs in Medicine
          Dentistry and Pharmacy. It also ranks among the top 500
          European universities and top 1000 universities in the world.
          Due to the excellent reputation and different course
          offerings, Riga Stradins University attracts many prospective
          students from abroad to study and specialize in the 5 medical
          sciences offered by this institution in Riga.
        </p>
        <p>
          <strong
          >Medical degrees offered by Riga Stradins
            University:</strong
          >
        </p>
        <ul>
          <li>Medicine: in English</li>
          <li>Dentistry: in English</li>
          <li>Pharmacy: in English</li>
        </ul>
        <p>&nbsp;</p>
        <h2>Latvia University of Life Sciences and Technologies</h2>
        <p>&nbsp;</p>
        <p>
          Latvia University of Life Sciences and Technologies was
          originally the Agricultural Department at the Riga
          Polytechnical Institute in 1863 in Jelgava, Latvia. In 1919,
          it became the Faculty of Agriculture at the University of
          Latvia. After that, It changed into an independent institution
          in 1939 when it was established as the Academy of Agriculture
          in the Jelgava Palace. Then it was renamed the Latvia
          University of Agriculture in 1990. Finally, it is currently
          known as Latvia University of Life Sciences and Technologies
          since 2018. The university specializes in agricultural
          science, forestry, food technology, and related areas.
        </p>
        <p>&nbsp;</p>
        <p>
          As a student of veterinary medicine at Latvia University of
          Life Sciences and Technologies, you will be prepared for your
          future career as a veterinarian: you will learn the structure
          and function of healthy and diseased animal bodies, different
          species for appropriate husbandry, know about the
          reproduction, the hygiene and feeding, the behavior and
          protection of animals and course the causes, diagnosis, and
          impacts of diseases.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong
          >Medical degrees offered by Latvia University of Life
            Sciences and Technologies:</strong
          >
        </p>
        <p>Veterinary Medicine: in English</p>
      </div>
    </>



  );
}
export default About;