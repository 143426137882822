import React, { useState } from 'react';
import './index.css';
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Container } from '@mui/material';

const FaqItem = ({ item, isOpen, handleToggle }) => (
    <div className={`collapse-box ${isOpen ? 'active' : ''}`} key={item.id}>
        <div className='IconButton' onClick={() => handleToggle(item.id)}>
            <h3>{item.title}</h3>
            {isOpen ? <RemoveIcon className='iconStyle' /> : <AddIcon className='iconStyle' />}
        </div>
        <Collapse in={isOpen} className='Collapse'>
            {item.point && item.point.length > 0 ? (
                <ul>
                    {item.point.map((ele, index) => (
                        <li key={index}>{`${ele}`}</li>
                    ))}
                </ul>
            ) : (
                <p>{item.description}</p>
            )}
        </Collapse>
    </div>
);

const FaqComponent = () => {
    const [openState, setOpenState] = useState(null);
    const [otherOne, setOtherOne] = useState(null);

    const handleToggle = (index) => {
        const isOpen = openState === index;
        setOpenState(isOpen ? null : index);
    };

    const handleToggleOtherOne = (itemId) => {
        setOtherOne((prevOtherOne) => (prevOtherOne === itemId ? null : itemId));
    };

    const renderFaqItems = (items, isOpenFn, toggleFn) => (
        <div className='ContainerCollapseOne'>
            {items.map((item) => (
                <FaqItem key={item.id} item={item} isOpen={isOpenFn(item.id)} handleToggle={toggleFn} />
            ))}
        </div>
    );

    const content = [
        {
            id: 0,
            title: 'How to create uniapp online account?',
            point: [
                'Click on the create account button',
                'Choose your sign-up method, you can sign-up with your email, Facebook or Gmail account',
                'Complete your registration information',
                'Once you’re logged in, complete your Uni-App profile to use all UniApp functions',
            ],
            description: ''
        },
        {
            id: 1,
            title: 'How do I create my application?',
            point: [
                `After you are logged into your account click on ( My UniApp )`,
                `Complete all the steps`,
                `You’re all set, and can use this app to apply for any study program on uniapp`,
            ],
            description: ``
        },
        {
            id: 2,
            title: 'Is uniapp free of charge?',
            point: [
            ],
            description: 'Yes, UniApp is free of charge'
        },
        {
            id: 3,
            title: 'For what do I need uniapp?',
            point: [
            ],
            description: `You need uniapp to apply for studying at your desired school or preferred study program, uniapp is one application that can be used to apply to multiple schools and programs`
        }
        ,
        {
            id: 4,
            title: 'Is uniapp legal?',
            point: [
            ],
            description: 'Uniapp is a German legal entity, owned by NEOM GmbH, uniapp is an official partner of many universities, based on these partnerships the programs are brought to students using uniapp'
        },
        {
            id: 5,
            title: `Is uniapp recognized?`,
            point: [
            ],
            description: `Yes, uniapp is recognized by all the universities listed here`
        },
        {
            id: 6,
            title: 'Does uniapp has an App?',
            point: [],
            description: 'Yes, starting from June 1st 2022 you can download the app via the apple store and google android play'
        },
        {
            id: 7,
            title: 'what is uniapp?',
            point: [
            ],
            description: `The UniApp Application is a single online application used by hundreds of colleges and universities. Instead of filling out the same information, again and again, students can now use uniapp to apply for multiple programs and different universities

        `
        },
        {
            id: 8,
            title: 'What is the processing time for applications on UniApp?',
            point: [],
            description: 'Depending on the number of applications received at the time of submission, the normal time for submitting a complete application on UniApp ranges from 1 to 3 days.            '
        },
        {
            id: 9,
            title: 'What happens after I have submitted my documents?',
            point: [
            ],
            description: `Accordion Content• Your uni-application will be reviewed by the Admission Team in accordance with the school’s standards. If anything more is required, they will tell you through notifications if your use the mobile app or in the message section of your application. All updates will be sent via email to you as well            `
        },
        {
            id: 10,
            title: 'How do I check the tuition fees for my preferred program?',
            point: [
            ],
            description: 'Accordion Content• Your uni-application will be reviewed by the Admission Team in accordance with the school’s standards. If anything more is required, they will tell you through notifications if your use the mobile app or in the message section of your application. All updates will be sent via email to you as well            '
        },
        {
            id: 11,
            title: 'What are the average tuition fees and cost of living?',
            point: [
            ],
            description: `You can find out more about each destination country, including the average tuition fees and cost of living, by visiting our Study Destination Guides`
        },
        {
            id: 12,
            title: 'When do I have to pay the tuition fee?',
            point: [],
            description: 'These dates will be stated on your Letter Of Acceptance, however, each school will have a different deadline. We recommend that you make the payment as soon as possible after you receive an offer, to secure your seat in the chosen study program            '
        },
        {
            id: 13,
            title: 'Do I have to pay the tuition fee in order to obtain an acceptance letter?',
            point: [
            ],
            description: `The majority of universites will first issue a Conditional Letter of Acceptance. This implies you’ve been admitted to the institution as long as you meet certain requirements. Providing official transcripts or supplementary proof is sometimes required, but most of the time the prerequisite is to submit a tuition deposit to the institution. This is generally not the complete sum of tuition, but rather a deposit to reserve your seat. Following receipt of the deposit (and satisfaction of any other criteria), you will be issued a Final Letter of Acceptance, which you may use to apply for your visa

        `
        },
    ]
    const contentRightSide = [
        {
            id: 14,
            title: 'How can I access first year students guide?',
            point: [],
            description: 'Visit uniapps first-year students guide page'
        },
        {
            id: 15,
            title: 'How can I access application transfer  students guide?',
            point: [],
            description: 'Visit uniapps transfer students guide page'
        },
        {
            id: 16,
            title: 'How can I contact uniapp?',
            point: [],
            description: 'Visit uniapps contact us page'
        },
        {
            id: 17,
            title: 'What is an acceptance or admission letter?',
            point: [],
            description: 'A Letter of Admission (LOA), Letter of Acceptance, or Confirmation of Acceptance for Studies (CAS) is a document issued by the university to which you applied. It confirms that you have got a place to study in your desired country. This letter can be used to apply for your study permit in the target country          '
        },
        {
            id: 18,
            title: 'How long does it take to receive an acceptance letter?',
            point: [],
            description: 'A Letter of Acceptance (LOA) might take up to 4 weeks to be issued by an institution . This timetable might vary substantially based on the school, season, and location of choice. UniApp’s staff works closely with our partner institutions to ensure that you receive a decision as soon as possible'
        },
        {
            id: 19,
            title: 'How do I get an offer letter through UniApp?',
            point: [],
            description: 'UniApp is working with hundreds of institutions to provide you with a streamlined application process. You should use UniApp platform to upload all of your application materials and apply to the programs of your choosing. We will process these documents to ensure that you have the best application possible before sending them to the institution. We handle the coordination and follow-ups, and you will be informed once a decision is reached'
        },
        {
            id: 20,
            title: 'Can UniApp help me study abroad?',
            point: [],
            description: `Yes! UniApp is an official partner of top institutions and colleges, also with a large number of high schools`
        },
        {
            id: 21,
            title: 'Can I get admission without an english proficiency test?',
            point: [],
            description: `Yes, you can get admitted without taking an English proficiency exam. Before beginning your academic program, you can participate in route programs designed to improve your comprehension of the English language`
        },
        {
            id: 22,
            title: 'How can I check my eligibility for a program?',
            point: [],
            description: `To determine your eligibility, you must first create an account. Then, complete ( My UniApp ) based on your uniapp, and the requirements of the desired study program the system determines whether you’re eligible or not`
        },
        {
            id: 23,
            title: 'How can I request information about a program?',
            point: [
                'Please log in into your uniapp account',
                'Choose the desired program',
                'Click on Read More',
            ],
        },
        {
            id: 24,
            title: 'How do I find out more about my preferred school or program?',
            point: [
            ],
            description: 'To find out more about your preferred school or program, visit UniApp and use the filter to search for a school or program. Click on Read more! to be directed to a page with more information about your preferred study program            '
        },
        {
            id: 25,
            title: 'What documents do I need to apply to a Program?',
            point: [
            ],
            description: 'If you complete your UniApp profile 100% you will be able to apply to all programs, in case there is any special requirements for your desired program you will receive a message            '
        },
        {
            id: 26,
            title: ' How many applications can I submit with UniApp?',
            point: [
            ],
            description: 'You can have up to 6 active applications on our applications dashboard'
        },
        {
            id: 27,
            title: 'Can UniApp help me with the visa process?',
            point: [
            ],
            description: 'Yes, we do offer visa services, please contact us to assign an agent for your queries'
        },
        {
            id: 28,
            title: 'What should I know about the visa process?',
            point: [
            ],
            description: 'For more information on each destination country’s visa requirements check out uniapp’s Study Destination Guide'
        },
    ]


    return (
        <div style={{ padding: '100px 0px' }}>
            <Container maxWidth='lg'>
                <div>
                    <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: 300, letterSpacing: '3px' }}>
                        FAQS AND ALL YOU NEED TO KNOW ABOUT STUDYING ABROAD
                    </p>
                    <h1 style={{ textAlign: 'center', color: '#333', fontSize: '36px' }}>Applying Through UniApp</h1>
                </div>
                <div className='ContainerCollapse'>
                    {renderFaqItems(content, (id) => openState === id, handleToggle)}
                    {renderFaqItems(contentRightSide, (id) => otherOne === id, handleToggleOtherOne)}
                </div>
            </Container>
        </div>
    );
};

export default FaqComponent;
