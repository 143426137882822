import React from 'react';
import './index.css'

const Study = () => {


  return (
    <>
      <div content-tab="study">
        <h2>Study Medicine in Lithuania</h2>
        <h3><strong>Why should you study in Lithuania?</strong></h3>
        <p>There are many a reason why one would choose to study medicine in Lituania. First off is the quality of the programs-again, do not be fooled by the lack of coverage as Lithuanian medicine schools are top-of-the-line institutions in Europe. On top of that, there is also the cultural aspect of it-whether you are an expat looking to study in an exotic location or a Slavic/Baltic student who doesn’t want to stray too far from home, Lithuania has got you covered. Finally, Lithuania offers quality education in a variety of medical fields such as veterinary medicine, nursing, physiotherapy, pharmacy, dentistry, medical specialization and so on.</p>
        <h3><strong>How much does it cost?</strong></h3>
        <p>The cost will depend on the program you’re planning on enlisting. Generally speaking, it goes like this:</p>
        <ul>
          <li>6-year medicine program: 8,300 to 8,500 EURO</li>
          <li>5-year odontology (medicine) program: 9,600 to 12,900 EURO</li>
          <li>5-year veterinary medicine program: 6,000 EURO</li>
          <li>4-year nursing program: 2,100 to 5,100 EURO</li>
          <li>3-year orthopedic technology program: 1,706 EURO</li>
          <li>3-year ontological care program: 3,000 EURO</li>
        </ul>
        <h3><strong>Cost of living</strong></h3>
        <p>A single person is expected to pay 627$ (594 EURO) on the essentials such as food and certain bills, excluding rent. Even then, rent is relatively inexpensive with the price tag ranging from 200 to 400 EURO for a typical studio flat. Living in Lithuania in general is pretty cheap, with cost of living being roughly 36.43% lower than the United States and rent being 66.28% lower than the United States.</p>
        <h4><strong>Accommodation</strong></h4>
        <p>As stated before, rent is relatively cheap in Lithuania. Studio flats usually fall in the price range of 200 to 400 EURO a month, a one bedroom apartment ranging from 350 to 450 EURO a month and a two bedroom apartment ranging from 340 to 600 EURO a month. Again, pretty affordable rent when you compare them to other countries.</p>

      </div>
    </>


  );
}
export default Study;