import React, { useState } from 'react';
import './index.css';
import { Container } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import img from '../../../../until/Image/PNG/uniapp-laptop-dashboard.png'
const Studentsworldwide = () => {
    const [openState, setOpenState] = useState(null);
    const handleToggle = (index) => {
        const isOpen = openState === index;
        setOpenState(isOpen ? null : index);
    };



    const content = [
        {
            id: 0,
            title: 'Powered By Artificial Intelligence',
            point: [],
            description: 'Our system is built to pre-screen all applications to ensure they are complete to meet your admissions requirements'
        },
        {
            id: 1,
            title: 'Global Reach To Diversify Your Campus',
            point: [
            ],
            description: `Our AI platform and experts on the ground will follow your target in recruiting international students and boost your ROI`
        },
        {
            id: 2,
            title: 'Strong Promotional Channels',
            point: [
            ],
            description: `Your school programs and related information will be promoted on our platforms, events and social media`
        }
    ]
    return (
        <>
            <div className='StudentsworldwideBox'>
                <div className='Container-studentsPage'>
                    <Container maxWidth="lg">
                        <div className='studentsPage-content'>
                            <div>
                                <img src={img} alt="mobile image not found" className='mainImage' />
                            </div>
                            <div>
                                <p style={{ fontWeight: '500' }}>AINTERNATIONAL REACH</p>
                                <h1> Be Seen by <span className='title'>Students</span>  worldwide</h1>

                                {content.map((item) => {
                                    const isOpen = openState === item.id;
                                    return (
                                        <div
                                            className={`collapse-box ${isOpen ? 'active' : ''}`}
                                            key={item.id}
                                        >
                                            <div
                                                className='IconButton'
                                                onClick={() => handleToggle(item.id)}
                                            >
                                                <h3>{item.title}</h3>
                                                {isOpen ? (
                                                    <RemoveIcon className='iconStyle' />
                                                ) : (
                                                    <AddIcon className='iconStyle' />
                                                )}
                                            </div>
                                            <Collapse in={isOpen} className='Collapse'>
                                                {item.point && item.point.length > 0 ? (
                                                    <ul>
                                                        {item.point.map((ele, index) => (
                                                            <li key={index}>{`${ele}`}</li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <p>{item.description}</p>
                                                )}
                                            </Collapse>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}
export default Studentsworldwide;