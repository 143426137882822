import React from 'react';
import './index.css';
import { Container } from '@mui/material';
import HandshakeIcon from '@mui/icons-material/Handshake'
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import img from '../../../../until/Image/PNG/uniapp-laptop-dashboard.png'
import DeveloperBoardTwoToneIcon from '@mui/icons-material/DeveloperBoardTwoTone';
import StorageTwoToneIcon from '@mui/icons-material/StorageTwoTone';
import KeyboardDoubleArrowUpTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowUpTwoTone';
const UniAppPlatform = () => {


    return (
        <>
            <div className="uniAppPlatform">
                <Container maxWidth="lg">

                    <p style={{ textAlign: 'center', color: "#696969" }}>MAXIMIZE REACH</p>
                    <h1 style={{ textAlign: 'center', color: '#333', fontSize: '34px' }}>UniApp is <span className='title'>One Platform</span> Built to Place <br /> Students and Deliver Quality Applications</h1>

                    <div className='main-content'>
                        <div className='left-side'>
                            <div className='first-section'>
                                <div>
                                    <div>
                                        <LanguageTwoToneIcon style={{ fontSize: "50px", color: "#76d4f9" }} />                                        <h3 style={{ marginBottom: '5px' }}>Global Targets</h3>
                                        <p style={{ marginTop: '0' }}>Reach over 1,000+ vetted agents in Africa, the Middle East, and Asia.</p>
                                    </div>

                                </div>
                                <div>
                                    <DeveloperBoardTwoToneIcon style={{ fontSize: "50px", color: "#76d4f9" }} />
                                    <h3 style={{ marginBottom: '5px' }}>Pre Scanning</h3>
                                    <p style={{ marginTop: '0' }}>Artificial intelligence supports our team to save valuable time</p>

                                </div>
                            </div>
                            <div className='first-section'>
                                <div>
                                    <div>
                                        <StorageTwoToneIcon style={{ fontSize: "50px", color: "#76d4f9" }} />
                                        <h3 style={{ marginBottom: '5px' }}>Data Insights</h3>
                                        <p style={{ marginTop: '0' }}>Gain full access to control your student pipeline and turn insights into action
                                            .</p>
                                    </div>
                                </div>
                                <div>
                                    <KeyboardDoubleArrowUpTwoToneIcon style={{ fontSize: "60px", color: "#76d4f9" }} />
                                    <h3 style={{ marginBottom: '5px' }}>Decades of expertise</h3>
                                    <p style={{ marginTop: '0' }}>Our Partnership Developers have extensive experience in HEI
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='right-side'>
                            <img src={img} alt="image not found" />
                        </div>
                    </div>
                </Container>
            </div>
        </>


    );


}

export default UniAppPlatform;