import React, { useState , useEffect } from 'react';
import { Container } from '@mui/material';
import './index.css'

const Afghanistan = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)
      },[])

    return (
        <div className='countryBody'>
            {/* <h1>HELLo</h1> */}
        </div>

    );
}
export default Afghanistan;