import React, { useState, useEffect } from 'react';
import './index.css';
import { Container, TextField } from '@mui/material';
import WOW from 'wowjs';
import axios from 'axios';
import Content from './Content/index'
import img1 from '../../until/Image/PIC/germany.jpg'
import img2 from '../../until/Image/JPG/uk.jpg'
import { useNavigate } from 'react-router';
import img3 from '../../until/Image/JPG/france.jpg'
import img4 from '../../until/Image/JPG/usa.jpg'
import { useParams } from 'react-router-dom';
const StudyIn = () => {
    const inputStyle = {
        backgroundColor: '#fff',
        borderRadius: '18px',
    };

    const [studyTopic, setStudyTopic] = useState('');
    const navigate = useNavigate();
    const { StudyIn } = useParams();
    const [data, setData] = useState(null);

    const getCountry = async () => {
        try {
            const res = await axios.get(`https://api.uni-app.com/destinations/${StudyIn}`);
            setData(res.data)
        } catch (err) {
            console.log(' error get Country ', err.message);
        }
    }

    const handelSearch = (item) => {
        // console.log('item ', item);
        navigate(`/search/?discipline=${studyTopic}&country=${item}`);
    }
    useEffect(() => {
        getCountry();
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, [StudyIn])

    return (
        <div className='wow fadeInDown' data-wow-duration='1s'>
            <div className="parentTheDiv">
                {/* <div className="backgroundImageCountry"></div> */}
                {/* <div className="CountryOverlay"></div> */}
                <Container maxWidth="lg">
                    <div className="CountryText">
                        <h1 >Why study a Bachelor or Master in {data?.title}</h1>
                        <p>Find all the study programs in {data?.title}</p>
                        <div className='sssss'>
                            <TextField
                                fullWidth
                                value={studyTopic}
                                onChange={(e) => setStudyTopic(e.target.value)}
                                size='small'
                                className='input1'
                                placeholder='What do you want to study?'
                                InputProps={{ style: { ...inputStyle } }}
                            />
                            <button className='countrySearchBtn' onClick={() => { handelSearch(data?.title) }}>Search</button>
                        </div>
                    </div>

                </Container>

            </div>
            <Content data={data} />

            <div className='DestinationsAbroad'>

                <Container maxWidth="lg">
                    <h2>Other Study Destinations</h2>
                    <div className='imgCountrySearch'>
                        <div className='image-containerr' onClick={() => {
                            navigate(`/study-destinations/study-in-germany/`);
                        }}>
                            <img src={img1} alt='' />
                            <div className='overlayy'>
                                <p className='overlay-textt'>Germany</p>
                            </div>
                        </div>

                        <div className='image-containerr' onClick={() => {
                            navigate(`/study-destinations/study-in-united-kingdom/`);
                        }}>
                            <img src={img2} alt='' />
                            <div className='overlayy'>
                                <p className='overlay-textt'>United Kingdom</p>
                            </div>
                        </div>

                        <div className='image-containerr' onClick={() => {
                            navigate(`/study-destinations/study-in-france/`);
                        }}>
                            <img src={img3} alt='' />
                            <div className='overlayy'>
                                <p className='overlay-textt'>France</p>
                            </div>
                        </div>

                        <div className='image-containerr' onClick={() => {
                            navigate(`/study-destinations/study-in-usa/`);
                        }}>
                            <img src={img4} alt='' />
                            <div className='overlayy'>
                                <p className='overlay-textt'>United States</p>
                            </div>
                        </div>
                    </div>

                    <button onClick={() => { navigate('/study-destinations/') }}>View All</button>
                </Container>
            </div>
        </div>
    );
}
export default StudyIn;