import React from 'react';
import './index.css'

const Universities = () => {


    return (
        <>
         <div content-tab="university">
                <h2 >
                  Universities in Lithuania
                </h2>
                <p>
                  <strong>Lithuanian University of Health Sciences </strong>is
                  actually the unification of two other universities which
                  happened only a decade ago-Kaunas University of Medicine and
                  Lithuanian Veterinary Academy. As a result, this university
                  has everything-clinical programs ranging from general medicine
                  to nursing to cardiology to neuroscience and so on but it also
                  consists of some preclinical programs focusing on specific
                  topics such as human anatomy or genetics.
                </p>
                <p>
                  <strong>Medicine at Vilnus University,</strong> in comparison
                  to the previous university’s rather young age, was found in
                  1579-one of the oldest institutions in Lithuania and possibly
                  Northern Europe. If you want a good old-fashioned and high
                  quality medicine program along with plentiful of internship
                  opportunities, this medicine school is for you. Keep in mind
                  the average tuition fee of this institution rounds up at
                  roughly 8,520 EURO per year-not a cheap place, but well
                  deserving of the high price tag.
                </p>
                <p>
                  While there are undoubtedly more medical institutions out
                  there in Lithuania, these two are undeniably the most
                  prestigious and well-known out of the lot.
                </p>
              </div>
        </>



    );
}
export default Universities;