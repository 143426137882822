import React, { useState, useEffect } from 'react';
import { Container, CircularProgress, Pagination } from '@mui/material';
import axios from 'axios';
import WOW from 'wowjs';
import './index.css';
import { useNavigate } from 'react-router';


const Articles = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [articles, setArticles] = useState([]);
    const [page, setPage] = useState(1);

    const fetchData = async (url) => {
        const res = await axios.get(url);
        return res.data;
    };


    const scrollView = () => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0);
    }

    const handleChange = async (event, value) => {
        scrollView();
        setPage(value);
        const url = `https://api.uni-app.com/articles?page=${value}&size=9`
        const articlesData = await fetchData(url);
        setArticles(articlesData);
    };

    const getAllArticles = async () => {
        try {
            const articlesData = await fetchData(`https://api.uni-app.com/articles?page=${page}&size=9`);
            setArticles(articlesData);
        } catch (err) {
            console.log('Error when getting all articles', err.message);
        } finally {
            setLoading(false);
        }
    };

    const fullView = (item) => {
        try {
            navigate(`/${item}/`);
        } catch (err) {
            console.log('Error when open fullView', err.message);
        }
    }

    useEffect(() => {
        getAllArticles();
        scrollView();
    }, []);

    return (
        <body style={{ padding: '100px 0' }} className='mainArticle wow fadeInDown' data-wow-duration='2.5s'>
            <Container>
                <div className='articleCardContainer'>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        articles?.content.map((ele, index) => (
                            <div className='articleCard' key={index} onClick={() => { fullView(ele.postName) }}>
                                {ele.articleImage && (
                                    <img src={ele.articleImage} alt='image not found' className='articleImage' />
                                )}
                                <div className='articleContact'>
                                    <p>{ele?.category.toUpperCase()}</p>
                                    <p>{ele?.title}</p>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '25px 0' }}>
                    <Pagination
                        sx={{
                            '& .MuiPaginationItem-root': {
                                color: '#333',
                                fontSize: '15px',
                                '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                    color: '#333',
                                },
                            },
                            '& .Mui-selected': {
                                backgroundColor: '#e0e0e0',
                                color: '#333',
                            },
                        }} count={42} page={page} onChange={handleChange} />
                </div>
            </Container>
        </body>
    );
};

export default Articles;