import React from 'react';
import './Search.css';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
    Container,
} from '@mui/material';

const Gallery = ({ data }) => {
    const CustomArrow = ({ onClick, direction }) => (
        <div className={`custom-arrow ${direction}`} onClick={onClick}>
            {direction === 'prev' ? (
                <KeyboardArrowLeftIcon style={{ backgroundColor: '#FFF', color: '#76d4f9', padding: '10px' }} size='large' onClick={onClick} />
            ) : (
                <KeyboardArrowRightIcon style={{ backgroundColor: '#FFF', color: '#76d4f9', padding: '10px' }} size='large' onClick={onClick} />
            )}
        </div>
    );
    

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomArrow direction="prev" />,
        nextArrow: <CustomArrow direction="next" />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div className='carouselTabs'>
            <Container maxWidth="lg" className="center-container">
                <Slider {...settings}>

                    {data && data.pictures && data.pictures.map((ele) => {
                        return (
                            <div>
                                <img  src={ele.href} />
                            </div>
                        )
                    })}


                </Slider>

            </Container>


        </div>
    );
};

export default Gallery;
