import React from 'react';
import './index.css'

const Universities = () => {


    return (
        <body className='bodyCountry'>
               <div content-tab="university" >
                <h2>
                  Universities in Slovakia
                </h2>
                <p>
                  To study medicine in Slovakia, there are several prestigious
                  universities in Slovakia that offer medical programs in
                  English.
                </p>
                <p>
                  Slovakian medical schools started offering English programs in
                  the 1990s, and the country quickly became so popular with
                  international students who are looking to study medicine in
                  Europe, thanks to the affordable tuition fees and living
                  costs.
                </p>
                <p>
                  All medical degrees in Slovakia are EU-recognized, and they
                  are also recognized in many other countries across the world,
                  so you can work or study in any country of your choice.
                </p>
                <p>
                  There are three universities in Slovakia that provide English
                  programs for medical students:
                </p>
                <p>&nbsp;</p>
                <ol>
                  <li>
                    <strong
                      >Study Medicine in Slovakia at Comenius University in
                      Bratislava</strong
                    >
                  </li>
                </ol>
                <p><strong>&nbsp;</strong></p>
                <p>
                  Czechoslovak State University in Bratislava was renamed
                  Comenius University in 1919, after renowned educator Jan Amos
                  Comenius. With 144 students, the Comenius University Faculty
                  of Medicine in Bratislava was the new university at that time.
                  Therefore, a long heritage of excellence in medical education
                  was established.
                </p>
                <p>&nbsp;</p>
                <p>
                  The six-year program of Medicine and Dentistry in English at
                  this university has attracted a large number of new
                  international students. And most of the graduates have gone on
                  to achieve a very remarkable success around the world.
                </p>
                <p>&nbsp;</p>
                <p>
                  The Comenius University Faculty of Medicine in Bratislava is
                  following the Erasmus Program and have a worldwide
                  collaboration on joint education and research projects.
                </p>
                <p>&nbsp;</p>
                <p>
                  With uni-app, it has become easier than ever to study medicine
                  in Slovakia. It offers a lot of medical programs to choose
                  from.
                </p>
                <p>&nbsp;</p>
                <ol start="2">
                  <li>
                    <strong>Study Medicine in Slovakia at</strong>
                    <strong>Jessenius Faculty of Medicine</strong>
                  </li>
                </ol>
                <p><strong>&nbsp;</strong></p>
                <p>
                  The Jessenius Faculty of Medicine is a part of the prestigious
                  Comenius University in Bratislava. It was completely
                  integrated as a Faculty of Medicine in 1969.
                </p>
                <p>
                  This university is consistently rated as the best medical
                  school in Slovakia and among the best in Central Europe.
                </p>
                <p>&nbsp;</p>
                <p>
                  It is also a very popular scientific organization that
                  prioritizes cutting-edge education and research.
                </p>
                <p>
                  Worth to mention that the Jessenius Faculty of Medicine is the
                  headquarters of three National Centers of Excellence,
                  supported by the EU’s Science and Research program.
                </p>
                <p>&nbsp;</p>
                <p>
                  Graduates get a Doctor of General Medicine or Doctor of
                  Dentistry degree after completing the six-year program.
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  3-
                  <strong
                    >Study Medicine in Slovakia at Pavol Jozef Šafárik
                    University in Košice</strong
                  >
                </p>
                <p><strong>&nbsp;</strong></p>
                <p>
                  Over 12,000 students, from more than 40 countries around the
                  world, have graduated from the Faculty of Medicine at Pavol
                  Jozef afárik University in Koice’s since it was founded in
                  1948.
                </p>
                <p>
                  It started offering medical programs in English in 1992. This
                  Faculty of Medicine educates students.
                </p>
                <p>&nbsp;</p>
                <p>
                  The European Union and the majority of the US recognize
                  medical degrees earned from this Slovakian medical school.
                </p>
                <p>
                  The Faculty of Medicine has around 60 departments,
                  experimental workplaces, and special-purpose facilities.
                </p>
                <p>
                  Students can interact directly with patients while learning
                  cutting-edge therapeutic techniques and integrating theory and
                  practice.
                </p>
                <p>&nbsp;</p>
                <p>
                  The Faculty of Medicine at Pavol Jozef afrik University has
                  obtained the highest level of accreditation, and its purpose
                  is to educate high-quality physicians using cutting-edge
                  medical research.
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>4-</strong
                  ><a
                    href="https://www.premedicine-berlin.de/en/study-medicine-in-slovakia/"
                    ><strong>
                      Study Medicine in Slovakia at Slovak Medical
                      University</strong
                    ></a
                  >
                </p>
                <p><strong>&nbsp;</strong></p>
                <p>
                  The Slovak Medical University was established in Trencin in
                  1953, then relocated to Bratislava in 1966, before adopting
                  its current name in 2002.
                </p>
                <p>&nbsp;</p>
                <p>
                  This Bratislava medical university, along with the Faculty of
                  Nursing and Professional Health Studies and the Faculty of
                  Public Health, focuses on the education of healthcare
                  professionals from undergraduate to specialist level in its
                  four faculties: Medicine, Public Health, Nursing, and
                  Professional Health Studies.
                </p>
                <p>&nbsp;</p>
                <p>
                  In 2012, English-language medical degrees were launched at
                  this university. However, the University Hospital Bratislava
                  is linked with the Slovak Medical University.
                </p>
                <p>&nbsp;</p>
                <p>
                  Slovak Medical University’s intensive training program
                  emphasizes the development of students as persons in order to
                  make them professional in today’s environment.
                </p>
              </div>

        </body>



    );
}
export default Universities;