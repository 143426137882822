import React from 'react';
import './index.css'

const Study = () => {


  return (
    <>
        <div content-tab="study">
                <h2 >
                  Study Medicine in Cyprus
                </h2>
                <p>
                  <span style={{fontWeight:'400'}}
                    >As previously mentioned, Cyprus is one of the top study
                    destinations in the region to study medicine in. Many
                    students choose to study medicine in Cyprus because they
                    receive an excellent English language education in medicine
                    cheaper than if they’d chosen to pursue this in any other
                    English-speaking study destination, among many other
                    reasons. It also has great weather and a good standard of
                    living. Read on to discover why you should choose to study
                    medicine in Cyprus.&nbsp;</span
                  >
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}
                    >Why study medicine in Cyprus?</span
                  >
                </h3>
                <p>
                  <span style={{fontWeight:'400'}}
                    >Here are some of the reasons why you should choose to study
                    medicine in Cyprus, from the great schools to the awesome
                    beaches and scenery:&nbsp;</span
                  >
                </p>
                <ul>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >The stunning architecture and natural beauty of the
                      beaches and other scenery that you can enjoy on weekends
                      or other breaks from studying.&nbsp;</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >High quality of life at a reasonable cost when compared
                      to other countries that have the same high standard of
                      living.&nbsp;</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >Safe living environment and area so you can go out at
                      night or in the day feeling safe, as long as you take
                      common sense rules into consideration.&nbsp;</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >Reasonably costing living and transportation systems so
                      you won’t pay an arm and a leg to complete your studies in
                      the country.&nbsp;</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >Cyprians are friendly, sociable and warm people, meaning
                      that you’ll always feel at home there!&nbsp;</span
                    >
                  </li>
                </ul>
              </div>
    </>


  );
}
export default Study;