import React from 'react';
import './index.css';
import ImageSection from './ImageSection';
import UniAppTImeStudents from './UniAppTImeStudents';
const StudentAppGuide = () => {
    return (
        <>
            <ImageSection />
            <UniAppTImeStudents/>
        </>
    );
}
export default StudentAppGuide;