import React, { useEffect } from 'react';
import './index.css';
import Header from './Header'
import FirstSeciton from './FirstSeciton';
import SecondSection from './SecondSection';
import ManagementTeam from './ManagementTeam';
import WOW from 'wowjs';
import AdvisoryBoard from './FirstSeciton/AdvisoryBoard';


const AboutUs = () => {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='about-us wow fadeInDown' data-wow-duration='1s'>
            <Header />
            <FirstSeciton />
            <SecondSection />
            <ManagementTeam />
            <AdvisoryBoard />
        </div>
    );
};

export default AboutUs;
