import React, { useEffect } from 'react';
import ApplyForABachelors from '../../ApplyForABachelors';
import EasierWithUniApp from "../../EasierWithUniApp";
import Header from "../../Header/Header";
import MakeSureSection from "../../MakeSureSection";
import PerfectDegreeSection from "../../PerfectDegreeSection";
import Platform from "../../Platform";
import './index.css';
import WOW from 'wowjs';
import Secrets from "../../Secrets";
import ShareCom from "../../ShareCom";
import StudyDestinations from "../../StudyDestinations";
import { Container } from '@mui/material';
const HomeScreen = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <body className='wow fadeInDown' data-wow-duration='1.5s'>
      <Header />
      <ApplyForABachelors flag={true} />
      <Platform />
      <EasierWithUniApp />
      <MakeSureSection />
      <Secrets />
      <PerfectDegreeSection />
      <div className='shareComCont'>
        <Container maxWidth="lg">
          <div className='sideMain'>
            <p>Apply for a <span className='title'> Bachelors or Masters </span>  Revealed  on UniApp</p>
            <h1 className='titleHeader'>Discover and Apply to the Best Schools</h1>
            <div className='btnStyle'>
              <span onClick={() => {
                window.location.href = 'https://my.uni-app.com/sign-in?redirectURL=%2Fhome';

              }}>
                Start Your Application
              </span>
            </div>
          </div>
        </Container >
      </div >
      <StudyDestinations />
      <div className='shareComCont'>
        <Container maxWidth="lg">
          <div className='sideMain'>
            <p>Your Complete <span className='title'> University Application </span> Guide</p>
            <h1 className='titleHeader'>You can be your own guiding star with our help!</h1>
            <div className='btnStyle'>
              <span onClick={() => {
                window.location.href = 'https://www.facebook.com/uniapp.official';
              }}>
                Student Application Guide
              </span>
            </div>
          </div>
        </Container >
      </div >

    </body>


  );
}

export default HomeScreen;