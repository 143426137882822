import "./App.css";
import "wowjs/css/libs/animate.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./component/Footer";
import NavBar from "./component/Navbar/NavBar";
import HomeScreen from "./component/Screen/HomeScreen";
import FaqScreen from "./component/Screen/FaqScreen";
import StudentsScreen from "./component/StudentsScreen";
import SchoolScreen from "./component/SchoolScreen";
import ContactUsScreen from "./component/contactUsScreen";
import StudyAgents from "./component/StudyAgents";
import StudentApplicationGuide from "./component/StudentApplicationGuide";
import AboutUs from "./component/AboutUs";
import StudyInCountries from "./component/StudyInCountries";
import AllCountries from "./component/StudyDestinations/AllCountries";
import BachelorsDegrees from "./component/BachelorsDegrees";
import ScrollToTop from "./component/ScrollToTop";
import OnlineDegrees from "./component/OnlineDegrees";
import MastersDegree from "./component/MastersDegree/Index";
import NoTitle from "./component/NoTitle";
import CategoryUncategorized from "./component/CategoryUncategorized";
import AcademicPapers from "./component/AcademicPapers";
import StudyMedicineInGermany from "./component/StudyMedicineInGermany";
import GuideFirstTimeStudents from "./component/StudentApplicationGuide/GuideToCollege/GuideFirstTimeStudents";
import GuideTransferStudent from "./component/StudentApplicationGuide/GuideToCollege/GuideTransferStudent";
import Afghanistan from "./component/StudyInCountries/Afghanistan";
import StudyMedicine from "./component/StudyMedicine";
import PrivacyAndTerms from "./component/PrivacyAndTerms";
import Terms from "./component/PrivacyAndTerms/Terms";
import Impressum from "./component/PrivacyAndTerms/Impressum";
import France from "./component/StudyInCountries/France";
import USA from "./component/StudyInCountries/USA";
import UnitedKingdom from "./component/StudyInCountries/UnitedKingdom";
import Germany from "./component/StudyMedicineInEurope/Germany";
import Search from "./component/Search";
import Program from "./component/Search/Program";
import NotFound from "./component/NotFound";
import StudyIn from "./component/StudyDestinations/StudyIn";
import Articles from "./component/Articles";
import FullViewArticles from "./component/Articles/FullViewArticles";
import Slovakia from "./component/StudyMedicineInEurope/Slovakia";
import Latvia from "./component/StudyMedicineInEurope/Latvia";
import Hungary from "./component/StudyMedicineInEurope/Hungary";
import Lithuania from "./component/StudyMedicineInEurope/Lithuania";
import Cyprus from "./component/StudyMedicineInEurope/Cyprus";
import Romania from "./component/StudyMedicineInEurope/Romania";
const App = () => {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route path="/faq/" element={<FaqScreen />} />
          <Route path="/Students/" element={<StudentsScreen />} />
          <Route path="/schools/" element={<SchoolScreen />} />
          <Route path="/contact-us/" element={<ContactUsScreen />} />
          <Route path="/study-agents/" element={<StudyAgents />} />
          <Route
            path="/student-application-guide/"
            element={<StudentApplicationGuide />}
          />
          <Route path="/about-us/" element={<AboutUs />} />
          <Route path="/study-destinations/" element={<AllCountries />} />
          <Route path="/study-destinations/:StudyIn/" element={<StudyIn />} />
          <Route path="/bachelors-degrees/" element={<BachelorsDegrees />} />
          <Route path="/online-degrees/" element={<OnlineDegrees />} />
          <Route path="/14128-2/" element={<NoTitle />} />
          <Route
            path="/category/uncategorized/"
            element={<CategoryUncategorized />}
          />
          <Route
            path="/where-to-source-for-academic-papers/"
            element={<AcademicPapers />}
          />
          <Route
            path="/student-application-guide/first-time-students/"
            element={<GuideFirstTimeStudents />}
          />
          <Route
            path="/student-application-guide/transfer-student/"
            element={<GuideTransferStudent />}
          />
          <Route path="*" element={<NotFound />} />
          <Route path="/Afghanistan/" element={<Afghanistan />} />
          <Route
            path="/study-medicine-in-europe/"
            element={<StudyMedicine />}
          />
          <Route path="/privacy/" element={<PrivacyAndTerms />} />
          <Route path="/impressum/" element={<Impressum />} />
          <Route path="/terms/" element={<Terms />} />
          <Route
            path="/study-medicine-in-europe/study-medicine-in-germany/"
            element={<Germany />}
          />
          <Route
            path="/study-medicine-in-europe/study-medicine-in-slovakia/"
            element={<Slovakia />}
          />
          <Route
            path="/study-medicine-in-europe/study-medicine-in-latvia/"
            element={<Latvia />}
          />

          <Route
            path="/study-medicine-in-europe/study-medicine-in-czech-republic/"
            element={<Hungary />}
          />

          <Route
            path="/study-medicine-in-europe/study-medicine-in-lithuania/"
            element={<Lithuania />}
          />

          <Route
            path="/study-medicine-in-europe/study-medicine-in-cyprus/"
            element={<Cyprus />}
          />

          <Route
            path="/study-medicine-in-europe/study-medicine-in-romania/"
            element={<Romania />}
          />

          <Route path="/search/?" element={<Search />} />
          <Route path="/Program/:slug" element={<Program />} />
          <Route path="/articles/" element={<Articles />} />
          <Route path="/:postName/" element={<FullViewArticles />} />
        </Routes>
        <ScrollToTop />
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
