import React, { useEffect } from 'react';
import './index.css';
import { Container } from '@mui/material'
import Tabs from '../GuideToCollege/Tabs'
import GuideFirstTimeComponent from './GuideFirstTimeComponent';
import WOW from 'wowjs';

const GuideFirstTimeStudents = () => {

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, [])
    return (
        <div style={{ padding: '50px 0px' }}>
            <Container maxWidth="lg">
                <div className='mainBox'>
                    <div className='left-side'>
                        <Tabs />
                    </div>
                    <div className='right-side wow fadeInDown' data-wow-duration='1s' >
                        <div>
                            <GuideFirstTimeComponent />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
export default GuideFirstTimeStudents;