import React, { useState, useEffect } from 'react';
import './index.css';
import { Link, NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router';

import { Container } from '@mui/material';
const Header = () => {
    const navigate = useNavigate();
    const [TeamCount, setTeamCount] = useState(0);
    const [RateCount, setRateCount] = useState(0);
    const [StudentCount, setStudentCount] = useState(0);
    const [AgentsCount, setAgentsCount] = useState(0);



    const increaseCount = () => {
        if (TeamCount < 50) {
            setTimeout(() => {
                setTeamCount(TeamCount + 1);
            }, 10);
        }
    };

    const Rate = () => {
        if (RateCount < 99) {
            setTimeout(() => {
                setRateCount(RateCount + 1);
            }, 5);

        }
    }

    const Student = () => {
        if (StudentCount < 80) {
            setTimeout(() => {
                setStudentCount(StudentCount + 2);
            }, 10);

        }
    }

    const Agents = () => {
        if (AgentsCount < 1000) {
            setTimeout(() => {
                setAgentsCount(AgentsCount + 10);
            }, 5);

        }
    }

    useEffect(() => {
        increaseCount();
        Rate();
        Agents();
        Student();
    })


    return (
        <>
            <div style={{ paddingTop: '100px', backgroundColor: '#f8f8f8' }}>
                <div className='Container-studentsPage'>
                    <Container maxWidth="lg">
                        <div className='studentsPage-content'>
                            <div>
                                <p style={{ fontWeight: '500' }}>PARTNERSHIP WITH  <span className='title'>UNIAPP</span> </p>
                                <h1 style={{color:'#333'}}> Scale-Up And <br /> <span className='title'>Diversify</span> Your School </h1>
                                <p>UniApp is your long-term partner to increase your global reach.
                                    Get access to thousands of vetted and well trained recruitment partners
                                    .</p>
                                <div className='btnStyle' onClick={() => { navigate('/contact-us/') }}>
                                    <span className='btnContact' >
                                        Partner With Us
                                    </span>
                                </div>
                            </div>
                            <div className='count'>

                                <div >
                                    <h1 style={{ fontWeight: '800' }}>{TeamCount}+</h1>
                                    <p style={{ color: '#3f3a64' }}> Team Memberss</p>
                                </div>
                                <div>
                                    <h1 style={{ fontWeight: '800' }}>{RateCount}%</h1>
                                    <p style={{ color: '#3f3a64' }}>satisfaction rate</p>
                                </div>

                                <div >
                                    <h1 style={{ fontWeight: '800' }}>{StudentCount}<span>+</span></h1>
                                    <p style={{ color: '#3f3a64' }}>Student's nationality </p>
                                </div>


                                <div >
                                    <h1 style={{ fontWeight: '800' }}>{AgentsCount}+</h1>
                                    <p style={{ color: '#3f3a64' }}> vetted agents </p>
                                </div>


                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}
export default Header;