import React from 'react';
import './index.css';
import EastIcon from '@mui/icons-material/East';
import FIlterSection from './FIlterSection';
import { useNavigate } from 'react-router';
import ApplyForABachelors from '../../ApplyForABachelors';

const LeftSide = () => {
    const navigate = useNavigate();
    return (
        <div>
        
        <div className='LeftSideMenu'>
            <h2>Popular posts</h2>
            <div onClick={() => {navigate('/study-medicine-in-germany-for-international-students/')}}>
                <EastIcon className='arrowStyle' />
                <p>The Ultimate Guide to Study Medicine in Germany for International Students in 2023</p>
            </div>
            <hr style={{ width: '100%', borderTop: '1px solid #EEE' }} />
            <div onClick={() => { navigate('/study-medicine-in-germany-in-english/') }}>
                <EastIcon className='arrowStyle' />
                <p>How to Study Medicine in Germany in English? Breaking the Language Barrier</p>

            </div>
            <hr style={{ width: '100%', borderTop: '1px solid #EEE' }} />

            <div onClick={() => { navigate('/the-essential-steps-to-study-medicine-in-germany/') }}>
                <EastIcon className='arrowStyle' />
                <p>The Essential Steps to Study Medicine in Germany: The Path to Medical Excellence</p>
            </div>
            <hr style={{ width: '100%', borderTop: '1px solid #EEE' }} />

            <div onClick={() => { navigate('/the-best-university-to-study-medicine-in-germany/') }}>
                <EastIcon className='arrowStyle' />
                <p>The Best University to Study Medicine in Germany: Aiming for Medical Excellence</p>
            </div>
            <hr style={{ width: '100%', borderTop: '1px solid #EEE' }} />

            <div onClick={() => { navigate('/understanding-student-visa-requirements-for-germany/') }}>
                <EastIcon className='arrowStyle' />
                <p>Understanding Student Visa Requirements for Germany: How to Secure your own?</p>
            </div>
            <hr style={{ width: '100%', borderTop: '1px solid #EEE' }} />
        </div>
            <ApplyForABachelors flag={false}/>
        </div>
    );
}

export default LeftSide;