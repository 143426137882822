import React from 'react';
import './index.css'

const Universities = () => {


    return (
    <>
         <div content-tab="university">
                <h2 >
                  Universities in Czech Republic
                </h2>
                <h2>
                  <span style={{fontWeight:'400'}}
                    >Top medicine universities in Czech Republic</span
                  >
                </h2>
                <p>
                  <span style={{fontWeight:'400'}}
                    >Studying medicine in Czech is worthwhile. Prague is home to
                    great medical faculties which are deeply rooted in history. </span
                  >There are more than 90 academic institutions that you can
                  find in the country. Here is a list of top universities
                  offering medical degrees:
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}
                    >First Faculty of Medicine in Prague&nbsp;</span
                  >
                </h3>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The First Faculty of Medicine in Prague is Central Europe’s
                    oldest medical school. It was founded in 1348, along with
                    Charles University, making it the world’s 11th oldest
                    medical institution. The faculty is located near the city
                    center of Prague, Czech Republic’s capital city. Prague is
                    sometimes referred to as “The City of One Hundred Spires.”
                    The faculty educates students in all areas of general
                    medicine. The First Faculty of Medicine is the largest
                    medical faculty in the Czech Republic, with nearly 1200
                    staff members and 3400 students. The General University
                    Hospital in Prague hosts the majority of clinical
                    instruction, but other teaching hospitals include Thomayer
                    Teaching Hospital, Military University Hospital in Prague,
                    and Hospital Na Bulovce and University Hospital in Motol.
                  </span>
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}
                    >Palacký University Olomouc</span
                  >
                </h3>
                <p>
                  Today’s Palacky University in Olomouc was founded by Jesuits
                  in 1573. At the public university, almost 170 professors look
                  after around 24,500 students. In addition to Czech courses, it
                  also offers a course in human medicine in English. The course
                  is structured in a similar way to that in Germany, Austria and
                  many other EU countries. In the first four semesters, the
                  pre-clinical subjects are taught, which focus on the
                  physiology of the human body. Practical classes and the
                  associated clinical subjects begin in the fifth semester. From
                  this point on, the previously developed theory is applied in
                  practice, and knowledge is deepened and expanded
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}>The University of Prague</span>
                </h3>
                <p>
                  The University of Prague, to which the Medical Faculty of
                  Pilsen belongs, was founded in 1348 and is therefore the
                  oldest German-speaking university. It has more than 8,098
                  employees and around 48,000 students. In addition to Czech
                  courses, it also offers English and German programs. An
                  international university ranking by Shanghai University in
                  2011 placed it among the best 1.5% of all universities
                  worldwide: In a comparison of 17,000 university educational
                  institutions, it is in the field between 201 and 300.
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}
                    >The Faculty of Medicine in Hradec Kralove&nbsp;</span
                  >
                </h3>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The Faculty of Medicine in Hradec Kralove started its
                    educational activities in November 25, 1945 as the first
                    University Faculty in East Bohemia. The Faculty of Medicine
                    organizes courses in English since 1992. Currently,
                    approximately 1400 students are studying medicine in Hradec
                    Kralove, with 400 coming from abroad. The Czech and English
                    curriculums are parallel and fully compatible with current
                    international standards (approved by the EU and the US).
                    Clinical training is provided at the Faculty Hospital, a
                    complex of 37 clinics and departments with 1500 beds and a
                    staff of 4000 doctors and nurses.</span
                  >
                </p>
              </div>
    </>



    );
}
export default Universities;