import React from 'react';
import './index.css'

const HowToApply = () => {


    return (
      <>
        <div content-tab="howtoapply">
                <h2 >
                  How to apply - Lithuania
                </h2>
                <h3><strong>Study Visa </strong></h3>
                <h4><strong>The process:</strong></h4>
                <ol>
                  <li>Get accepted at a university place</li>
                  <li>
                    Schedule a visa appointment with the Lithuanian embassy in
                    your country
                  </li>
                  <li>
                    Download, print and complete the visa application forum.
                  </li>
                  <li>Get any supporting documents you might need</li>
                  <li>Attend visa appointment</li>
                  <li>Wait for your acceptance</li>
                  <li>
                    Electronically register for a temporary residence permit
                  </li>
                  <li>
                    Go to the Lithuanian embassy and present your documents in
                    person for the student visa
                  </li>
                </ol>
                <h4><strong>Required documents:</strong></h4>
                <p><strong>For the student visa (Visa D):</strong></p>
                <ul>
                  <li>A completed visa application forum</li>
                  <li>A recent passport-sized photograph of yourself</li>
                  <li>
                    A valid passport with an expiry date at least 3 months
                    beyond the duration of your study program
                  </li>
                  <li>
                    Formal admissions letter from your university, at least two
                    blank pages, and issued within the last 10 years
                  </li>
                  <li>
                    a letter of mediation submitted electronically by the higher
                    education institution via the Lithuanian Migration
                    Information System (MIGRIS)
                  </li>
                  <li>
                    Proof of sufficient financial resources (at least €150 for
                    every month of your stay)
                  </li>
                  <li>
                    Details of your accommodation arrangements and address
                  </li>
                  <li>Your flight itinerary (including return tickets)</li>
                  <li>
                    A health insurance policy with a minimum coverage of €30,000
                  </li>
                </ul>
                <p><strong>For the temporary residence permit:</strong></p>
                <ul>
                  <li>A valid Visa D</li>
                  <li>A valid passport</li>
                  <li>
                    An electronically submitted application through MIGRIS
                  </li>
                  <li>One passport-sized photograph of yourself</li>
                  <li>
                    Proof of financial means to cover your Lithuanian living
                    costs
                  </li>
                  <li>A police clearance certificate from your home country</li>
                  <li>
                    Details of your health insurance policy (with coverage of at
                    least €6,000)
                  </li>
                  <li>Means to pay the processing fee</li>
                </ul>
                <p><strong>Process time:</strong></p>
                <p>
                  The decision will take up to 15 days after the date of
                  receiving the application to properly register and be
                  rendered. Depending on the circumstances, this could take up
                  to 45 days or even longer thus it is important to get this out
                  of the way as quickly as possible.
                </p>
                <h4><strong>Can you work on a student visa?</strong></h4>
                <p>
                  Yes. You will be able to work up to 20 hours a week during the
                  term time, and full-time during the holidays. This permissions
                  is automatically granted once you get your student visa so you
                  don’t need to go through any more hoops.
                </p>
                <h3><strong>Scholarships</strong></h3>
                <p>
                  For those short on money, do not worry-there are plenty of
                  ways one can scrounge up to alleviate their financial burden.
                  <a href="https://uni-app.com/">Uniapp</a> can also help you
                  apply for your degree and scholarships. The most forthright
                  way is scholarships-awarded to those who have achieved
                  academic excellence, they are a great way for the bright minds
                  with not so bright wallets to still afford their education. In
                  this example, we will be covering the Lithuanian University of
                  Health Sciences. Examples include:
                </p>
                <ol>
                  <li>
                    International Excellence Scholarship (for students who are
                    actively involved and have achieved great results in the
                    fields of increasing the internationality of LSMU, creating
                    a multicultural environment, as well as growing the
                    awareness of LSMU abroad);
                  </li>
                  <li>
                    Lithuanian Science Support Dr. Juozas and Birutė Skrinskai
                    Fund (for students of the Faculty of Medicine);
                  </li>
                  <li>
                    Vytenis Povilas Andriukaitis Scholarship (for the student of
                    the Faculty of Medicine and the Faculty of Public Health);
                  </li>
                  <li>
                    Juozas Olekas Scholarship (for a student of the Faculty of
                    Medicine);
                  </li>
                  <li>
                    JSC “Gintarinė vaistinė” Scholarship (for the 4th&nbsp;and
                    5th-year&nbsp;students of the Faculty of Pharmacy)
                  </li>
                  <li>
                    JSC “MED GRUPĖ” Scholarship (for a student of the Odontology
                    programme at the Faculty of Odontology)
                  </li>
                  <li>
                    Veterinary Pharmacy Association Scholarship (for a student
                    of the Veterinary Medicine study programme at the Faculty of
                    Veterinary Medicine).
                  </li>
                </ol>
                <h4><strong>Financial aid office</strong></h4>
                <p>
                  For those unable to land a scholarship but still require
                  financial aid, the financial aid office will be more than
                  happy to look out for you. Their CHANCEN eG Income Share
                  Agreement Program in particular is helpful for the students
                  who come from Germany, Australia and Switzerland.
                </p>
                <h4><strong>Masters programs</strong></h4>
                <p>
                  For masters programs, they have a specific scholarship for
                  those. It consists of:
                </p>
                <p>
                  A monthly scholarship and a grant covering the study
                  cost&nbsp;offered to citizens of:<br />
                  Ukraine&nbsp;(30 grants);&nbsp;Armenia, Azerbaijan, Georgia,
                  foreign citizens of Lithuanian origin, Moldova, (15 grants
                  altogether);<br />
                  A monthly scholarship&nbsp;offered to citizens of&nbsp;China,
                  Israel, Japan, South Korea&nbsp;(5 grants altogether).
                </p>
                <p>&nbsp;</p>
                <p>
                  Admittedly, there is a bit of favouritism towards the Slavic
                  students but if your country is not on the list, you can
                  always ask around the department for more details.
                </p>
                <h4><strong>Miscellaneous aid</strong></h4>
                <p>
                  The Lithuanian University of Health Sciences also has a
                  program to assist with cultural adjustment and those who might
                  be feeling homesick, a mentorship program to aid the newer
                  students around the campus and ease them into the integration
                  process, the typical tutoring services one would find in a
                  university and special accommodation for students with special
                  needs as well as free mental healthcare for those who require
                  psychological tending.
                </p>
                <p>&nbsp;</p>
              </div>
      </>


    );
}
export default HowToApply;