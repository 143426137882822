import React from 'react';
import './index.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import img1 from '../../../../until/Image/CTOPIC/ARDEN.jpg'
import img2 from '../../../../until/Image/CTOPIC/BSBI.jpg'
import img3 from '../../../../until/Image/CTOPIC/EU-BS.jpg'

import img4 from '../../../../until/Image/CTOPIC/FOM.jpg'
import img5 from '../../../../until/Image/CTOPIC/SIU.jpg'
import img6 from '../../../../until/Image/CTOPIC/SRH.jpg'

import img7 from '../../../../until/Image/CTOPIC/b.jpg'
import img8 from '../../../../until/Image/CTOPIC/c.jpg'

import img9 from '../../../../until/Image/CTOPIC/d.jpg'

import img10 from '../../../../until/Image/CTOPIC/e.jpg'

import img11 from '../../../../until/Image/CTOPIC/f.jpg'

import img12 from '../../../../until/Image/CTOPIC/g.jpg'

import img13 from '../../../../until/Image/CTOPIC/h.jpg'

import { Container } from '@mui/material';
const CarouselCard = () => {

    const CustomArrow = ({ onClick, direction }) => (
        <div className={`custom-arrow ${direction}`} onClick={onClick}>
            {direction === 'prev' ? <KeyboardArrowLeftIcon size='large' /> : <KeyboardArrowRightIcon size='large' />}
        </div>
    );


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: <CustomArrow direction="prev" />,
        nextArrow: <CustomArrow direction="next" />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };
    return (
        <div className='customCarousel'>
            <Container maxWidth="lg">
                <Slider {...settings}>
                    <div>
                        <img src={img1} />
                    </div>
                    <div>
                    <img src={img2} />
                    </div>
                    <div>
                    <img src={img3} />
             
                    </div>
                    <div>
                    <img src={img4} />
                     
                    </div>
                    <div>
                    <img src={img5} />
             
                    </div>
                    <div>
                    <img src={img6} />
                       
                    </div>
                    <div>
                    <img src={img7} />
           
                    </div>
                    <div>
                    <img src={img8} />
        
                    </div>

                    <div>
                    <img src={img9} />
                 
                    </div>

                    <div>
                    <img src={img10} />
                     
                    </div>

                    <div>
                    <img src={img11} />
                     
                    </div>


                    <div>
                    <img src={img12} />
                  
                    </div>

                    <div>
                    <img src={img13} />
                  
                    </div>
                </Slider>
            </Container>
        </div>


    );


}

export default CarouselCard;