import React, { useEffect } from 'react';
import FaqComponent from '../../FaqComponent/Index';
import WOW from 'wowjs';
const FaqScreen = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    
    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);
    return (
        <div className='wow fadeInDown' data-wow-duration='1.5s'>
            <FaqComponent />
        </div>
    );
}

export default FaqScreen;