import React from 'react';
import './index.css'

const Study = () => {


    return (
        <>
           <div content-tab="study" >
                <h2>
                  Study Medicine in Czech Republic
                </h2>
                <h2>
                  <span style={{fontWeight:'400'}}
                    >Why study medicine in Czech Republic?</span
                  >
                </h2>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The Czech Republic has a long medical history dating back
                    to the early fourteenth century AD. Foreign students wishing
                    to complete their studies in human medicine or one of the
                    educational branches related to it outside their home
                    countries should consider Czech medical colleges. Students
                    can study medicine in Czech for free, with the costs of
                    studying the Czech language included in the study costs, or
                    they can study it in English, which starts at 14,000 euros
                    for the academic year. A bachelor’s degree in medicine takes
                    6 years to complete in the Czech Republic.</span
                  >
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}
                    >The tuition costs of study Medicine in Czech Republic</span
                  >
                </h3>
                <p>
                  The tuition costs are rising worldwide, which burns a hole in
                  the pocket and might prevent outstanding and passionate
                  students from achieving the dream.
                  <span style={{fontWeight:'400'}}
                    >For students who choose to study in Czech, studying
                    medicine in the Czech Republic is free, whereas studying in
                    English requires payment of fees. The cost of attending a
                    Czech university varies depending on the institution.
                    Private university tuition in the Czech Republic ranges from
                    4000 to 14000 euros. The tuition fee for studying medicine
                    at Charles University is €13,850 (360,000 kroner) per year;
                    at Masaryk University, the fee is 300,000 CZK.</span
                  >
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}>Cost of living&nbsp;</span>
                </h3>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The cost of living in the Czech Republic is lower than in
                    most other European countries, with the average monthly cost
                    ranging from €430 to €700. It is critical to recognize that
                    the cost of living varies greatly depending on one’s
                    lifestyle and place of residence.</span
                  >
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}
                    >why you should study in the Czech Republic Medicine in
                    Czech Republic?</span
                  >
                </h3>
                <p>
                  The country offers medical degrees with affordable tuition
                  fees. The universities have world-class infrastructure, and
                  the curriculum is highly advanced and continues to create
                  history. Here are a few reasons why you should study in the
                  Czech Republic
                </p>
                <ul>
                  <li>The programs are offered in the English language.</li>
                  <li>
                    The student community is diverse, making people from
                    different cultures and traditions feel valued and inclusive.
                  </li>
                  <li>
                    The general medicine program is for 6 years, while the
                    dentistry and pharmacy program is 5 years each.
                  </li>
                  <li>
                    Nine of the top universities in the world ranked by QS World
                    Rankings are in the Czech Republic.
                  </li>
                  <li>You can study in the country without IELTS or TOEFL.</li>
                  <li>
                    You can earn while learning. Twenty hours a week part-time
                    work is allowed whereas you can work full-time during the
                    summer holidays.
                  </li>
                  <li>
                    There are many scholarships offered by the government, for
                    which you can apply to avail the benefits.
                  </li>
                </ul>
                <h3>
                  <span style={{fontWeight:'400'}}
                    >Junior (preparatory) year&nbsp;</span
                  >
                </h3>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The preparatory year lasts 9 months and requires 35 hours
                    per week. The goal is for the student to learn Czech and
                    prepare for university entrance exams, during which the
                    emphasis is placed on the subjects that the student will
                    study later according to his specialization, specifically
                    mathematics, physics, chemistry, and biology. The
                    preparatory year is divided into two semesters, beginning in
                    October and ending in June, when university admission exams
                    are held at the end of the same month. It should be noted
                    that there is competition and trade-offs among students,
                    particularly in the Colleges of Medicine and Dentistry, and
                    the student must strive for a high average (88 or higher) in
                    the results of the preparatory year to ensure a seat in
                    either of these two colleges.</span
                  >
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}
                    >Work while studying&nbsp;</span
                  >
                </h3>
                <p>
                  <span style={{fontWeight:'400'}}
                    >Czech law allows foreign students to work 1,000 hours per
                    year, and most students work two to three hours per day,
                    plus working holidays, for a monthly income of 400-500
                    euros, with an hourly wage of 10-12 euros on average.</span
                  >
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}>Other medical fields </span>
                </h3>
                <p>
                  <span style={{fontWeight:'400'}}
                    >Knowledge of other medical specialties, in addition to
                    human medicine, can be useful for applicants to study in the
                    Czech Republic because some people want to study in other
                    medical specialties such as dentistry, pharmacy, nursing,
                    and so on. Other mentioned medical specialties, such as
                    medicine, are taught in English or Czech. In Czech, studying
                    medicine takes 6 years, while dentistry and pharmacy take 5
                    years. In other medical fields, students must first earn a
                    bachelor’s degree, followed by master’s and doctoral
                    degrees. In this country, a bachelor’s degree typically
                    lasts 3 to 4 years, a master’s degree 1 to 3 years, and in
                    some fields 4 to 6 years.</span
                  >
                </p>
              </div>
        </>



    );
}
export default Study;