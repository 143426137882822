import React, { useEffect } from 'react';
import './index.css';
import Header from './component/Header';
import WOW from 'wowjs';
import ShareCom from '../ShareCom';

import UniWork from './component/UniWork';
import UniversityApplication from './component/UniversityApplication';
import StudyDestinations from '../StudyDestinations';
import PerfectDegreeSection from '../PerfectDegreeSection';
import ChooseUniApp from './component/ChooseUniApp';
import { Container } from '@mui/material';

import { useNavigate } from 'react-router';


const StudentsScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div className='wow fadeInDown' data-wow-duration='1.5s' style={{ padding: '100px 0px' }}>
      <Header />
      <div className='shareComCont'>
        <Container maxWidth="lg">
          <div className='sideMain'>
            <p> Wide Selection of <span className='title customMainTitle'> International Universities </span> And Degrees</p>
            <h1 className='titleHeader'>Access to top colleges throughout the world compare, and apply to</h1>
            <div className='btnStyle'>
              <span onClick={() => {
                window.location.href = 'https://my.uni-app.com/sign-in?redirectURL=%2Fhome';
              }}>
                Start Your Uni-Application
              </span>
            </div>
          </div>
        </Container >
      </div>
      <UniWork />
      <UniversityApplication />
      <ChooseUniApp />
      <StudyDestinations />
      <div className='shareComCont'>
        <Container maxWidth="lg">
          <div className='sideMain'>
            <p>HAVE A QUESTION?</p>
            <h1 className='titleHeader'> We’re always here for you</h1>
            <div className='btnStyle'>
              <span onClick={() => {
                navigate('/contact-us/')
              }}>
                Contact Our Support Team
              </span>
            </div>
          </div>
        </Container >
      </div >
      <PerfectDegreeSection />
    </div>
  );
}
export default StudentsScreen;