import React from 'react';
import './index.css'

const Universities = () => {


    return (
        <>
        <div content-tab="university">
                <h2>Universities in Cyprus</h2>
                <p>
                  <span style={{fontWeight:'400'}}
                    >Cyprus is renowned for the excellent medical institutions
                    that it offers students. These schools attract many students
                    from around the globe who want to study medicine in Cyprus.
                    From the University of Nicosia to the European University of
                    Cyprus, here’s everything you need to know about the best
                    medical institutions in Cyprus if you want to study your
                    degree there.&nbsp;</span
                  >
                </p>
                <h3>
                  <span style={{fontWeight:'400'}}
                    >The University of Nicosia</span
                  >
                </h3>
                <p>
                  <span style={{fontWeight:'400'}}>Location: Nicosia&nbsp;</span>
                </p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The University of Nicosia is arguably the best school in
                    Cyprus offering medical studies. In the year 2011, the
                    university partnered with St. George’s University of London,
                    which is the only specialized college in medicine and health
                    in the UK,&nbsp; to offer a remarkable Bachelor of Medicine
                    and Bachelor of Surgery ( MBBS) program. The school is one
                    of the only medical schools in Europe to receive the triple
                    recognition (5 QS Stars, EADTU, EFQUEL) for excellence in
                    distance learning, utilising the latest technologies and
                    offering top notch learning design theories to offer the
                    medical program.&nbsp;</span
                  >
                </p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >More than 800 students from over 65&nbsp; countries, such
                    as USA and Canada, utilise the high tech courses offered by
                    the university. A whopping 99.4 percent of the graduates
                    from the school work in over 200 excellent medical centres
                    around the globe.&nbsp;&nbsp;</span
                  >
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The program focuses on offering students practice based
                    training to make sure they are ready for real life settings
                    after graduation. It offers a wide range of medical degrees
                    like a Master’s in Public Health, though the main degree
                    focus offered by the school is the MD. The MD program is
                    accredited and allows students to train at hospitals both in
                    Cyprus, as well as in the United Kingdom. After graduation,
                    students will be allowed to practice medicine across
                    Europe&nbsp;</span
                  >
                </p>
                <p>&nbsp;</p>
                <h2>
                  <span style={{fontWeight:'400'}}
                    >European University Cyprus</span
                  >
                </h2>
                <p>
                  <span style={{fontWeight:'400'}}>Location: Engomi&nbsp;</span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >Another top medical school in Cyprus is the European
                    University Cyprus, located in Cyprian city of Engomi. The
                    school offers an MD program that lasts for 6 years with the
                    motto being “rigorous scientific theory with
                    patient-centered clinical practice.” The school also hosts
                    an online course taught at Harvard T.H. Chan School of
                    Public Health: the renowned “Principles and Practice of
                    Clinical Research.” course. The program offered is&nbsp;
                    similar to the medical programs offered in the American
                    education system. Furthermore, graduates of the program can
                    apply for the US Medical Licensing Examination to get
                    licensed as a doctor in the US.&nbsp;</span
                  >
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >While studying the program, students also get to gain
                    practical experience working in public and private hospitals
                    across Cyprus. The school also offers international
                    externships during the summer to receive even more hands on
                    training. These externships includes ones at organisations
                    like Shriners Hospitals for Children in the US, Imperial
                    College London in the UK or the Weizmann Institute of
                    Science in Israel.</span
                  >
                </p>
                <h2>
                  <span style={{fontWeight:'400'}}
                    >The University of Cyprus&nbsp;</span
                  >
                </h2>
                <p>
                  <span style={{fontWeight:'400'}}>Location: Nicosia&nbsp;</span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The University of Cyprus medical school&nbsp; also has an
                    excellent medical program for students, both at the
                    undergraduate and postgraduate level. The school prizes
                    itself for constantly updating methods of teaching and
                    theoretical medical information based on the latest
                    technology, trends and medical news. The school offers
                    medical programs in both Greek and English.&nbsp;</span
                  >
                </p>
                <h2>
                  <span style={{fontWeight:'400'}}>Near East University</span>
                </h2>
                <p><span style={{fontWeight:'400'}}>Location: Nicosia</span></p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >Finally, Near East University (NEU) Faculty of Medicine is
                    one of the best medical schools in Cyprus and was founded in
                    the year 2008. The school has an aim to provide excellent
                    medical education for bachelor’s and postgraduate levels.
                    The MD program needs 6 years to be completed and is taught
                    in the English language so is perfect for international
                    students. The program is a multidisciplinary program,
                    teaching students to make correct medical diagnoses through
                    using the latest medical knowledge.&nbsp;</span
                  >
                </p>
                <p><span style={{fontWeight:'400'}}>&nbsp;</span></p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The school has many research laboratories and a university
                    hospital so that students can gain practical training before
                    graduation. The school has the following faculties: Faculty
                    of Medicine, Faculty of Dentistry, Faculty of Pharmacy,
                    Faculty of veterinary medicine and Faculty of health
                    sciences.&nbsp;</span
                  >
                </p>
              </div>
        </>



    );
}
export default Universities;