import React from 'react';
import './index.css'
const HowToApply = () => {
  return (
    <body className='bodyCountry'>
      <div content-tab="howtoapply">
        <h2 >
          How to apply - Slovakia
        </h2>
        <p>
          You are required to complete the online application
          form<strong>, </strong>then you need to prepare some documents
          for your admission process.
        </p>
        <p>
          Here are the admission requirements to study medicine in
          Slovakia
        </p>
        <ul>
          <li>An A-Level or equivalent high school diploma</li>
          <li>Biology and chemistry entrance exam</li>
          <li>Medical certificate proving good health</li>
          <li>An English certificate for non-native speakers</li>
        </ul>
        <p>&nbsp;</p>
        <p>
          We also recommend you take a pre-med course for the best
          preparation for your admission exam to study medicine in
          Slovakia. This will definitely help you pass the exam.
        </p>
        <p>
          The moment you decide to study medicine in Slovakia, you know
          you are expected to go through a lot of hard work and many
          hours of study.
        </p>
        <p>
          However, all your efforts will be worth it in the end. After
          all, being a doctor is one of the world’s noblest and most
          rewarding professions.
        </p>
        <p>
          If you’re ready to study medicine in Slovakia, why not take a
          look at <a href="https://uni-app.com/">Uni-app</a> and earn a
          medical degree at the most prestigious universities in
          Slovakia.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </body>



  );
}
export default HowToApply;