import React from 'react';
import './index.css'

const About = () => {


  return (
    <>
      <div>
        <h2>About Medicine in Lithuania</h2>
        <p>When thinking of destinations to study medicine at, Lithuania might not be the first choice on your mind to study medicine in Lithuania. Everyone seems much more content going for the more recognizable countries in regards to educational renown such as America or the United Kingdom instead of a simple Baltic country they have likely not heard about. But don’t be quick to judge a book by its cover-Lithuania may not have the prestige and recognition of the other big countries but it can certainly give you a fulfilling and enriching education in the field of medicine. Now’s your time to consider to study medicine in Lithuania.</p>
        <h3><strong>Geography:</strong></h3>
        <p>Lithuania is one of the three Baltic states alongside Estonia and Lativa, being sandwiched in between Poland, Russia, Belarus and Lativa and lying on the eastern shores of the Baltic Sea.</p>
        <h3><strong>Religion:</strong></h3>
        <p>Much like its neighbors, the religion of Lithuania is a very clear cut thing with over 75% of the population believing in Catholic Christianity as they have all the way since 1357. They do have a minority of believers in alternate versions of Christianity such as Orthodox and Evangelical as well as believers of different religions entirely such as Islam and Hinduism, but if religion is an important thing to you and you’re not a Catholic Christian you might run into some challenges trying to stick to your faith as well as being an outsider.</p>
        <h3><strong>Language:</strong></h3>
        <p>The Lithuanians have their own language, with its unique dialect, phonology and grammar. That being said, if you are a foreign student then you can rest assured that English is one of, if not the most popular foreign language in the area with 30% of the population and a whopping 80% of the youth having at least some proficiency in the language.</p>
        <h3><strong>Tourism:</strong></h3>
        <p>Lithuania may not be one of the big shots when it comes to tourism but it can definitely hold its own, with over 1.7 million tourists visiting the country yearly for business, leisure and family matters.</p>
      </div>
    </>
  );
}
export default About;