import React from 'react';
import './index.css'

const About = () => {


    return (
       <>
          <div content-tab="about">
                <h2>
                  About Medicine in Czech Republic
                </h2>
                <p style={{fontWeight:'400'}}>
                  Medicine is one degree respected and highly valued around the
                  globe. The degree trains to master the science of human
                  anatomy and diagnose, prevent, and cure diseases. If you are
                  someone planning to study medicine in Czech Republic will
                  prove to be the best international destination. The
                  universities offer medical programs in the English language,
                  and the degrees are recognized internationally. Let us tell
                  you why you should make this decision!
                </p>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The Czech Republic is located in Central Europe, with
                    Prague as its capital. It was intermittently a part of the
                    Republic of Czechoslovakia until 1992. With an area of
                    approximately 38,000 km2 and a population of 11 million
                    people, it is bordered by Germany to the west, Slovakia to
                    the east, Germany and Poland to the north, and Austria to
                    the south. The Czech Republic has a varied natural
                    landscape, with three major rivers running through it. It
                    has a picturesque landscape with hundreds of lakes and vast
                    areas of forest, a mild climate in summer and winter, and
                    ancient historical monuments. UNESCO has designated 12
                    historical sites.</span
                  >
                </p>
                <h2>
                  <span style={{fontWeight:'400'}}
                    >Study Medicine in Czech Republic</span
                  >
                </h2>
                <p>
                  <span style={{fontWeight:'400'}}
                    >The Czech Republic is located in Central Europe, with
                    Prague as its capital. It was intermittently a part of the
                    Republic of Czechoslovakia until 1992. With an area of
                    approximately 38,000 km2 and a population of 11 million
                    people, it is bordered by Germany to the west, Slovakia to
                    the east, Germany and Poland to the north, and Austria to
                    the south. The Czech Republic has a varied natural
                    landscape, with three major rivers running through it. It
                    has a picturesque landscape with hundreds of lakes and vast
                    areas of forest, a mild climate in summer and winter, and
                    ancient historical monuments. UNESCO has designated 12
                    historical sites.</span
                  >
                </p>
                <p>&nbsp;</p>
              </div>
       </>



    );
}
export default About;