import React from 'react';
import './index.css'
import Container from "@mui/material/Container";
import img1 from '../../until/Image/PIC/germany.jpg'
import img2 from '../../until/Image/PIC/latvia-potrait.jpg'
import img3 from '../../until/Image/PIC/romania-potrait.jpg'
import img4 from '../../until/Image/PIC/Slovakiap.jpg'
import { useNavigate } from 'react-router';

const DestinationsAbroad = () => {
    const navigate = useNavigate();
    return (
        <div className='DestinationsAbroad'>
            <Container maxWidth="lg">
                {/* <h2>Other Study Destinations</h2> */}
                <h2>Medical Study Destinations Abroad</h2>
                <div className='imgCountrySearch'>
                    <div className='image-containerr' onClick={() => {
                        navigate(`/study-destinations/study-in-germany/`);
                    }}>
                        <img src={img1} alt='' />
                        <div className='overlayy'>
                            <p className='overlay-textt'>Germany</p>
                        </div>
                    </div>

                    <div className='image-containerr' onClick={() => {
                        navigate(`/study-destinations/study-in-latvia/`);
                    }}>
                        <img src={img2} alt='' />
                        <div className='overlayy'>
                            <p className='overlay-textt'>Latvia</p>
                        </div>
                    </div>

                    <div className='image-containerr' onClick={() => {
                        navigate(`/study-destinations/study-in-romania/`);
                    }}>
                        <img src={img3} alt='' />
                        <div className='overlayy'>
                            <p className='overlay-textt'>Romania</p>
                        </div>
                    </div>

                    <div className='image-containerr' onClick={() => {
                        navigate(`/study-destinations/study-in-slovakia/`);
                    }}>
                        <img src={img4} alt='' />
                        <div className='overlayy'>
                            <p className='overlay-textt'>Slovakia</p>
                        </div>
                    </div>

                </div>
                <button onClick={() => { navigate('/study-medicine-in-europe/') }}>View All</button>
            </Container>
        </div>
    );
};


// 
export default DestinationsAbroad;
