import React from 'react';
import './index.css'

const Study = () => {


    return (
        <body className='bodyCountry'>

<div content-tab="study">
                <h2>
                  Study Medicine in Slovakia
                </h2>
                <p>
                  Earning your medical degree from Slovakia is an excellent
                  choice. The country has a very fascinating culture and
                  history, which means you will learn a lot there.
                </p>
                <p>
                  Obviously, you can also enjoy its scenic beauty, as Slovakia
                  has a lot to offer in this regard. Apart from the benefits of
                  the country, maybe the most important reason to study there is
                  its national education system. Because Slovak universities are
                  among the best, you will have access to high-quality medical
                  studies, well-equipped facilities, and complete EU
                  qualification recognition.
                </p>
                <p>
                  The general medicine (GM) study program is one of the most
                  popular study programs for international students in Slovakia.
                  It lasts six years and is divided into twelve semesters. You
                  will receive the title “Doctor of General Medicine” (MUDr. ),
                  which is recognized not just in the European Union, but
                  practically everywhere in the world.
                </p>
                <p>
                  The graduate will have significant theoretical knowledge of
                  pre-clinical and clinical areas, as the goal of practical
                  training is to learn fundamental preventive, diagnostic, and
                  therapeutic methods.
                </p>
                <p>
                  If you are keen to study medicine in Slovakia, Uni-app will
                  get you there, since it is the ultimate choice to earn the
                  medical degree you want.
                </p>
              </div>
        </body>



    );
}
export default Study;