import React from 'react';
import './index.css'

const About = () => {


    return (
        <body className='aboutSection'>
      <div content-tab="about" class="single-tab-details tab-active">
                <h2 class="single-tab-details-title">
                  About Medicine in Slovakia
                </h2>
                <p>
                  The educational system in Slovakia is considered to be
                  outstanding when compared to other countries. This is because
                  most of the schools and universities in Slovakia are owned by
                  the state
                </p>
                <p>
                  The education system in Slovakia is also divided into
                  different types of schools. Most of the schools are public,
                  private, and international schools. The public schools are
                  free of cost and the legislation of an international school is
                  usually standardized. But on the other hand, the private
                  schools charge fees and the standard of education depends on
                  the management and quality of teachers.
                </p>
                <p>
                  Slovakia is located in central Europe, and became an
                  independent country in 1993, following the dissolution of
                  Czechoslovakia.
                </p>
                <p>
                  Since 2004, the country has been a member of the European
                  Union. Bratislava is Slovakia’s capital and largest city. It
                  hosts the Parliament, universities, museums, theatres, and
                  other significant intellectuals, artistic, and industrial
                  institutions.
                </p>
                <p>
                  There are a lot of prestigious universities in Slovakia that
                  offer medical degree programs in English to international
                  students.
                </p>
                <p>
                  The international environment at universities is ideal for
                  preparing you for your future career. Since Slovakia has a low
                  cost of living, you will enjoy a very comfortable life there.
                </p>
                <p>
                  Cities and old towns in Slovakia, such as Bratislava and
                  Kosice, are very attractive, with narrow streets, charming
                  squares, and historical structures. You can also visit many
                  other countries while living in Slovakia, such as Hungary, the
                  Czech Republic, or Poland. It is worthwhile to study medicine
                  in Slovakia.
                </p>
                <p>&nbsp;</p>
                <p>
                  If you live in other parts of the world, you will probably
                  wonder about Slovakia which is fine. With uni-app, you can
                  find out more about the medical programs available in
                  Slovakia.
                </p>
              </div>
        </body>



    );
}
export default About;