import React from 'react';
import './index.css'

const Study = () => {


  return (
    <>
      <div content-tab="study">
        <h2>
          Study Medicine in Romania
        </h2>
        <p>
          Studying medicine in Romania is one of the best options in
          Europe and offers an excellent quality of education in
          English, French or Romanian language, a rich cultural
          heritage, affordable tuition, and living expenses.
        </p>
        <p>
          Romania follows the Bologna system, which means that most of
          its tertiary-level programs are divided into three cycles: a
          three-year bachelor’s degree, a two-year master’s degree, and
          a three-year doctoral degree. However, some programs take
          longer to finish, such as medicine which takes six-year
          programs.
        </p>
        <p>
          Studying medicine in Romania is one of the greatest options
          for aspiring international medical students for so many
          reasons:
        </p>
        <ul>
          <li>Guaranteed admission to medical school in Romania</li>
          <li>No entry exams</li>
          <li>Affordable tuition fees</li>
          <li>Reasonable living expenses</li>
          <li>Multicultural culture</li>
          <li>Excellent research quality</li>
          <li>Availability of financial aid</li>
          <li>ECTS credit transfer</li>
          <li>Recognized diploma in Europe.</li>
        </ul>
        <p>
          There are several medical universities in Romania that offer
          medical programs in English. This enables international
          students to study medicine in Romania and earn recognized
          degrees throughout the EU and in many other countries around
          the world.
        </p>
        <p>
          <a
            href="https://uni-app.com/search?discipline=Health%20%26%20Medicines"
          >Uni-app</a
          >
          is here to help you secure your spot to study medicine in
          Romania, and ensure that you begin your medical studies at the
          university of your dream.
        </p>
      </div>
    </>


  );
}
export default Study;