import React , {useEffect} from 'react';
import Container from "@mui/material/Container";
import WOW from 'wowjs';
import img1 from '../../../until/Image/PIC/nikosia.jpg';
import CyprusContent from './CyprusContent/index';
import DestinationsAbroad from '../DestinationsAbroad';
import { useNavigate } from 'react-router';

const Cyprus = () => {

  const navigate = useNavigate();
  const filterData = (name) => {
    navigate(`/search/?institute=${name}`);
  }

  useEffect(()=>{
    const wow = new WOW.WOW();
    wow.init();
    window.scrollTo(0, 0)
  },[])
  const data = [
    {
      image: img1,
      cityName: "University of Nicosia",
      location: 'Nikosia, Cyprus'
    },
  ];

  return (
    <div className='wow fadeInDown' data-wow-duration='1s'>

      <div className="headerCity">
        <Container maxWidth="lg">
          <div>
            <h1>Study Medicine in Cyprus</h1>
          </div>
        </Container>
      </div>
      <Container maxWidth="lg">
        <div className='contactCity'>
          <h2>Medical Universities in Cyprus</h2>
        </div>
        <div className='cityImage'>
          {data.map((ele, index) => (
            <div key={index} style={{ position: 'relative', width: '250px', maxWidth: '320px' }} onClick={() => {
              filterData(ele.cityName)
            }}>
              <img src={ele?.image} alt="Image" style={{ width: '100%', height: 'auto' }} />
              <div style={{ cursor: 'pointer', position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: '10px', color: '#fff' }}>
                <h3 style={{ marginBottom: '0', fontSize: '14px' }}>{ele?.cityName}</h3>
                <h4 style={{ marginTop: '5px', fontSize: '12px' }}>{ele?.location}</h4>
              </div>
            </div>
          ))}
        </div>
      </Container>
      <CyprusContent/>
      <DestinationsAbroad/>
    </div>
  );
};

export default Cyprus;
