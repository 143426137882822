import React from 'react';
import './index.css'

const HowToApply = () => {


    return (
      <>
        <div content-tab="howtoapply">
                <h2>How to apply - Cyprus</h2>
                <p>
                  <span style={{fontWeight:'400'}}
                    >In order to be able to apply to study medicine in Cyprus, </span
                  ><a href="https://uni-app.com/"
                    ><span style={{fontWeight:'400'}}>Uniapp</span></a
                  ><span style={{fontWeight:'400'}}>
                    can make it easier for you by simplifying the application
                    process! There are typically certain requirements for
                    admission, both general requirements and more specific ones
                    required at the graduate level. You can also find a good
                    scholarship to support your journey to study medicine in
                    Cyprus. Read on to learn more about the topic of application
                    to medical school in Cyprus.&nbsp;</span
                  >
                </p>
                <h2>
                  <span style={{fontWeight:'400'}}
                    >General Entry Requirements</span
                  >
                </h2>
                <p>
                  <span style={{fontWeight:'400'}}
                    >Here are some of the general entry requirements you might
                    need to hand in when applying to study medicine in
                    Cyprus:</span
                  >
                </p>
                <ul>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >High School Diploma, with a focus on science and math
                      subjects. The applicant needs to have scored a score of at
                      least 90 per cent GCE A’ Levels with grades ABB, including
                      Biology and one of either Chemistry, Physics or Maths, and
                      one additional subject, or International Baccalaureate
                      grades of 32 overall and a combined score of 16 at Higher
                      Level. Subjects should also include Biology and at least
                      one of subjects of Chemistry, Physics or Maths.</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >Proof of English Proficiency (either a cutoff score of
                      6.5 overall in the IELTS, or 213 overall in the
                      computer-based TOEFL, or grade 5 in the GCSE, or a score
                      of 5 in English in the International Baccalaureate
                      Standard Level (SL),or a score of 70% in English in the
                      European Baccalaureate).</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >Passing an interview, either in person or online
                      depending on the program&nbsp;</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >A personal statement written in English, stating why you
                      want to be a doctor and what you have done to learn more
                      about the career path.</span
                    >
                  </li>
                </ul>
                <h2>
                  <span style={{fontWeight:'400'}}>Graduate requirements</span>
                </h2>
                <p>
                  <span style={{fontWeight:'400'}}
                    >To join the 4 year graduate program to study medicine in
                    Cyprus, you’ll need to have fulfilled the following
                    requirements:&nbsp;</span
                  >
                </p>
                <p><span style={{fontWeight:'400'}}>&nbsp;</span></p>
                <ul>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >A Bachelor’s degree with score of a 2nd class honours or
                      equivalent or a higher degree (like MSc, MPhil or PhD) in
                      any subject that’s related to medicine.</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >Professional or voluntary work experience in medicine or
                      health field</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >Proof of English language proficiency If English was not
                      the primary language of bachelor’s degree, in the form of
                      certificate of IELTS (International English Language
                      Testing System) or equivalent certificates (such as
                      TOEFL)</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >Medical certificate proving good health, including a
                      screen proving immunisation against hepatitis
                      B.&nbsp;</span
                    >
                  </li>
                  <li style={{fontWeight:'400'}} aria-level="1">
                    <span style={{fontWeight:'400'}}
                      >Police certificate proving good conduct.</span
                    >
                  </li>
                </ul>
              </div>
      </>


    );
}
export default HowToApply;