import React from 'react';
import './index.css'

const Universities = () => {


    return (
      <div content-tab="university">
      <h2 >
        Universities in Romania
      </h2>
      <p>Here are the top medical universities in Romania:</p>
      <ol>
        <li>
          <strong>
            Carol Davila University of Medicine and Pharmacy</strong
          >
        </li>
      </ol>
      <p>
        Carol Davila University of Medicine and Pharmacy is one of the
        oldest universities in Romania. It is also known as the
        University of Medicine and Pharmacy Bucharest.
      </p>
      <p>
        It was founded in 1694 as St. Sava College before becoming
        UMFCD in 1857. A decade later, in 1867, it opened its Faculty
        of Medicine.
      </p>
      <p>
        The medical program, taught in English, is divided into two
        parts: pre-clinical and clinical education. The first part
        includes studies in functional, morphological, and related
        sciences.
      </p>
      <p>
        Clinical education is taught by several departments, including
        medical semiology, infectious disease, internal medicine,
        clinical neuroscience, pediatrics, radiography, rehabilitation
        medicine, surgery, ophthalmology, orthopedics, ENT, and
        obstetrics-gynecology.
      </p>
      <p>Every year, the university only accepts 300 applications.</p>
      <p>&nbsp;</p>
      <h3>
        <strong
          >2. Victor Babeș University of Medicine and Pharmacy</strong
        >
      </h3>
      <p>
        The university, popularly known as UMF Timișoara, is a public
        institution founded in 1945. It is named after Victor Babeș,
        one of the fathers of modern microbiology.
      </p>
      <p>
        It is one of the rare medical institutions that offer courses
        in three languages: English, French, and Romanian.
      </p>
      <p>
        However, if you decide to study medicine in Romania, you can
        easily apply to Victor Babeș University of Medicine and
        Pharmacy through
        <a href="https://uni-app.com/search">uni-app</a>, as there are
        only 15 spots available for the English program and 30 for the
        French course at this university.
      </p>
      <p>
        The pre-clinical cycle includes anatomy, biology, histology,
        biophysics, and biochemistry. Unlike other colleges, this
        phase involves practical training in neuroscience and first
        aid.
      </p>
      <p>
        Meanwhile, the clinical phase focuses on the medical and
        surgical fields. In order to create well-rounded doctors, the
        program includes classes in the social sciences, sports, and
        foreign languages.
      </p>
      <p>&nbsp;</p>
      <h3>
        <strong
          >3. Grigore T. Popa University of Medicine and
          Pharmacy</strong
        >
      </h3>
      <p>
        Grigore T. Popa University of Medicine and Pharmacy is a
        public medical school in Moldavia. known as UMF Iași, it was
        founded in 1979, and it is now acknowledged as a university of
        advanced research and education.
      </p>
      <p>
        For overseas students, the university provides a 6-year
        medical program in English, French, and Romanian. Yet the
        admission process is competitive due to the school’s excellent
        international academic positioning, with limited spots to 200
        each year.
      </p>
      <p>
        The 360-credit course, to study medicine in Romania, is
        supported by immersions in laboratories, research institutes,
        and university hospitals. Clinical rotations are available in
        the departments of pediatrics, obstetrics and gynecology,
        dermatology, surgery, psychiatry, family medicine, and public
        health.
      </p>
      <h3><strong>&nbsp;</strong></h3>
      <h3>
        <strong
          >4. Luliu Hațieganu University of Medicine and Pharmacy,
          Cluj-Napoca</strong
        >
      </h3>
      <p>&nbsp;</p>
      <p>
        Luliu Hațieganu University of Medicine and Pharmacy, or UMF
        Cluj, is a governmental medical school in northwestern
        Romania. It was founded in 1919 as the Superior Dacla
        University. It’s known as one of the country’s most
        distinguished universities.
      </p>
      <p>
        It is the only medical school recognized by the Association of
        Medical Schools in Europe. In addition, it has received the
        Quality Certificate from the National Conference of
        Francophone Faculties of Medicine.
      </p>
      <p>
        Once you decide to study medicine in Romania, you now have the
        opportunity to enroll in the University of Medicine and
        Pharmacy, Cluj-Napoca via
        <a href="https://uni-app.com/search">uni-app</a>, and make
        your dream degree come true.
      </p>
      <h3><strong>&nbsp;</strong></h3>
      <h3>
        <strong
          >5. Târgu Mureș University of Medicine, Pharmacy, Science,
          and Technology</strong
        >
      </h3>
      <p>
        It is one of six traditional medical schools in Romania and it
        is located in Târgu Mureș. It is Known as UMFST and it was
        established in 1945.
      </p>
      <p>
        UMFST is one of the newest universities to offer an
        English-language program to study medicine in Romania. The
        program is also divided into pre-clinical and clinical parts.
        Yet foreign students must take Romanian classes as part of
        their study.
      </p>
      <p>
        UMFST remains a popular choice among many candidates because
        it is one of the fewest schools that offers an international
        student scholarship.
      </p>
      <p>&nbsp;</p>
    </div>



    );
}
export default Universities;