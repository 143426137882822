import React, { useEffect, useState } from 'react';
import { Container, TextField } from '@mui/material';
import './index.css';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router';
import CheckIcon from '@mui/icons-material/Check';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';

const ApplyForABachelors = ({ flag }) => {
    const navigate = useNavigate();
    const [studyTopic, setStudyTopic] = useState('');
    const [dataCountry, setDataCountry] = useState([]);

    const handleStudyTopicChange = (event) => {
        setStudyTopic(event.target.value);
    };
    const inputStyle = {
        backgroundColor: '#fff',
        borderRadius: '18px',
    };

    const headingStyle = {
        color: 'blue',
        fontSize: '16px',
        marginBottom: '10px',
    };

    const demoArray = [
        "Master",
        "Bachelor",
        "MBA",
        'PhD',
        'Diploma',
        'Staatsexamen',
        "Foundation",
        "Germany",
        'Spanish',
        "United States",
        'United Kingdom',
        "France",
        'Social Science',
        "Humanities",
        "Law",
    ];

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');


    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const handleCategoryClick = (category) => {
        const updatedCategories = selectedCategories.includes(category)
            ? selectedCategories.filter((c) => c !== category)
            : [...selectedCategories, category];

        setSelectedCategories(updatedCategories);
    };

    const sendData = () => {
        const filterCategories = (selectedCategories, validCategories) => {
            return selectedCategories
                .filter(category => validCategories.includes(category))
                .join(',');
        };
        
        const countryCategories = filterCategories(selectedCategories, ["Germany", "United States", "France", 'United Kingdom', 'Spanish']);
        const courseCategories = filterCategories(selectedCategories, ["Master", "Bachelor", "MBA", 'Staatsexamen', 'PhD', 'Diploma']);
        const disciplineCategories = filterCategories(selectedCategories, ["Law", "Humanities", 'Social Science']);
        
        let urlParams = [];
        
        if (studyTopic) {
            urlParams.push(`q=${studyTopic}`);
        }
        
        if (countryCategories) {
            urlParams.push(`country=${countryCategories}`);
        }
        
        if (selectedValue) {
            urlParams.push(`country=${selectedValue}`);
        }
        
        if (courseCategories) {
            urlParams.push(`course=${courseCategories}`);
        }
        
        if (disciplineCategories) {
            urlParams.push(`discipline=${disciplineCategories}`);
        }
        const url = urlParams.length > 0 ? `?${urlParams.join('&')}` : '';
        navigate(`/search/${url}`);
        
    };



    const dataFilter = async () => {
        try {
            const res = await axios.get('https://api.uni-app.com/courses/filters?officialOnly=true');
            setDataCountry(Object.keys(res.data.country))
        } catch (err) {
            console.log('error when filter data ', err.message);
        }
    }



    useEffect(() => {
        dataFilter();
    }, [])



    return (
        <body className='bodyApplyForABachelors'>
            <Container maxWidth="lg">
                <div className='box'>
                    {/* <h2>Apply for a Bachelors, Masters, or Postgraduate Degree</h2> */}

                    {flag && <>
                        <h2>Apply for a Bachelors, Masters, or Postgraduate Degree</h2>
                        <div className='inputs'>
                            <TextField
                                className='input1'
                                placeholder='What do you want to study'
                                InputProps={{ style: { ...inputStyle } }}
                                size='small'
                                value={studyTopic}
                                onChange={handleStudyTopicChange}
                            />
                            <FormControl className='input2' style={{ backgroundColor: '#fff', borderRadius: '18px' }}>
                                <InputLabel style={{ color: "#acacac", marginTop: '-7px' }} id="select-label">Where do you want to study</InputLabel>
                                <Select
                                
                                    style={{ borderRadius: '18px' }}
                                    size='small'
                                    value={selectedValue}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'Select a value' }}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        PaperProps: {
                                            style: {
                                                maxHeight: '300px',
                                                borderRadius:'18px'
                                            },
                                        },
                                    }}
                                >
                                    {dataCountry.map((ele) => (
                                        <MenuItem key={ele} value={ele}>
                                            {ele}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </div>
                    </>}


                    {!flag && <>
                        <h3 style={{color:"#fff"}}> Apply for a Bachelors, Masters, or Postgraduate Degree</h3>
                        <div style={{display:'flex' , flexDirection:'column'}}>
                            <TextField
                                className='input1'
                                style={{marginBottom:'15px'}}
                                placeholder='What do you want to study'
                                InputProps={{ style: { ...inputStyle } }}
                                size='small'
                                value={studyTopic}
                                onChange={handleStudyTopicChange}
                            />
                            <FormControl className='input2' style={{ backgroundColor: '#fff', borderRadius: '18px' }}>
                                <InputLabel style={{ color: "#acacac", marginTop: '-7px' }} id="select-label">Where do you want to study</InputLabel>
                                <Select
                                    style={{ borderRadius: '18px' }}
                                    size='small'
                                    value={selectedValue}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'Select a value' }}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        PaperProps: {
                                            style: {
                                                maxHeight: '300px',
                                                borderRadius:'18px'
                                            },
                                        },
                                    }}
                                >
                                    {dataCountry.map((ele) => (
                                        <MenuItem key={ele} value={ele}>
                                            {ele}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </div>
                    </>}

                    {flag && <>

                        <h2 className='nameOfCategory' style={headingStyle}>
                            For Quick Search
                        </h2>
                        <div className="categories-container">
                            {demoArray.map((ele, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleCategoryClick(ele)}
                                >
                                    <p className={`category ${selectedCategories.includes(ele) ? 'selected' : 'not-selected'}`}>
                                        <span>{ele}</span>
                                        <span>{selectedCategories.includes(ele) && <CheckIcon style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '15px' }} />}</span>
                                    </p>
                                </div>
                            ))}
                        </div>

                    </>}
                    <div className='optionn'>
                        <span onClick={sendData}>Start </span>
                        <span onClick={() => {
                            navigate('/search/')

                        }}>Or View All Programs</span>


                    </div>
                </div>
            </Container>
        </body>
    );
};

export default ApplyForABachelors;
