import React, { useState, useEffect } from 'react';
import './index.css';
import { Container } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WOW from 'wowjs';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';


import { useNavigate } from 'react-router';

const AllCountries = () => {
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);

    const getAllCountries = async () => {
        try {
            const res = await axios.get('https://api.uni-app.com/destinations?page=0&size=103');
            setCountries(res.data.content);
        } catch (err) {
            console.log('Error When get all countries', err.message);
        } finally {
            setLoading(false);
        }
    };

    const handelPage = (ele) => {
        navigate(`/study-destinations/${ele}/`)
    }
    useEffect(() => {
        getAllCountries();
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='wow fadeInDown' data-wow-duration='1s'>
            <div className="parentTheCountry">
                <div className="backgroundImageMainCountry"></div>
                <div className="CountryOverlay"></div>
                <Container maxWidth="lg">
                    <div className="CountryTextt">
                        <h1>Find Bachelor's and Master's Degrees Worldwide</h1>
                    </div>
                </Container>
            </div>

            <Container maxWidth="lg">
                <div className='city'>
                    {loading ? (

                        <CircularProgress />

                    ) : (
                        countries.map((ele, index) => (
                            <div key={index} onClick={() => handelPage(ele.postName)}>
                                <p>{ele.title}</p>
                                <ArrowForwardIosIcon className='countriesIcon' />
                            </div>
                        ))
                    )}
                </div>
            </Container>
        </div>
    );
}
export default AllCountries;