import React from 'react';
import './index.css'

const About = () => {


  return (
    <>
      <div content-tab="about">
        <h2 >
          About Medicine in Romania
        </h2>
        <p>
          Romania is one of the best countries to study medicine in
          Europe. There are plenty of affordable universities and a good
          job market for newly graduated doctors.
        </p>
        <p>
          Studying medicine in Romania is a great step to take if you
          want to obtain a degree in a short time, you will work in a
          multicultural environment and be able to experience a
          different culture.
        </p>
        <p>
          If you want to study medicine abroad but you haven’t decided
          yet which school to choose, you should definitely consider
          Romania as your study destination.
        </p>
        <p>
          Since 2010, the economic growth of Romania has been among the
          greatest in the EU, as its current GDP growth rate has also
          increased the demand for quality education and international
          studies.
        </p>
        <p>
          Currently, Romania focuses on investments in infrastructure,
          healthcare, education, job creation, and the development of
          small and medium-sized businesses.
        </p>
        <p>
          Romania is well known for being the preferred destination for
          international medical students.
        </p>
      </div>
    </>
  );
}
export default About;