import React, { useEffect } from 'react';
import './index.css';
import Header from "./Header"
import CarouselCard from '../SchoolScreen/component/Carousel';
import Ainternational from './Ainternational';
import CEO from './CEO';
import WOW from 'wowjs';
import AllPlatForm from './AllPlatForm';
import ShareCom from '../ShareCom';
import { Container } from '@mui/material';


const StudyAgents = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
    const wow = new WOW.WOW();
    wow.init();
  }, [])
  return (
    <div className='wow fadeInDown' data-wow-duration='1.5s'>
      <Header />
      <Ainternational />
      <CEO />
      <CarouselCard />
      <AllPlatForm />
      <div className='shareComCont'>
        <Container maxWidth="lg">
          <div className='sideMain'>
           <p>SCALE YOUR <span className='title'> RECRUITMENT </span> ACTIVITIES</p>
            <h1 className='titleHeader'> Become an Uni-App Recruitment partner</h1>
            <div className='btnStyle'>
              <span onClick={() => {
                window.location.href = 'https://my.uni-app.com/sign-in?redirectURL=%2Fhome';

              }}>
                Partner with us
              </span>
            </div>
          </div>
        </Container >
      </div >



    </div >
  );
}
export default StudyAgents;