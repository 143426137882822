import React from 'react';
import './index.css';
import { Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import img1 from '../../../../until/Image/PNG/complete-your-profile.png'
import img2 from '../../../../until/Image/PNG/explore-study-programs.png'
import img3 from '../../../../until/Image/PNG/apply-to-school.png'
const UniWork = () => {
    return (
        <div style={{ padding: '100px 0px' }}>

            <Container maxWidth="lg">
                <p style={{ textAlign: 'center', color: "#696969", fontWeight: "500" , margin:'0' }}>IN EASY STEPS</p>
                <h1 style={{ textAlign: 'center' , fontSize:'34px' , marginTop:'0' ,color:'#333' }}>How  <span className='title'> Uni-App </span>  Works</h1>
                <div className='cardBox'>
                    <div className='cardWork'>
                        <img src={img1} alt='Image 1' />
                        <h2>Complete Your Profile</h2>
                        <p>After completing your UniApp profile, you will be able to apply to multiple programs</p>
                        <div>
                            <div className='link'>
                                <a onClick={() => { window.location.href = 'https://my.uni-app.com/sign-in?redirectURL=%2Fhome' }} style={{ fontWeight: 'bold' }}> Create Account </a>
                                <ArrowForwardIcon style={{ fontSize: '18px', marginLeft: '10px' }} />
                            </div>
                        </div>
                    </div>
                    <div className='cardWork'>
                        <img src={img2} alt='Image 2' />
                        <h2> Explore Study Programs</h2>
                        <p>
                            Receive all the information in advance of any preferred study programs
                        </p>                        <div className='link'>
                            <a onClick={() => { window.location.href = 'https://my.uni-app.com/sign-in?redirectURL=%2Fhome' }} style={{ fontWeight: 'bold' }}>Explore Program </a>
                            <ArrowForwardIcon style={{ fontSize: '18px', marginLeft: '10px' }} />
                        </div>
                    </div>
                    <div className='cardWork'>
                        <img src={img3} alt='Image 3' />
                        <h2>Apply to School</h2>
                        <p> Select your preferred destination, school and degree. Then click on Apply now!</p>
                        <div className='link'>
                            <a onClick={() => { window.location.href = 'https://my.uni-app.com/sign-in?redirectURL=%2Fhome' }} style={{ fontWeight: 'bold' }}>Get Started </a>
                            <ArrowForwardIcon style={{ fontSize: '18px', marginLeft: '10px' }} />
                        </div>
                    </div>
                </div>
            </Container>


        </div>
    );
}
export default UniWork;