import React, { useState, useEffect } from 'react';
import { Container, TextField } from '@mui/material';
import './index.css';
import { useNavigate } from 'react-router';
import WOW from 'wowjs';
const FilterOnline = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0)
    }, [])

    const handleClick = () => {
        navigate('/search/')
    };

    return (
        <body className='degreeSection'>
            <Container maxWidth='lg'>
                <div className='degreesBy'>
                    <div>
                        <h2>Browse By Country</h2>
                        <hr style={{ width: '100%', borderTop: '1px solid #EEE', marginTop: '-30px', marginBottom: '25px' }} />

                        <p onClick={() => { navigate('/search/?attendance=Online&country=United%20States') }} className='degreesText'>Study in United States</p>
                        <p onClick={() => { navigate('/search/?attendance=Online&country=United%20Kingdom') }} className='degreesText'>Study in United Kingdom</p>
                        <p onClick={() => { navigate('/search/?attendance=Online&country=Germany') }} className='degreesText'>Study in Germany</p>
                        <p onClick={() => { navigate('/search/?attendance=Online&country=France') }} className='degreesText'>Study in France</p>
                        <p onClick={() => { navigate('/search/?attendance=Online&country=Netherlands') }} className='degreesText'>Study in Netherlands</p>
                        <p onClick={() => { navigate('/search/?attendance=Online&country=Canada') }} className='degreesText'>Study in Canada</p>
                        <p onClick={() => { navigate('/search/?attendance=Online&country=New%20Zealand') }} className='degreesText'>Study in New Zealand</p>
                        <p onClick={() => { navigate('/search/?attendance=Online&country=Australia') }} className='degreesText'>Study in Australia</p>
                        <p onClick={() => { navigate('/search/?attendance=Online&country=Ireland') }} className='degreesText'>Study in Ireland</p>
                        <p onClick={() => { navigate('/search/?attendance=Online&country=Ireland,United%20Kingdom,Germany,Netherlands,France,Sweden,Spain,Italy,Denmark,Finland,Turkey,Switzerland,Lithuania,Belgium,Austria,Czech%20Republic,Hungary,Latvia,Estonia,Portugal,Norway,Poland,Cyprus,Greece,Northern%20Cyprus,Romania,Bulgaria,England,Luxembourg,Slovakia,Croatia') }} className='degreesText'>Study in Europe</p>


                        <button className='submitCard' onClick={handleClick}> Start </button>

                    </div>

                    <div>
                        <h2>Browse by University</h2>
                        <hr style={{ width: '100%', borderTop: '1px solid #EEE', marginTop: '-30px', marginBottom: '25px' }} />
                        <p onClick={() => { navigate('/search/?institute=Arden%20University&attendance=Online') }} className='degreesText'>Arden University</p>
                        <p onClick={() => { navigate('/search/?institute=BSBI%20Berlin%20School%20of%20Business%20and%20Innovation&attendance=Online') }} className='degreesText'>BSBI Berlin School of Business and Innovation</p>
                        <p onClick={() => { navigate('/search/?institute=eu%20Business%20School&attendance=Online') }} className='degreesText'>eu Business School</p>
                        <p onClick={() => { navigate('/search/?institute=IU%20International%20University%20of%20Applied%20Sciences&attendance=Online') }} className='degreesText'>IU International University of Applied Sciences</p>
                        <p onClick={() => { navigate('/search/?institute=Munich%20Business%20School&attendance=Online') }} className='degreesText'>Munich Business School</p>
                        <p onClick={() => { navigate('/search/?institute=Paris%20School%20of%20Business&attendance=Online') }} className='degreesText'>Paris School of Business</p>
                        <p onClick={() => { navigate('/search/?institute=SRH%20University%20of%20Applied%20Science&attendance=Online') }} className='degreesText'>SRH University of Applied Science</p>
                        <p onClick={() => { navigate('/search/?institute=Schiller%20International%20University&attendance=Online') }} className='degreesText'>Schiller International University</p>
                        <p onClick={() => { navigate('/search/?institute=CBS%20International%20Business%20School&attendance=Online') }} className='degreesText'>CBS International Business School</p>
                        <p onClick={() => { navigate(`/search/?institute=London%20School%20of%20Business%20and%20Finance&attendance=Online`) }} className='degreesText'>London School of Business and Finance</p>
                        <button className='submitCard' onClick={handleClick}> Start </button>
                    </div>


                    <div>
                        <h2>Browse by Discipline</h2>
                        <hr style={{ width: '100%', borderTop: '1px solid #EEE', marginTop: '-30px', marginBottom: '25px' }} />
                        <p onClick={() => { navigate('/search/?discipline=Business%20%26%20Management&attendance=Online') }} className='degreesText'>Business & Management studies</p>
                        <p onClick={() => { navigate('/search/?discipline=Humanities&attendance=Online') }} className='degreesText'>Humanities studies</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Engineering%20%26%20Technology') }} className='degreesText'>Engineering & Technology studies</p>
                        <p onClick={() => { navigate('/search/?discipline=Arts%2C%20Design%20%26%20Architecture&attendance=Online') }} className='degreesText'>Art, Design & Architecture studies</p>
                        <p onClick={() => { navigate('/search/?discipline=Social%20Sciences&attendance=Online') }} className='degreesText'>Social Science studies</p>
                        <p onClick={() => { navigate('/search/?discipline=Computer%20Science%20%26%20IT&attendance=Online') }} className='degreesText'>Computer Science & IT studies</p>
                        <p onClick={() => { navigate('/search/?discipline=Natural%20Science%20%26%20Mathematics&attendance=Online') }} className='degreesText'>Natural Science & Mathematics studies</p>
                        <p onClick={() => { navigate('/search/?discipline=Law&attendance=Online') }} className='degreesText'>Law Studies</p>
                        <p onClick={() => { navigate(`/search/?discipline=Environment%20%26%20Earth%20Sciences&attendance=Online`) }} className='degreesText'>Environment & Earth Science studies</p>
                        <p onClick={() => { navigate('/search/?discipline=Hospitality%2C%20Leisure%20%26%20Sports&attendance=Online') }} className='degreesText'>Hospitality, Leisure & Sport studies</p>

                        <button className='submitCard' onClick={handleClick}> Start </button>


                    </div>

                    <div>
                        <h2>Browse by Degree</h2>
                        <hr style={{ width: '100%', borderTop: '1px solid #EEE', marginTop: '-30px', marginBottom: '25px' }} />
                        <p onClick={() => { navigate('/search/?q=Computer%20Engineering&attendance=Online') }} className='degreesText'>Computer Engineering</p>
                        <p onClick={() => { navigate('/search/?q=Human%20Engineering&attendance=Online') }} className='degreesText'>Human Engineering</p>
                        <p onClick={() => { navigate('/search/?q=Electrical%20Engineering%20and%20Computer%20Science&attendance=Online') }} className='degreesText'>Electrical Engineering and Computer Science</p>
                        <p onClick={() => { navigate('/search/?q=Applied%20Economics%20and%20Management&attendance=Online') }} className='degreesText'>Applied Economics and Management</p>
                        <p onClick={() => { navigate('/search/?q=Operations%20Research&attendance=Online') }} className='degreesText'>Operations Research</p>
                        <p onClick={() => { navigate(`/search/?q=Political%20Economy&attendance=Online`) }} className='degreesText'>Political Economy</p>
                        <p onClick={() => { navigate('/search/?q=Actuarial%20Mathematics&attendance=Online') }} className='degreesText'>Actuarial Mathematics</p>
                        <p onClick={() => { navigate('/search/?q=Electrical%20Power%20Engineering&attendance=Online') }} className='degreesText'>Electrical Power Engineering</p>
                        <p onClick={() => { navigate('/search/?q=Business%20Analysis&attendance=Online') }} className='degreesText'>Business Analysis</p>
                        <p onClick={() => { navigate('/search/?q=Pharmacy&attendance=Online') }} className='degreesText'>Pharmacy</p>

                        <button className='submitCard' onClick={handleClick}> Start </button>

                    </div>
                </div>
            </Container>
        </body>
    );
}

export default FilterOnline;
