import React from 'react';
import './index.css'

const About = () => {


  return (
    <>
      <div content-tab="about">
        <h2>
          About Medicine in Cyprus
        </h2>
        <p>
          <span style={{fontWeight:'400'}}
          >Cyprus isn’t one of the typical study destinations you
            think of if you want to study medicine. However, choosing to
            study medicine in Cyprus is a great idea if you want to
            study a degree in English at top level education. Cyprus is
            known for it’s amazing scenery and rich culture, with some
            of the best archaeological heritage worldwide. It also has a
            vibrant atmosphere that draws many students and tourists to
            the country.</span
          >
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{fontWeight:'400'}}
          >Cyprus has some of the leading medical universities in the
            region, making it one of the top medical study destinations
            worldwide. If you want to study medicine in Cyprus, it’ll
            take you six years to complete your degree. After
            graduation, you’ll receive the MD title and be able to start
            working as a physician.&nbsp;</span
          >
        </p>
        <h3>
          <span style={{fontWeight:'400'}}
          >How much does it cost to study medicine in Cyprus?</span
          >
        </h3>
        <p>
          <span style={{fontWeight:'400'}}
          >In general, choosing to study medicine in Cyprus is cheaper
            than in other countries that offer a medical degree in
            English but it is still pretty pricey. For example, tuition
            fees for the first three years of medical school are €18,000
            euros and €22,000 for the next three years. You’ll also need
            to pay €6,000 a year while studying the last two years, if
            you choose to do your clinical training in the UK, which is
            what many students studying medicine in Cyprus choose to
            do.&nbsp;</span
          >
        </p>
        <p>&nbsp;</p>
        <p>
          <span style={{fontWeight:'400'}}
          >When it comes to accommodation, there are a lot of options
            for students choose from. Renting a house can cost between
            250 to 700 euros a month, varying based on where you choose
            to live. Other living expenses may cost you between 150 and
            250 euros a month, depending on your lifestyle and
            habits.</span
          >
        </p>
      </div>
    </>
  );
}
export default About;