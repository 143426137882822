import React, { useEffect } from 'react';
import './index.css';
import Header from './component/Header';
import Studentsworldwide from './component/Studentsworldwide';
import CarouselCard from "./component/Carousel"
import UniAppPlatform from './component/UniAppPlatform';
import WOW from 'wowjs';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router';
const SchoolScreen = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <div className='wow fadeInDown' data-wow-duration='1s'>
      <Header />
      <Studentsworldwide />
      <CarouselCard />
      <UniAppPlatform />

      <div className='shareComCont'>
        <Container maxWidth="lg">
          <div className='sideMain'>
            <p>Your Complete <span className='title'> Student Application </span> Management</p>
            <h1 className='titleHeader'>A 360 Digital Solution Used By Education Institutions Worldwide</h1>
            <div className='btnStyle'>
              <span onClick={() => {
                navigate('/contact-us/')
              }}>
                Partner with us
              </span>
            </div>
          </div>
        </Container >
      </div >
    </div>
  );
}

export default SchoolScreen;