import React, { useState, useEffect } from 'react';
import { CircularProgress, Container } from '@mui/material';
import axios from 'axios';
import WOW from 'wowjs';
import './index.css';
import { useParams } from 'react-router-dom';
import LeftSide from '../MastersDegree/component/LeftSide';
const FullViewArticles = () => {
    const { postName } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false)

    const getByPostName = async () => {
        try {
            const url = `https://api.uni-app.com/articles/${postName}`
            const res = await axios.get(url);
            setData(res.data)
            setLoading(true)
        } catch (err) {
            console.log('error when get articles by postName ', err.message);
        }
    }
    useEffect(() => {
        getByPostName();
        const wow = new WOW.WOW();
        wow.init();
        window.scrollTo(0, 0);
    }, [postName])

    return (
        <div className='MastersDegreeBody'>
            {loading ? <Container maxWidth="lg">
                <div className='childMastersDegreeBody'>
                    <div className='wow fadeInDown' data-wow-duration='1s'>
                        <img src={data?.articleImage} alt='' />
                        <p style={{ color: "#696969", fontSize: '18px' }}>{data?.category.toUpperCase()}</p>
                        <p style={{ color: "#333", fontSize: '34px' }}>{data?.title}</p>
                        <div className='articlesContact' dangerouslySetInnerHTML={{ __html: data?.content }} />
                    </div>
                    <div>
                        <LeftSide />
                    </div>
                </div>
            </Container> : <CircularProgress />}
        </div>
    );
};

export default FullViewArticles;