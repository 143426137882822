import React from 'react';
import './index.css'

const HowToApply = () => {


  return (
    <>
      <div content-tab="howtoapply">
        <h2 >How to apply - Romania</h2>
        <p>
          Before the COVID-19 pandemic, online education programs were
          not widely used in Romania. Hence, online education programs
          have been now a great option for many students who have
          full-time jobs.
        </p>
        <p>
          When Romanian authorities declared an emergency due to the
          pandemic in 2020, online education became mandatory throughout
          the country, in both public and private education systems,
          from primary school to higher education institutions.
        </p>
        <p>
          Since Romanian schools and universities have shifted to online
          classes, due to the COVID-19 pandemic, you now have a great
          chance to study medicine in Romania online via
          <a
            href="https://uni-app.com/search?discipline=Health%20%26%20Medicines&amp;attendance=Online"
          >uni-app</a
          ><a
            href="https://uni-app.com/search?discipline=Health%20%26%20Medicines&amp;attendance=Online"
          ><strong>.</strong></a
          >
        </p>
        <p>
          The application requirements for Romanian medical universities
          differ depending on the school and program. The application
          process depends also on the student’s nationality—whether EU,
          EEA, or CH.
        </p>
        <p>
          Here are the required documents to study medicine in Romania:
        </p>
        <ul>
          <li>High school certificate</li>
          <li>Transcripts of high school studies</li>
          <li>Copy of your passport</li>
          <li>Six personal photos</li>
          <li>Birth certificate</li>
          <li>Marriage certificate, if applicable</li>
          <li>Medical certificate</li>
        </ul>
        <p>Language Requirements to study medicine in Romania</p>
        <p>
          Students must be fluent in their chosen language of study
          (Romanian, English, or French).
        </p>
        <p>
          Following certificates are accepted for language proficiency:
        </p>
        <ul>
          <li>
            English language certificate: TOEFL, IELTS, and Cambridge
          </li>
          <li>French language proficiency: Level B2</li>
        </ul>
        <p>
          * All mentioned documents must be translated into Romanian,
          English, or French.
        </p>
        <p>&nbsp;</p>
        <p>
          Applicants should prepare an application file that includes
          all essential documents as well as the application form.
        </p>
        <p>
          Most universities will need a copy of the application file
          processing fees, which are non-refundable.
        </p>
        <p>
          Applicants should register for medical studies at the Ministry
          of Education, Youth, and Sports through their International
          Relations Offices, or at the Romanian Embassies across the
          world.
        </p>
        <p>
          All you need to do is fill out an application form and send it
          along with the documents in original, authenticated copy and
          translation.
        </p>
        <p>
          Once you are accepted, the Ministry of Education, Youth and
          Sport will send you an acceptance letter.
        </p>
        <p>
          So, if you’re ready to study medicine in Romania,
          <a
            href="https://uni-app.com/search?discipline=Health%20%26%20Medicines"
          >Uni-app</a
          >
          will enable you to enroll in one of the best medical programs
          of your choice and help you go to your university faster and
          easier than you might think.
        </p>
        <p>
          Now, we will leave you a good dose of Canada’s hero and most
          quotable doctor, William Osler. It’s almost impossible to read
          anything he said and not be inspired to get back to helping
          patients, teaching students, and just generally enjoying this
          profession.
        </p>
        <p>
          <em
          >“Let me congratulate you on the choice of calling which
            offers a combination of intellectual and moral interests
            found in no other profession.”</em
          >
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </>



  );
}
export default HowToApply;