import React from 'react';
import { Container, TextField, Button } from '@mui/material';
import './index.css';
import { useNavigate } from 'react-router';

const DegreesFilter = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/search/')
    };

    return (
        <body className='degreeSection'>
            <Container maxWidth='lg'>
                <div className='degreesBy'>
                    <div>
                        <h2>Browse By Country</h2>
                        <hr style={{ width: '100%', borderTop: '1px solid #EEE', marginTop: '-30px', marginBottom: '25px' }} />

                        <p onClick={() => { navigate('/search/?course=Bachelor&country=United%20States') }} className='degreesText'>Study in United States</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&country=United%20Kingdom') }} className='degreesText'>Study in United Kingdom</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&country=Germany') }} className='degreesText'>Study in Germany</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&country=France') }} className='degreesText'>Study in France</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&country=Netherlands') }} className='degreesText'>Study in Netherlands</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&country=Canada') }} className='degreesText'>Study in Canada</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&country=New%20Zealand') }} className='degreesText'>Study in New Zealand</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&country=Australia') }} className='degreesText'>Study in Australia</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&country=Ireland') }} className='degreesText'>Study in Ireland</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&country=Turkey') }} className='degreesText'>Study in Turkey</p>


                        <button className='submitCard' onClick={handleClick}> Start </button>

                    </div>

                    <div>
                        <h2>Browse by University</h2>
                        <hr style={{ width: '100%', borderTop: '1px solid #EEE', marginTop: '-30px', marginBottom: '25px' }} />
                        <p onClick={() => { navigate('/search/?course=Bachelor&institute=Arden%20University') }} className='degreesText'>Arden University</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&institute=BSBI%20Berlin%20School%20of%20Business%20and%20Innovation') }} className='degreesText'>BSBI Berlin School of Business and Innovation</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&institute=eu%20Business%20School') }} className='degreesText'>eu Business School</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&institute=IU%20International%20University%20of%20Applied%20Sciences') }} className='degreesText'>IU International University of Applied Sciences</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&institute=Munich%20Business%20School') }} className='degreesText'>Munich Business School</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&institute=Paris%20School%20of%20Business') }} className='degreesText'>Paris School of Business</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&institute=SRH%20University%20of%20Applied%20Science') }} className='degreesText'>SRH University of Applied Science</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&institute=Schiller%20International%20University') }} className='degreesText'>Schiller International University</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&institute=Fresenius%20University%20Of%20Applied%20Sciences') }} className='degreesText'>Fresenius University</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&institute=London%20School%20of%20Business%20and%20Finance') }} className='degreesText'>London School of Business and Finance</p>
                        <button className='submitCard' onClick={handleClick}> Start </button>
                    </div>


                    <div>
                        <h2>Browse by Discipline</h2>
                        <hr style={{ width: '100%', borderTop: '1px solid #EEE', marginTop: '-30px', marginBottom: '25px' }} />
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Business%20%26%20Management') }}  className='degreesText'>Business & Management studies</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Humanities') }} className='degreesText'>Humanities studies</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Engineering%20%26%20Technology') }} className='degreesText'>Engineering & Technology studies</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Arts%2C%20Design%20%26%20Architecture') }} className='degreesText'>Art, Design & Architecture studies</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Social%20Sciences') }} className='degreesText'>Social Science studies</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Computer%20Science%20%26%20IT') }} className='degreesText'>Computer Science & IT studies</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Natural%20Science%20%26%20Mathematics') }} className='degreesText'>Natural Science & Mathematics studies</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Law') }} className='degreesText'>Law Studies</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Environment%20%26%20Earth%20Sciences') }} className='degreesText'>Environment & Earth Science studies</p>
                        <p onClick={() => { navigate('/search/?course=Bachelor&discipline=Hospitality%2C%20Leisure%20%26%20Sports') }} className='degreesText'>Hospitality, Leisure & Sport studies</p>

                        <button className='submitCard' onClick={handleClick}> Start </button>


                    </div>

                    <div>
                        <h2>Browse by Degree</h2>
                        <hr style={{ width: '100%', borderTop: '1px solid #EEE', marginTop: '-30px', marginBottom: '25px' }} />
                        <p onClick={() => { navigate('/search/?q=Computer%20Engineering&course=Bachelor') }} className='degreesText'>Computer Engineering</p>
                        <p onClick={() => { navigate('/search/?q=Human%20Engineering&course=Bachelor') }}className='degreesText'>Human Engineering</p>
                        <p onClick={() => { navigate('/search/?q=Electrical%20Engineering%20and%20Computer%20Science&course=Bachelor') }} className='degreesText'>Electrical Engineering and Computer Science</p>
                        <p onClick={() => { navigate('/search/?q=Applied%20Economics%20and%20Management&course=Bachelor') }} className='degreesText'>Applied Economics and Management</p>
                        <p onClick={() => { navigate('/search/?q=Operations%20Research&course=Bachelor') }} className='degreesText'>Operations Research</p>
                        <p onClick={() => { navigate('/search/?q=Political%20Economy&course=Bachelor') }} className='degreesText'>Political Economy</p>
                        <p onClick={() => { navigate('/search/?q=Actuarial%20Mathematics&course=Bachelor') }} className='degreesText'>Actuarial Mathematics</p>
                        <p onClick={() => { navigate('/search/?q=Electrical%20Power%20Engineering&course=Bachelor') }} className='degreesText'>Electrical Power Engineering</p>
                        <p onClick={() => { navigate('/search/?q=Business%20Analysis&course=Bachelor') }} className='degreesText'>Business Analysis</p>
                        <p onClick={() => { navigate('/search/?q=Pharmacy') }} className='degreesText'>Pharmacy</p>

                        <button className='submitCard' onClick={handleClick}> Start </button>

                    </div>
                </div>
            </Container>
        </body>
    );
}

export default DegreesFilter;
